import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import RenameDeletePopup from "helperComponents/RenameDeletePopup";
import { apiRequest } from "util/services";
import { notification, Spin, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const Tags = () => {
  const [tagList, setTagList] = useState([]);
  const [showCreateNewTag, setShowCreateNewTag] = useState(false);
  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const [showRenameTag, setShowRenameTag] = useState(false);
  const [inputTag, setInputTag] = useState("");
  const [selectedTagID, setSelectedTagID] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTagTopics, setSelectedTagTopics] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showNoTagFound, setShowNoTagFound] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [tagsPageNumber, setTagsPageNumber] = useState(1);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [numOfTags, setNumOfTags] = useState(10);
  const [totalTags, setTotalTags] = useState(0);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const getAllTags = async () => {
    const payload = {
      start_index: startIndex,
      num_tags: numOfTags,
    };
    setTagLoading(true);
    const response = await apiRequest(
      "library/tags/get",
      "POST",
      payload,
      true
    );
    if (response.success) {
      if (response.success.tags.length > 0) {
        setTotalTags(response.success.num_tags);
        setTagList(response.success.tags);
        setShowNoTagFound(false);
      } else {
        setTagList([]);
        setShowNoTagFound(true);
      }
      setShowActions(response.success.show_actions);
      setTagLoading(false);
    } else {
      setShowNoTagFound(true);
      setTagLoading(false);
    }
  };

  useEffect(() => {
    getAllTags();
  }, [startIndex, numOfTags]);

  const handleShowRenameTag = (tag) => {
    setInputTag(tag.tag_name);
    setSelectedTagID(tag.tag_id);
    setSelectedTagTopics(tag.tagged_topics_number);
    setSelectedTag(tag.tag_name);
    setShowRenameTag(true);
  };

  const handleShowDeleteTag = (tag) => {
    setSelectedTagTopics(tag.tagged_topics_number);
    setSelectedTag(tag.tag_name);
    setShowDeleteTag(true);
    setSelectedTagID(tag.tag_id);
  };

  const handleAddTag = async () => {
    setDisableBtn(true);
    const payload = { tag_name: inputTag };

    const response = await apiRequest(
      "library/tags/add",
      "POST",
      payload,
      true
    );
    if (response.success) {
      notification.success({
        message: t("tags_page.2"),
      });
      setShowCreateNewTag(false);
      setDisableBtn(false);
      getAllTags();
      setInputTag("");
    } else {
      notification.error({
        message: response.error,
      });
      setDisableBtn(false);
    }
  };

  const handleRenameTag = async () => {
    setDisableBtn(true);
    const payload = {
      tag_id: selectedTagID,
      tag_name: inputTag,
    };

    const response = await apiRequest(
      "library/tags/rename",
      "POST",
      payload,
      true
    );
    if (response.success) {
      notification.success({
        message: t("tags_page.3"),
      });
      setDisableBtn(false);
      setShowRenameTag(false);
      getAllTags();
      setInputTag("");
      setSelectedTagID("");
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  const handleDeleteTag = async () => {
    setDisableBtn(true);
    const payload = {
      tag_id: selectedTagID,
    };

    const response = await apiRequest(
      "library/tags/remove",
      "POST",
      payload,
      true
    );
    if (response.success) {
      notification.success({
        message: t("tags_page.4"),
      });
      setDisableBtn(false);
      setShowDeleteTag(false);
      getAllTags();
      setSelectedTagID("");
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  const handleUserPage = (e) => {
    if (e.which === 13) {
      if (!tagsPageNumber) {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      } else if (
        tagsPageNumber > 0 &&
        tagsPageNumber <= Math.ceil(totalTags / selectedPerPage) &&
        /^\d+$/.test(tagsPageNumber)
      ) {
        setStartIndex(tagsPageNumber - 1);
        e.target.blur();
      } else {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      }
    }
  };

  const handlePageChange = (value) => {
    setSelectedPerPage(value);
    setNumOfTags(value);
    setStartIndex(0);
    setTagsPageNumber(1);
  };

  return (
    <div className={styles.mainWindow}>
      <div>
        <div className={styles.header}>
          <div className={styles.firstItemHeader}>
            <p>{t("tags_page.5")}</p>
            <p>{t("tags_page.6")}</p>
          </div>
          <button
            onClick={() => setShowCreateNewTag(true)}
            disabled={showActions ? false : true}
          >
            <i className="fa-solid fa-plus"></i> {t("tags_page.1")}
          </button>
        </div>

        <div className={styles.tableContainer}>
          <div className={`${styles.row} ${styles.header}`}>
            <span className={styles.tagName}>{t("tags_page.7")}</span>
            <span className={styles.activeOn}>{t("tags_page.8")}</span>
            {showActions && <span className={styles.actions}></span>}
          </div>
          <Spin spinning={tagLoading} style={{ marginTop: "20px" }}>
            {tagList?.map((tag) => (
              <div className={styles.row} key={tag.tag_id}>
                <span className={styles.tagName}>{tag.tag_name}</span>
                <span
                  className={styles.activeOn}
                >{`${tag.tagged_topics_number} topics`}</span>
                {showActions ? (
                  <span className={styles.actions}>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/rename.svg"
                          : "/assets/rename_black.svg"
                      }
                      onClick={() => handleShowRenameTag(tag)}
                      alt=""
                    />
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/trash.svg"
                          : "/assets/trash_black.svg"
                      }
                      onClick={() => handleShowDeleteTag(tag)}
                      alt=""
                    />
                  </span>
                ) : (
                  <></>
                )}
              </div>
            ))}
            {showNoTagFound && (
              <div className={styles.notFound}>{t("tags_page.12")}</div>
            )}
          </Spin>
        </div>
      </div>
      {tagList?.length !== 0 && (
        <div className={styles.paginationWrapper}>
          <div className={styles.innerWrapper}>
            {
              <div className={styles.paginationBox}>
                <Button
                  disabled={startIndex === 0}
                  onClick={() => {
                    parseInt(tagsPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev - 1);
                    parseInt(tagsPageNumber, 10) === startIndex + 1 &&
                      setTagsPageNumber((prev) => parseInt(prev, 10) - 1);
                  }}
                >
                  <i className="fa fa-angle-left" />
                </Button>
                <span className={styles.pagination}>
                  <input
                    className={styles.pageIndex}
                    onKeyPress={handleUserPage}
                    type="text"
                    value={tagsPageNumber}
                    onChange={(e) => setTagsPageNumber(e.target.value)}
                  />
                  {totalTags
                    ? ` / ${Math.ceil(totalTags / selectedPerPage)}`
                    : ""}
                </span>
                <Button
                  disabled={
                    tagList?.length === 0 ||
                    (totalTags &&
                      startIndex + 1 ===
                        Math.ceil(totalTags / selectedPerPage)) ||
                    !tagsPageNumber
                  }
                  onClick={() => {
                    parseInt(tagsPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev + 1);
                    parseInt(tagsPageNumber, 10) === startIndex + 1 &&
                      setTagsPageNumber((prev) => parseInt(prev, 10) + 1);
                  }}
                >
                  <i className="fa fa-angle-right" />
                </Button>
              </div>
            }

            {tagList?.length > 0 && (
              <Select
                className="review-page-number-selector"
                value={selectedPerPage + ` / ${t("label.10")}`}
                onChange={handlePageChange}
                style={{ backgroundColor: "transparent" }}
              >
                <Option value={10}>10</Option>
                <Option disabled={totalTags <= 10 ? true : false} value={25}>
                  25
                </Option>
                <Option disabled={totalTags <= 25 ? true : false} value={50}>
                  50
                </Option>
                <Option disabled={totalTags <= 50 ? true : false} value={100}>
                  100
                </Option>
              </Select>
            )}
          </div>
          {totalTags > 0 && (
            <div className={styles.dashboardsTotal}>
              {selectedPerPage * startIndex +
                tagList?.length +
                " of " +
                totalTags}
            </div>
          )}
        </div>
      )}
      {showRenameTag && (
        <RenameDeletePopup
          text={inputTag}
          setText={setInputTag}
          label={t("tags_page.9", {
            tag: selectedTag,
            topic: selectedTagTopics,
          })}
          handleAction={handleRenameTag}
          closePopup={setShowRenameTag}
          type="rename"
          disableBtn={disableBtn}
        />
      )}
      {showDeleteTag && (
        <RenameDeletePopup
          label={t("tags_page.10", {
            tag: selectedTag,
            topic: selectedTagTopics,
          })}
          handleAction={handleDeleteTag}
          closePopup={setShowDeleteTag}
          type="delete"
          btnColor="#D9003C"
          disableBtn={disableBtn}
        />
      )}
      {showCreateNewTag && (
        <RenameDeletePopup
          text={inputTag}
          setText={setInputTag}
          label={t("tags_page.13")}
          handleAction={handleAddTag}
          closePopup={setShowCreateNewTag}
          type="create"
          disableBtn={disableBtn}
        />
      )}
    </div>
  );
};

export default Tags;

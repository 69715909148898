import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "redux/popup/actions.js";
import { apiRequest } from 'util/services';
import styles from "./style.module.scss";
import { useTranslation } from 'react-i18next';

function Popup({ user, auth }) {

    const popupReducer = useSelector(
        (state) => state.popupReducer
    );
    const dispatch = useDispatch();

    const open = popupReducer.open;
    const first_time = popupReducer.first_time;

    const setOpen = (state) => {
        dispatch({
            type: state ? actions.OPEN_POPUP : actions.CLOSE_POPUP
        })
    }

    const [initialData, setInitialData] = useState();
    const [popupData, setPopupData] = useState();
    const [topicboards, setTopicboards] = useState();
    const [filter, setFilter] = useState({
        category: [],
        Platform: [],
        language: []
    })
    const [search, setSearch] = useState("")
    const [hide, setHide] = useState(true)
    const { t } = useTranslation();

    useEffect(() => {
        if (auth != "unlogged") {
            let el = document.getElementById("popup");
            el.addEventListener("click", (e) => {
                if (!e.target.closest("#popup__content")) {
                    setOpen(false);
                }
            })

            const getResult = async () => {
                const res = await apiRequest("dashboards/popup", "POST", {
                    user_id: user.uid
                }, true);

                if (res && !res.error) {
                    const { topicboards, Platform, categories, language } = res;
                    setPopupData({ Platform, categories, language });
                    setInitialData(topicboards);
                    setTopicboards(topicboards);
                    setHide(res.hide);
                }
                else {
                    setOpen(false);
                    notification.error({
                        message: t("notifications.138"),
                    })
                }
            };

            if (open) {
                getResult();
            }
        }

    }, [open]);

    useEffect(() => {
        if (first_time && initialData && !hide) {
            setOpen(true);
        }
    }, [first_time, initialData, hide])

    useEffect(() => {
        let checkEmpty = true;
        Object.keys(filter).forEach((v) => checkEmpty = checkEmpty && filter[v].length === 0)
        if (checkEmpty) {
            document.getElementById("all").classList.add(styles.active);
        }

        const criteria = Object.keys(filter)?.filter((v) => filter[v].length !== 0);

        const res = initialData?.filter((item) => {
            let check = true;
            let containes = false;
            const words = search.trim().split(/\W+/);
            if (words.length === 0) {
                containes = true
            }
            else {
                words.forEach((w) => {
                    containes = containes || item.DashboardName.toLowerCase().includes(w.toLowerCase())
                })
            }
            criteria.forEach(c => {
                check = check && filter[c].includes(item[c])
            })
            return check && containes;
        })
        setTopicboards(res);
    }, [filter, search])

    useEffect(() => {
        resetFilter();
        let el = document.getElementById("popup");
        if (open) {
            el.classList.add(styles.open)
            document.body.style.overflowY = "hidden"
        }
        else {
            el.classList.remove(styles.open)
            document.body.style.overflowY = "visible"
        }
    }, [open])


    const handleClick = (e, type, value) => {
        document.getElementById("all").classList.remove(styles.active);
        if (e.target.classList.contains(styles.active)) {
            e.target.classList.remove(styles.active);
            var index = filter[type].indexOf(value);
            const newObj = {
                ...filter,
                [type]: [...filter[type].slice(0, index), ...filter[type].slice(index + 1)]
            };
            setFilter(newObj);
        }
        else {
            e.target.classList.add(styles.active);
            const newObj = {
                ...filter,
                [type]: [...filter[type], value]
            };
            setFilter(newObj);
        }
    }

    const resetFilter = () => {
        setSearch("");
        document.querySelectorAll("." + styles.menu_section_item).forEach((el) => {
            el.classList.remove(styles.active)
        })
        document.getElementById("all").classList.add(styles.active);
        setFilter({
            category: [],
            Platform: [],
            language: []
        });
    }

    const openShareableDashboard = (hash) => {
        const win = window.open(`/share/DashBoard/${hash}`, "_blank");
        win.focus();
    }

    const hidePopup = async (e) => {
        const res = await apiRequest("dashboards/hide_popup", "POST", {
            "user_id": user.uid,
            "hide": e.target.checked ? true : false
        }, true)

        if (res && !res.error) {
            setOpen(false);
            setHide(true);
        }
        else {
            setOpen(false);
            notification.error({
                message: t("notifications.139"),
            })
        }
    }

    return (
        <div className={styles.popup} id="popup">
            <div className={styles.popup__content} id="popup__content">
                <div className={styles.popup__left}>
                    <div className={styles.menu_section}>
                        <div id="all" className={styles.menu_section_item + " " + styles.active}
                            onClick={resetFilter}>
                            {t("dashboard_sidebar.1")}
                        </div>
                    </div>
                    <div className={styles.menu_section}>
                        <h4 className={styles.menu_section_header}>
                            {t("popup.1")}
                        </h4>
                        {
                            popupData?.language?.map((language) => {
                                return (
                                    <div className={styles.menu_section_item}
                                        onClick={e => handleClick(e, "language", language)}>
                                        <img src={`/assets/${language}.svg`} />
                                        <p>{language}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.menu_section}>
                        <h4 className={styles.menu_section_header}>
                            {t("popup.2")}
                        </h4>
                        {
                            popupData?.categories?.map((category) => {
                                return (
                                    <div className={styles.menu_section_item}
                                        onClick={e => handleClick(e, "category", category)}>
                                        {category}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.menu_section}>
                        <h4 className={styles.menu_section_header}>
                            {t("popup.3")}
                        </h4>
                        {
                            popupData?.Platform?.map((platform) => {
                                return (
                                    <div className={styles.menu_section_item}
                                        onClick={e => handleClick(e, "Platform", platform)}>
                                        {platform}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={styles.popup__right}>
                    <div className={styles.closePopupBox}>
                        <div className={styles.showCheckbox}>
                            <input id="dont-show-again-state" type="checkbox" checked={hide} onChange={hidePopup} />
                            <label htmlFor='dont-show-again-state'>{t("popup.4")}</label>
                        </div>
                        <span className={styles.popupClose} onClick={() => setOpen(false)}>
                            ×
                        </span>
                    </div>
                    <div className={styles.popup__search}>
                        <div className={styles.popup__search_icon}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <input className={styles.popup__search_input} placeholder={t("popup.6")} value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div className={styles.popup__title}>
                        <h3>{t("popup.5")}</h3>
                    </div>
                    <div className={styles.popup__Dashboard_grid}>
                        {
                            topicboards?.length === 0 && <p style={{ fontSize: "18px" }}>{t("notifications.140")}</p>
                        }
                        {
                            topicboards?.map((topicboard) => {
                                return <div className={styles.popup__Dashboard} onClick={() => openShareableDashboard(topicboard.Serialized)}>
                                    <div className={styles.popup__Dashboard_image}>
                                        <img src={topicboard.PicUrl} alt={t("popup.8")} />
                                    </div>
                                    <div className={styles.popup__Dashboard_text}>
                                        <div className={styles.popup__Dashboard_header}>
                                            <span>{topicboard.DashboardName} - </span><span>{topicboard.Platform}</span>
                                        </div>
                                        <div>
                                            <span>{t("popup.7", { lang: topicboard.language, num: topicboard.NumTopics })}</span>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popup
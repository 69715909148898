import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TopicLibraryItem from "./TopicListItem";

const TopicLibraryListing = ({
  topics,
  handleCheckboxChange,
  handleSelectTopic,
  allTopicsList,
  handleParentCheckboxChange,
  editTopicRule,
  handleRunDashboard,
  handleRenameTopicModal,
  handleShowRelationPopup,
  setTopicID,
  handleShowDeleteTopic,
}) => {
  useEffect(() => {
    setTreeTopics(ParseTopics(topics));
  }, [topics]);

  const CarryOutOutlined = (selected) => {
    const theme = useSelector((state) => state.DashboardReducer.theme);
    if (selected) {
      return (
        <img
          width={20}
          src={
            theme === "dark"
              ? "/assets/arrow_down.svg"
              : "/assets/arrow_down_black.svg"
          }
          alt=""
        />
      );
    } else {
      return (
        <img
          width={20}
          src={
            theme === "dark" ? "/assets/survey.svg" : "/assets/survey_black.svg"
          }
          alt=""
        />
      );
    }
  };

  const ParseChildrens = (item) => {
    let topic = [];
    if (item?.child?.length) {
      item.child.forEach((element) => {
        topic.push({
          ...element,
          title: element.name,
          icon: (selected) => <CarryOutOutlined selected={selected} />,
          children:
            element.child && element.child?.length
              ? ParseChildrens(element)
              : [],
        });
      });
    }
    return topic;
  };

  const ParseTopics = (topics) => {
    let Parsedtopics = {};
    if (topics.length) {
      // get all the parent topics
      topics.forEach((element) => {
        Parsedtopics[`${element.id}`] = {
          ...element,
          title: element.name,
          icon: (selected) => <CarryOutOutlined selected={selected} />,
          children:
            element.child && element.child?.length
              ? ParseChildrens(element)
              : [],
        };
      });
    }
    return Parsedtopics;
  };
  const [TreeTopics, setTreeTopics] = useState(ParseTopics(topics));

  // setTreeTopics(ParseTopics(topics))
  if (TreeTopics && Object.keys(TreeTopics)?.length) {
    return (
      <div style={{ display: "flex", flexDirection: "column", rowGap: "0px" }}>
        {Object.keys(TreeTopics).map((key) => {
          let topic = TreeTopics[key];
          return (
            <TopicLibraryItem
              key={key}
              handleSelectTopic={handleSelectTopic}
              handleCheckboxChange={handleCheckboxChange}
              editTopicRule={editTopicRule}
              allTopicsList={allTopicsList}
              topicList={topics}
              handleRenameTopicModal={handleRenameTopicModal}
              handleShowRelationPopup={handleShowRelationPopup}
              handleParentCheckboxChange={handleParentCheckboxChange}
              tree={topic}
              handleRunDashboard={handleRunDashboard}
              handleShowDeleteTopic={handleShowDeleteTopic}
              setTopicID={setTopicID}
            />
          );
        })}
      </div>
    );
  }
};

export default TopicLibraryListing;

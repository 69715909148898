import React from "react";
import { Form, Input, Button, notification } from "antd";
import firebase from "../../FirebaseConfig";
import { logError, apiRequest } from "util/services";
import { useHistory } from "react-router-dom";

const PasswordVerification = ({code}) => {
  const history = useHistory();

  const onFinish = (values) => {
    firebase.auth().confirmPasswordReset(code, values.password)
    .then(function() {
        notification.success({
            message: 'Successful',
            description: 'Reset password succeeded'
        })
        history.push("/login")
    })
    .catch(function(error) {
      try {
        apiRequest("users/client_log", "POST", { error:error, message:"Failed to reset" }, true)
      }
      catch (er) {
        console.log(er)
      }
      logError("Failed to reset password", {})
        notification.error({
            message: 'Failed',
            description: 'Failed to reset password'
        })
    })
  };

  const onFinishFailed = (errorInfo) => {
    try {
      apiRequest("users/client_log", "POST", { error:errorInfo, message:"Failed to reset" }, true)
    }
    catch (er) {
      console.log(er)
    }
    notification.error({
        message: 'Failed',
        description: 'Failed to reset password'
    })
  };

  return (
    <div className="PasswordVerification">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<label style={{ color: "white" }}>New Password</label>}
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordVerification;

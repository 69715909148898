import { Button, notification, Form } from "antd";
import config from "Configurations";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { USER } from "util/constants";
import styles from "./styles.module.scss";
import mixpanel from 'mixpanel-browser';

function AppSumo() {
  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  if (firebase.auth().currentUser) {
    window.location.href = '/';
  }

  useEffect(() => {
    document.getElementById("header_to_menu").style.display = "none";
  }, []);

  const signUpUser = async (values) => {
    if (
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000"
    ) {
      notification.error({
        message: "Google Recaptcha",
        description: "You must verify with google recaptcha",
      });
      return;
    }
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        code: values.appsumo,
        user_email: values.email,
      }),
    };

    await fetch(config.BASE_URL + "users/appsumo/check_coupon", requestOptions)
      .then((response) => response.text())
      .then(async (result2) => {
        const result = JSON.parse(result2);
        if (result.error) {
          notification.error({
            message: "Error",
            description: result.error,
          });
        } else {
          let user = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            type: USER,
            emailVerified: true,
          };

          firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then(async (response) => {
              const res = response.user.toJSON();
              (res || {}).uid && mixpanel.identify(res.uid);
              mixpanel.track('REGISTER_APPSUMO');
              registerUID(res.uid, response.user, user, values.appsumo);
            })
            .catch(function (error) {
              var errorMessage = error.message;
              notification.error({
                message: "Error",
                description: errorMessage,
              });
              setLoading(false);
            });
        }
        setLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message,
        });
        setLoading(false);
      });
  };

  const registerUID = async (uid, authUser, objUser, appSumoCode) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        user_id: uid,
        code: appSumoCode,
      }),
    };

    await fetch(
      config.BASE_URL + "subscriptions/appsumo/create",
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result2) => {
        const result = JSON.parse(result2);
        if (result.error) {
          authUser.delete();
          notification.error({
            message: "Error",
            description: result.error,
          });
        } else {
          await firebase
            .database()
            .ref(`/users/${uid}`)
            .set({ ...objUser, plan: result.packageId });
          document.location.href = document.location.origin;
        }
        setLoading(false);
      })
      .catch((error) => {
        authUser.delete();
        notification.error({
          message: "Error",
          description: error.message,
        });
        setLoading(false);
      });
  };

  const verifyRecaptcha = (token) => {
    setCheckRecaptcha(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.carouselContent}>
        <div style={{ width: "100%" }}>
          <img style={{ width: "100%" }} src="/assets/appSumo.png" />
        </div>

        <div className={styles.title}>
          <h1>Hey Sumo-ling, welcome to Pivony! </h1>
          <h2>
            Welcome to Pivony! Do you have your AppSumo code? If so, please fill
            the form and click "Sign Up" to start adding value to you business
            with Pivony.{" "}
          </h2>
        </div>

        <div className={styles.form}>
          <Form form={form} name="mainDetails" onFinish={signUpUser}>
            <Form.Item
              name="appsumo"
              label="AppSumo Code"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <input className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="firstName"
              label="First name"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <input placeholder="John" className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last name"
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <input placeholder="Doe" className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="email"
              label="AppSumo Email"
              rules={[
                {
                  required: true,
                  message: "AppSumo e-mail address is required",
                },
              ]}
            >
              <input
                type="email"
                placeholder="john.doe@gmail.com"
                className={styles.inputForm}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <input type="password" className={styles.inputForm} />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  onChange={verifyRecaptcha}
                  sitekey={config.RECAPTCHA_SITE_PUBLIC_KEY}
                />
              </div>
              <Button
                loading={loading}
                className={styles.btn}
                type="primary"
                htmlType="submit"
                style={{ height: "auto" }}
              >
                Sign up
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <p className={styles.terms}>
                By clicking the button above, you agree to our <br />
                <a href="https://pivony.com/terms/" target="_blank">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="https://pivony.com/privacy/" target="_blank">
                  Privacy Policy
                </a>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AppSumo;

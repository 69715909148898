import React, { useState, useEffect } from "react";
import { Table, Checkbox, Spin } from "antd";
import { useTranslation } from "react-i18next";
const DashboardsSelection = ({
  dashboards,
  selectedDashboards,
  setSelectedDashboards,
  resetPopup,
  loading,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 950) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedDashboards?.length === dashboards?.length);
  }, [selectedDashboards, dashboards]);

  useEffect(() => {
    if (resetPopup) {
      setSelectAll(false);
      setSelectedDashboards([]);
    } else {
      setSelectAll(selectedDashboards?.length === dashboards?.length);
    }
  }, [resetPopup]);

  useEffect(() => {
    setSelectAll(
      selectedDashboards?.length === dashboards?.length ||
        (selectedDashboards?.length > 0 &&
          selectedDashboards?.length < dashboards?.length)
    );
  }, [selectedDashboards, dashboards]);

  const handleDashboardsSelection = (selectedDashboard) => {
    const updatedSelection = [...selectedDashboards];

    const dashboardIndex = updatedSelection.findIndex(
      (dashboard) => dashboard?.dashboardId === selectedDashboard?.dashboardId
    );

    if (dashboardIndex === -1) {
      updatedSelection.push(selectedDashboard);
    } else {
      updatedSelection.splice(dashboardIndex, 1);
    }

    setSelectedDashboards(updatedSelection);
  };

  const handleSelectAll = () => {
    const newSelectAll = !(
      selectedDashboards?.length === dashboards?.length ||
      (selectedDashboards?.length > 0 &&
        selectedDashboards?.length < dashboards?.length)
    );
    setSelectAll(newSelectAll);
    const selected = newSelectAll ? dashboards : [];
    setSelectedDashboards(selected);
  };
  const columns = [
    {
      title: (
        <Checkbox
          color="primary"
          checked={selectAll}
          indeterminate={
            selectedDashboards?.length > 0 &&
            selectedDashboards?.length < dashboards?.length
          }
          onChange={handleSelectAll}
        />
      ),
      key: "checkbox",
      render: (record) => (
        <Checkbox
          checked={
            Array.isArray(selectedDashboards) &&
            selectedDashboards.some(
              (dashboard) => dashboard?.dashboardId === record?.dashboardId
            )
          }
          onChange={() => handleDashboardsSelection(record)}
        />
      ),
    },
    {
      title: t("all_dashboards_page.2"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("all_dashboards_page.18"),
      dataIndex: "group",
      key: "group",
      align: "center",
    },
    {
      title: t("all_dashboards_page.4"),
      dataIndex: "platform",
      key: "platform",
      align: "center",
    },
    {
      title: t("all_dashboards_page.6"),
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      align: "center",
    },
  ];

  const data = dashboards?.map((dashboard, index) => ({
    dashboardId: dashboard.dashboardId,
    name: dashboard.name,
    group: dashboard.group,
    platform: dashboard.platform,
    lastUpdated: dashboard.lastUpdated,
  }));

  return (
    <div>
      <Spin spinning={loading}>
        <Table
          columns={
            isMobileView
              ? columns?.filter(
                  (column) =>
                    column.key !== "platform" && column.key !== "lastUpdated"
                )
              : columns
          }
          dataSource={data}
          pagination={false}
        />
      </Spin>
    </div>
  );
};

export default DashboardsSelection;

import React from "react";
import "./stylesheets/Landing.scss";
import AuthenticatedLanding from "./LandingComponents/AuthenticatedLanding";
import firebase from "./FirebaseConfig.js";
import Signup from "./authentication/Signup";
import Login from 'authentication/Login';
import { notification } from "antd";

const Landing = ({ user, authenticated }) => {
  if (user?.plan === "rejected") {
    notification.error({
      message: "Your payment was rejected please try your subscription again",
    });
    // firebase.database().ref(`/users/${user.uid}/plan`).set(rejected);
  }
  return (
    <div>
      {user?.plan !== undefined && user?.plan !== "rejected" ? (
        <AuthenticatedLanding user={user} />
      ) : (
        <Login user={user} authenticated={authenticated} />
      )}
    </div>
  );
};

export default Landing;

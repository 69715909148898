import React from "react";
import styles from "./signup.module.scss";
import { Button } from "antd";
import { numberWithCommas } from "util/common";

const activeCard = {
  borderTop: "30px",
  borderStyle: "solid",
  borderColor: "#891199",
  borderRadius: "5px",
};

const currencySymbol = {
  "usd": "$",
  "eur": "€",
  "gbp": "£",
  "tl": "₺"
}

const reviewToDashboards = {
  '100000': 5,
  '500000': 10,
  '2000000': 20,
}

const Card = ({ plan, passPlanSection, user, reviews, currency, expert, timeType, freeTrial }) => {

  const choosePlan = (freeTrial) => {
    passPlanSection(plan.planId, freeTrial);
  };

  return (
    <div className={styles.planCard} style={plan.active && activeCard}>
      <h1>{plan.title}</h1>
      {plan.subtitle && <h2>{plan.subtitle}</h2>}
      <div className={styles.prices}>
        {plan.price1 && (
          <h4>
            {currencySymbol[currency]}
            {plan.price1 && parseInt(plan.price1[user.currency], 10)}
          </h4>
        )}
        <h3>
          {currencySymbol[currency]}
          {plan.pricing && parseInt(plan.pricing[timeType].price[currency], 10)}
        </h3>
        <span>Month / User</span>
      </div>
      <div className={styles.pros}>
        {plan.pros?.map((item) => {
          return (
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>{item}</p>
            </span>
          );
        })}
        {/* {
          plan.title !== "Free" && !freeTrial &&
          <>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>{numberWithCommas(reviews)} Reviews</p>
            </span>
            {
              plan.tweets && 
              <span className={styles.prosItem}>
                <img src="/assets/check.png" />
                <p>{numberWithCommas(plan.tweets[reviews])} Tweets</p>
              </span>
            }
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>{reviewToDashboards[reviews]} Dashboards</p>
            </span>
            <span className={styles.prosItem} style={{visibility: expert !== "expert_yes" && "none", opacity: expert !== "expert_yes" && 0 }}>
              <img src="/assets/check.png" />
              <p>Expert Insights</p>
            </span> 
          </>
        } */}
        {
          plan.hidden && freeTrial &&
          <>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>{numberWithCommas(20000)} Reviews</p>
            </span>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>3 Dashboards</p>
            </span>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>10 Topics / Dashboard</p>
            </span>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>50 Advanced Search Queries / Dashboard</p>
            </span>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>2 Custom Topics / Dashboard</p>
            </span>
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>14 Days Trial</p>
            </span>
          </>
        }
      </div>
      {
        !plan.hidden && plan.freeTrial &&
        <div className={styles.freeBtn}>
          <Button disabled={plan.disabled} onClick={() => choosePlan(true)}>
            Free Trial
          </Button>
        </div>
      }
      {!plan.hidden && (
        <div className={styles.chooseBtn}>
          <Button disabled={plan.disabled} onClick={() => choosePlan(false)}>
            {user.plan === undefined && user.plan === "rejected"
              ? "BUY NOW"
              : plan.btnTxt}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Card;

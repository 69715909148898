import React from "react";
import { Button, Form } from "antd";
import config from "Configurations";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./styles.module.scss";


const Representation = ({
  loading,
  verifyRecaptcha,
  form,
  signUp,
  organizationName
}) => (
    <div className={styles.container}>
      <div className={styles.carouselContent}>
        <div style={{ width: "100%" }}>
          <img style={{ width: "100%" }} src="/assets/pivony-logo1.png" alt="logo" />
        </div>

        <div className={styles.title}>
          <h1>You are invited to join</h1>
          <h1><i>{decodeURI(organizationName.replaceAll("%20"," "))}</i></h1>
        </div>
        <div>
          <Form form={form} name="mainDetails" onFinish={signUp}>
            <Form.Item
              name="firstName"
              label="First name"
              rules={[{ required: true, message: "First name is required" }]}
            >
              <input placeholder="John" className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last name"
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <input placeholder="Doe" className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Password is required" }]}
            >
              <input type="password" className={styles.inputForm} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm your password"
              rules={[{ required: true, message: "Confirm Password is required" }]}
            >
              <input type="password" className={styles.inputForm} />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  style={{ display: "inline-block" }}
                  onChange={verifyRecaptcha}
                  sitekey={config.RECAPTCHA_SITE_PUBLIC_KEY}
                />
              </div>
              <Button
                loading={loading}
                className={styles.btn}
                type="primary"
                htmlType="submit"
                style={{ height: "auto" }}
              >
                Sign up
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <p className={styles.terms}>
                By clicking the button above, you agree to our <br />
                <a href="https://pivony.com/terms/" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="https://pivony.com/privacy/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </p>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );

export default Representation;

import React from "react";
import DropZoneWrapper from "./dropZoneWrapper";
import styles from "./style.module.scss";
import { Progress } from "antd";
import { hexToRgbA } from "util/common";
import { useSelector } from "react-redux";

const Representation = ({
  handleFileChange,
  progress,
  spinning,
  fileName,
  setColumnNames,
  setProgress,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  return (
    <div className={styles.wrapper}>
      <DropZoneWrapper
        spinning={false}
        progress={progress}
        setProgress={setProgress}
        handleFileChange={handleFileChange}
        setColumnNames={setColumnNames}
      />
      {progress > 0 && progress < 100 && (
        <Progress
          percent={progress}
          size={"small"}
          strokeColor={theme === "dark" ? "#E8E0F4" : "#4400AA"}
          trailColor={theme === "dark" ? "#100d31" : "#ffffff"}
          percentPosition={{ align: "center", type: "outer" }}
          style={{
            color: theme === "dark" ? "white" : "black",
            width: "300px",
          }}
          className={styles.progressBar}
        />
      )}
      {fileName && !(progress > 0 && progress < 100) && (
        <span className={styles.fileNamepan}>
          <i className="fa fa-check-circle" /> {fileName}
        </span>
      )}
    </div>
  );
};

export default Representation;

import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import Bubbles from "./Bubbles.png";
import BubblesMobile from "./Bubble_mobile.png";
import { useHistory } from "react-router-dom";
import { ResponsiveContainer } from "recharts";
const PageNotFound = ({ user, authenticated, theme }) => {
  const history = useHistory();
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 949) {
        setIsMobileView(false);
      } else {
        setIsMobileView(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const backToHome = () => {
    history.push("/");
  };
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${Bubbles})`,
      }}
    >
      <div className={styles.content}>
        <img
          src={
            theme === "dark"
              ? isMobileView
                ? "/assets/404_mobile_dark.svg"
                : "/assets/404_dark.svg"
              : isMobileView
              ? "/assets/404_mobile_light.svg"
              : "/assets/404_light.svg"
          }
          width={
            window.innerWidth > 1299
              ? 450
              : window.innerWidth > 949
              ? 350
              : window.innerWidth > 599
              ? 250
              : 200
          }
          height={
            window.innerWidth > 1299
              ? 300
              : window.innerWidth > 949
              ? 250
              : window.innerWidth > 599
              ? 200
              : 150
          }
        />
        <p className={styles.msg}>
          <span>The link you clicked may be broken</span>
          <br />
          <span>or the page may have been removed.</span>
        </p>
        <span onClick={backToHome} className={styles.HomeBtn}>
          Back to Home
        </span>
      </div>
    </div>
  );
};

export default PageNotFound;

import React, { useEffect, useState } from "react";
import { notification } from "antd";
import firebase from "FirebaseConfig.js";
import Representation from './Representation';
import { apiRequest } from "util/services";
import { useDispatch } from 'react-redux';
import { UPDATE_USER } from 'redux/user/actions';
import { useTranslation } from 'react-i18next';

const roles = [
  "Marketing / Content Creator",
  "Marketing Director / Manager",
  "Data Insight Manager",
  "Customer Specialist",
  "Designer / Developer",
]

const Details = ({ user }) => {
  const [fullName, setFullName] = useState(user?.fullName || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [role, setRole] = useState(user?.role || "");
  const [logo_url, setLogo] = useState(user?.logo_url || "");
  const [progress, setProgress] = useState(0)
  const [numberRetrievedFromDB, setNumberRetrievedFromDB] = useState(user?.phoneNumber && user?.phoneNumber?.length >0)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const deleteLogo = async () => {
    let logo_url = "";
    const logoremove = await apiRequest("users/logo/remove", "POST", {
      user_id: user.uid,
    });
    firebase
      .database()
      .ref(`/users/${user.uid}/`)
      .set({
        ...user,
        logo_url,
      }).then(() => {
        notification.success({
          message: t("notifications.95")
        });
        dispatch({
          type: UPDATE_USER,
          payload: {
            logoUrl: null
          }
        })
      }).catch(() => {
        notification.error({
          message: t("notifications.96")
        });
      })

  }

  const save = () => {
    firebase
      .database()
      .ref(`/users/${user.uid}/`)
      .set({
        ...user,
        fullName,
        phoneNumber,
        role,
        logo_url,
      }).then(() => {
        notification.success({
          message: t("notifications.97")
        });
      }).catch(() => {
        notification.error({
          message: t("notifications.98")
        });
      })
  }
  return <Representation
    user={user}
    fullName={fullName}
    setFullName={setFullName}
    phoneNumber={phoneNumber}
    setPhoneNumber={setPhoneNumber}
    numberRetrievedFromDB={numberRetrievedFromDB}
    setNumberRetrievedFromDB={setNumberRetrievedFromDB}
    setRole={setRole}
    role={role}
    roles={roles}
    save={save}
    deleteLogo={deleteLogo}
  />
}

export default Details;
import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import DashboardWrapper from "components/Dashboard/DashboardWrapper";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
import { apiRequest } from "util/services";
import Loading from "helperComponents/Loading";
import Insights from "pages/Insights";
import DashboardHeader from "components/Dashboard/Header";
import { useSelector } from "react-redux";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const SharedInsights = ({ authenticated, user, match, shared }) => {
  const currentDashboardHash = match.params.id;
  const [currentDashboard, setCurrentDashboard] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  useEffect(() => {
    if (!currentDashboardHash) {
      notification.error({
        message: "Please use a valid link",
      });
      history.push("/");
      return;
    }
    const getDashboardID = async () => {
      const payload = {
        hashed: currentDashboardHash,
      };
      const result = await apiRequest(
        "dashboards/share/deserialize",
        "POST",
        payload
      );
      if (result) {
        setCurrentDashboard(result.dashboard_id);
        setLoading(false);
        // checkDashboardStatus();
      } else {
        notification.error({
          message: "Please use a valid link",
        });
        return;
      }
    };
    getDashboardID();
  }, []);
  if (loading) return <Loading />;
  return (
    <div className={styles.mainWindow}>
      <Insights
        user={user}
        authenticated={authenticated}
        currentDashboard={currentDashboard}
        currentDashboardHash={currentDashboardHash}
        shared={shared}
        theme={theme}
      />
    </div>
  );
};

export default SharedInsights;

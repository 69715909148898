import React, { useEffect, useState, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';

const Report = ({ user }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState();

  const getReports = useCallback((callback) => {
    const payload = {
      user_id: user.uid
    };
    apiRequest(
      "report/list",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        setReports(result);
        typeof callback === 'function' && callback();
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }, [user.uid]);

  useEffect(() => {
    setLoading(true);
    getReports(
      () => setLoading(false)
    );
  }, [getReports]);

  const deleteReport = (dashboardId) => {
    setDeleteInProgress(dashboardId);
    const payload = {
      dashboard_id: dashboardId,
      user_id: user.uid
    }
    apiRequest(
      "report/delete",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        getReports(
          () => {
            setDeleteInProgress()
            notification.success({ message: "Report deleted successfully" });
          }
        );
      } else {
        notification.error({
          message: result.error,
        });
        setDeleteInProgress();
      }
    })
  }

  return <Representation
    deleteInProgress={deleteInProgress}
    reports={reports}
    getAllReports={getReports}
    deleteReport={deleteReport}
    loading={loading}
    user={user}
  />
}

export default Report;
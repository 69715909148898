import React from "react";
import styles from "./style.module.scss";
import { APP_MESSAGES, APP_MESSAGES_TUR } from "util/constants";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NewDashboardHeader = () => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { i18n } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5>{i18n.language === 'en' ? APP_MESSAGES.NEW_Dashboard.HEADER : APP_MESSAGES_TUR.NEW_Dashboard.HEADER}</h5>
            <h6>{i18n.language === 'en' ? APP_MESSAGES.NEW_Dashboard.SUBHEADER : APP_MESSAGES_TUR.NEW_Dashboard.SUBHEADER}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboardHeader;

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import styles from "./style.module.scss";
const { Option } = Select;

const DropdownSelect = ({
  options,
  reset,
  defaultValue,
  placeholder,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleSelection = (values) => {
    setSelectedOptions(values);
  };
  return (
    <>
      {reset ? (
        <div className={styles.filters}>
          <p className={styles.filterPlaceholder}>{placeholder}</p>
          <Select
            defaultValue={defaultValue}
            onChange={handleSelection}
            value={selectedOptions ? selectedOptions : defaultValue}
            showSearch
            optionFilterProp="title"
          >
            {options?.map((option) => (
              <Option
                key={option.optionId}
                value={option.value}
                title={option.label}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        <div className={styles.filters}>
          <p className={styles.filterPlaceholder}>{placeholder}</p>
          <Select
            defaultValue={defaultValue}
            onChange={handleSelection}
            value={selectedOptions ? selectedOptions : defaultValue}
            showSearch
            optionFilterProp="title"
          >
            {options?.map((option) => (
              <Option
                key={option.optionId}
                value={option.value}
                title={option.label}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};

export default DropdownSelect;

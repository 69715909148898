import * as actions from "redux/popup/actions.js";

let initialState = {
  open: false,
  first_time: false
};

const popupReducer = (state = initialState, action) => {
  if (action.type === actions.POPUP_FIRST_TIME) {
    if(state.first_time) return state;
    return {
      ...state,
      first_time: true
    };
  }
  else if (action.type === actions.OPEN_POPUP) {
    return {
      ...state,
      open: true
    };
  } else if (action.type === actions.CLOSE_POPUP) {
    return {
      ...state,
      open: false
    };
  }
  return state;
};

export { popupReducer };

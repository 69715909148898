import React, { useState, useEffect } from "react";
import { Button, Space, Radio, notification, Input, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Modal } from "components/General/ModalFormComponents";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { apiRequest } from "util/services";
import SingleSelect from "helperComponents/SingleSelect";
import DateFilterTwoPickers from "components/Dashboard/DateFilterTwoPickers";
import MultipleSelect from "helperComponents/MultipleSelect";
import dayjs from "dayjs";
const CreateReport = ({
  open,
  onClose,
  getReportsDownloads,
  createLoading,
  setCreateLoading,
  cancelButtonText = "Back",
  saveButtonText = "Next",
  theme,
}) => {
  const { t } = useTranslation();
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 950) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView, window.innerWidth]);
  const [reportName, setReportName] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState(null);
  const [topics, setTopics] = useState([]);
  const [pivotCols, setPivotCols] = useState({});
  const [pivotVals, setPivotVals] = useState({});
  const [selectedTopics, setSelectedTopics] = useState(null);
  const [selectedPivotCol, setSelectedPivotCol] = useState(null);
  const [selectedPivotValue, setSelectedPivotValue] = useState(null);
  const [showDateFilterPopup, setShowDateFilterPopup] = useState(false);
  const [daysRange, setDaysRange] = useState("last7d");
  const [reportDateRange, setReportDateRange] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState({
    id: 1,
    label: "Full-report",
    value: 1,
  });
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [dashboardsLoading, setDashboardsLoading] = useState(false);
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [pivotsLoading, setPivotsLoading] = useState(false);
  useEffect(() => {
    if (daysRange === "last7d") {
      const until = dayjs().format("YYYY-MM-DD"); // Today
      const since = dayjs()
        .subtract(6, "day")
        .format("YYYY-MM-DD"); // 6 days before today
      setReportDateRange([since, until]);
    }
  }, [daysRange]);
  const [currStep, setCurrStep] = useState(0);
  const getGroups = async () => {
    setGroupsLoading(true);
    const response = await apiRequest(
      "industry_analysis/groups",
      "GET",
      null,
      true
    );
    if (response?.data) {
      const gr = response?.data;
      setGroups(
        gr?.map((group) => ({
          id: group.groupId,
          label: group.name,
          value: group.groupId,
        }))
      );
    } else {
      setGroups([]);
      notification.error({
        message: response?.error ? response?.error : "Failed to get groups",
      });
    }
    setGroupsLoading(false);
  };
  const getDashboards = async () => {
    setDashboardsLoading(true);
    const response = await apiRequest(
      "industry_analysis/dashboards",
      "POST",
      { groupIds: [selectedGroup?.id] },
      true
    );
    if (response?.data) {
      const dash = response?.data;
      setDashboards(
        dash?.map((dashboard) => ({
          id: dashboard.dashboardId,
          label: dashboard.name,
          value: dashboard.dashboardId,
        }))
      );
    } else {
      setDashboards([]);
      notification.error({
        message: response?.error ? response?.error : "Failed to get dashboards",
      });
    }
    setDashboardsLoading(false);
  };
  const getTopics = async () => {
    setTopicsLoading(true);
    const response = await apiRequest(
      "industry_analysis/categories",
      "POST",
      { dashboard_ids: selectedDashboards?.map((dashboard) => dashboard.id) },
      true
    );
    if (response?.data) {
      const tpcs = response?.data;
      setTopics(
        tpcs?.map((topic) => ({
          id: topic.categoryId,
          label: topic.name,
          value: topic.categoryId,
        }))
      );
    } else {
      setTopics([]);
      notification.error({
        message: response?.error ? response?.error : "Failed to get topics",
      });
    }
    setTopicsLoading(false);
  };
  const handleCreateReport = async () => {
    setCreateLoading(true);
    const payload = {
      group_id: parseInt(selectedGroup?.id),
      report_name: reportName,
      dashboard_ids: selectedDashboards?.map((dashboard) => dashboard.id),
    };
    if (selectedTopics?.length > 0) {
      payload.topic_ids = selectedTopics?.map((topic) => topic.id);
    }
    if (selectedPivotCol && !selectedPivotValue) {
      notification.warning({
        message: "Please select a pivot value",
      });
    }
    if (selectedPivotCol && selectedPivotValue) {
      payload.pivot_filter = { [selectedPivotCol.id]: selectedPivotValue.id };
    }
    if (reportDateRange?.length > 0) {
      payload.since = reportDateRange[0];
      payload.until = reportDateRange[1];
    }
    const promise = apiRequest("report/create", "POST", payload, true);
    const timeout = new Promise((resolve) => setTimeout(resolve, 6000));
    const result = await Promise.race([promise, timeout]);
    if (result?.validation_error || result?.error) {
      notification.error({
        message: result?.error ? result.error : "Unable to create report",
      });
      setCreateLoading(false);
    } else {
      notification.success({
        message: t("reports_downloads.25"),
      });
      setCreateLoading(false);
    }
    setReportName("");
    setDaysRange("last7d");
    setReportDateRange([]);
    setSelectedDashboards([]);
    setSelectedGroup(null);
    setSelectedTopics([]);
    setSelectedReportType({
      id: 1,
      label: "Full-report",
      value: 1,
    });
    setCurrStep(0);
    onClose();
    getReportsDownloads();
    setCreateLoading(false);
  };
  const getPivots = async () => {
    setPivotsLoading(true);
    const response = await apiRequest(
      "report/pivots",
      "POST",
      { dashboard_ids: selectedDashboards?.map((dashboard) => dashboard.id) },
      true
    );
    if (response?.success) {
      const data = response.success;
      const cols = [];
      const vals = {};
      data.forEach((item) => {
        Object.entries(item).forEach(([id, value]) => {
          Object.keys(value).forEach((key) => {
            const colKey = `${id}$$${key}`;
            cols.push({
              id: colKey,
              label: `${key} (${id})`,
              value: colKey,
            });

            const values = Object.entries(value[key]).map(([subKey]) => ({
              id: subKey,
              label: subKey,
              value: subKey,
            }));

            vals[colKey] = values;
          });
        });
      });
      setPivotCols(cols);
      setPivotVals(vals);
    } else {
      setPivotCols([]);
      setPivotVals({});
      notification.error({
        message: response?.error ? response?.error : "Failed to get pivots",
      });
    }
    setPivotsLoading(false);
  };
  useEffect(() => {
    getGroups();
  }, []);
  useEffect(() => {
    if (selectedGroup) {
      getDashboards();
    }
    if (selectedDashboards?.length > 0) {
      getPivots();
      if (selectedReportType?.id === 2) {
        getTopics();
      }
    }
  }, [selectedDashboards, selectedGroup, selectedReportType]);
  const reportTypes = [
    { id: 1, label: "Full-report", value: 1 },
    { id: 2, label: "Only for Selected Topics", value: 2 },
  ];
  let steps = {
    0: [
      {
        title: "",
        type: "input",
        required: true,
        placeholder: "Report name",
        subtitle: "Enter report name",
        selectedValue: reportName,
        setSelectedValue: setReportName,
        visible: true,
      },
    ],
    1: [
      {
        title: "Group",
        type: "single-select",
        placeholder: "Select Group",
        subtitle: "Choose a group",
        required: true,
        loading: groupsLoading,
        options: groups,
        selectedValue: selectedGroup,
        setSelectedValue: setSelectedGroup,
        visible: true,
      },
      {
        title: "Dashboards",
        type: "multi-select",
        placeholder: "Select Dashboards",
        subtitle: "Choose dashboards",
        required: true,
        loading: dashboardsLoading,
        options: dashboards,
        selectedValue: selectedDashboards,
        setSelectedValue: setSelectedDashboards,
        visible: selectedGroup,
      },
    ],
    2: [
      {
        title: "Date Period",
        type: "date-range",
        subtitle: "Choose a Date Period",
        selectedValue: reportDateRange,
        setSelectedValue: setReportDateRange,
        visible: true,
      },
    ],
    3: [
      {
        title: "Report Type",
        type: "radio-select",
        options: reportTypes,
        selectedValue: selectedReportType,
        setSelectedValue: setSelectedReportType,
        visible: true,
      },
    ],
  };

  if (selectedReportType?.id === 2) {
    steps = {
      ...steps,
      4: [
        {
          title: "Topics",
          type: "multi-select",
          placeholder: "Select Topics",
          subtitle: "Choose topics",
          required: true,
          loading: topicsLoading,
          options: topics,
          selectedValue: selectedTopics,
          setSelectedValue: setSelectedTopics,
          visible: true,
        },
      ],
    };
  }
  if (pivotCols?.length > 0) {
    const step = selectedReportType?.id === 2 ? 5 : 4;
    steps = {
      ...steps,
      [step]: [
        {
          title: "Pivots",
          type: "single-select",
          subtitle: "Choose Pivot Column",
          placeholder: "Select Pivot Column",
          options: pivotCols,
          loading: pivotsLoading,
          selectedValue: selectedPivotCol,
          setSelectedValue: setSelectedPivotCol,
          visible: true,
        },
        {
          title: "Pivots",
          type: "single-select",
          subtitle: "Choose Pivot Value",
          placeholder: "Select Pivot value",
          options: pivotVals[selectedPivotCol?.id],
          selectedValue: selectedPivotValue,
          setSelectedValue: setSelectedPivotValue,
          visible: selectedPivotCol,
          required: selectedPivotCol ? true : false,
        },
      ],
    };
  }

  const presetDatesMap = {
    last24: t("filter.15"),
    last7d: t("filter.16"),
    last14d: t("filter.17"),
    last30d: t("filter.18"),
    last90d: t("filter.19"),
    custom: t("filter.20"),
    allTime: t("filter.21"),
  };
  console.log(reportDateRange);
  return (
    <Modal
      showModal={open}
      showSaveButton={false}
      showCancelButton={false}
      onClose={() => {
        setReportName("");
        setDaysRange("last7d");
        setReportDateRange([]);
        setSelectedDashboards([]);
        setSelectedGroup(null);
        setSelectedTopics([]);
        setSelectedReportType({
          id: 1,
          label: "Full-report",
          value: 1,
        });
        setCurrStep(0);
        onClose();
      }}
      titleAsset={""}
      widthofmodal="460px"
      className={styles.PopupSequence}
      title={
        <div className={styles.popupHeading}>
          {"Create New Report"}{" "}
          {steps[currStep]?.title ? ` > ${steps[currStep]?.title}` : ""}
        </div>
      }
      description={steps[currStep]?.description}
    >
      <div className={styles.Popup}>
        {steps[currStep]?.map((step) => (
          <div className={styles.stepsWrapper}>
            {step?.visible && (
              <div className={styles.popupSubTitle}>
                {step?.subtitle}
                <span
                  className={step?.required ? styles.required : styles.optional}
                >
                  {step?.required ? "*" : ""}
                </span>
              </div>
            )}
            {step?.type === "input" && step?.visible && (
              <input
                placeholder={step?.placeholder}
                value={step?.selectedValue}
                onChange={(e) => step?.setSelectedValue(e.target.value)}
                className={styles.reportNameInput}
              />
            )}
            {step?.type === "single-select" && step?.visible && (
              <SingleSelect
                options={step?.options}
                selectedOptions={step?.selectedValue}
                setSelectedOptions={step?.setSelectedValue}
                placeholder={
                  <span className={styles.actionsPlaceholder}>
                    {step?.placeholder}
                  </span>
                }
                width={380}
                height={42}
                optionsLoading={step?.loading}
                allowClearSelection
                popupStyling
              />
            )}
            {step?.type === "multi-select" && step?.visible && (
              <MultipleSelect
                showSearch={true}
                placeholder={step?.placeholder}
                tagCount={"responsive"}
                options={step?.options}
                selectedOptions={step?.selectedValue?.map((item) => item.id)}
                enableSelectAll
                width={380}
                height={42}
                optionsLoading={step?.loading}
                setSelectedOptions={(values) => {
                  const selectedValues = values.map((item) => {
                    const option = step?.options?.find(
                      (opt) => opt.id === item
                    );
                    if (option) {
                      return {
                        id: option.id,
                        name: option.label,
                        value: option.value,
                      };
                    }
                    return null;
                  });
                  step.setSelectedValue(selectedValues);
                }}
                popupStyling
              />
            )}
            {step?.type === "date-range" && step?.visible && (
              <Dropdown
                open={showDateFilterPopup}
                overlay={
                  <div className={styles.dateContainer}>
                    <DateFilterTwoPickers
                      onChange={(dateString, daysRange) => {
                        setReportDateRange(
                          dateString?.length > 0
                            ? [dateString[0], dateString[1]]
                            : []
                        );
                        setDaysRange(daysRange);
                        setShowDateFilterPopup(false);
                      }}
                      close={() => {
                        setShowDateFilterPopup(false);
                      }}
                      selectedDaysRange={daysRange}
                      setSelectedDaysRange={(val) => setDaysRange(val)}
                      defaultStartDate={new Date()}
                      defaultEndDate={new Date()}
                      searchPeriod={[new Date(), new Date()]}
                    />
                  </div>
                }
                trigger={["click"]}
                placement="bottom"
                overlayClassName={styles.savedSearchDateFilter}
                onOpenChange={(i) => {
                  setShowDateFilterPopup(i);
                }}
              >
                <span className={styles.savedSearchDateBtn}>
                  <span className={styles.iconWithLabel}>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/calendar.svg"
                          : "/assets/calendar_black.svg"
                      }
                      alt=""
                      width={16}
                      height={16}
                    />{" "}
                    {` ${presetDatesMap[daysRange]}`}
                  </span>
                  <DownOutlined />
                </span>
              </Dropdown>
            )}
            {step?.type === "radio-select" && step?.visible && (
              <Space direction="vertical">
                {step?.options.map((option) => (
                  <Radio
                    key={option.id}
                    value={option}
                    checked={step?.selectedValue?.id === option.id}
                    onChange={(e) => {
                      step.setSelectedValue(e.target.value);
                    }}
                  >
                    {option.label}
                  </Radio>
                ))}
              </Space>
            )}
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.footerBtn}
          onClick={() => {
            if (currStep === 0) {
              setReportName("");
              setDaysRange("last7d");
              setReportDateRange([]);
              setSelectedDashboards([]);
              setSelectedGroup(null);
              setSelectedTopics([]);
              setSelectedReportType({
                id: 1,
                label: "Full-report",
                value: 1,
              });
              onClose();
            } else {
              setCurrStep((prev) => prev - 1);
            }
          }}
        >
          {cancelButtonText ? cancelButtonText : t("button.3")}
        </Button>
        <Button
          className={styles.footerBtn}
          type="primary"
          onClick={() => {
            if (currStep === Object.keys(steps).length - 1) {
              // Create report
              handleCreateReport();
            } else {
              setCurrStep((prev) => prev + 1);
            }
          }}
          loading={createLoading}
          disabled={(steps[currStep] || []).some(
            (stepObj) =>
              stepObj.required &&
              (!stepObj.selectedValue || stepObj.selectedValue?.length === 0)
          )}
        >
          {currStep === Object.keys(steps).length - 1
            ? "Create Report"
            : saveButtonText
            ? saveButtonText
            : t("button.30")}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateReport;

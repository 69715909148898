import React, { useState, useEffect } from "react";
import { Select, Checkbox } from "antd";
import styles from "./style.module.scss";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const MultipleSelect = ({
  isLoading,
  selectionLimit,
  showSearch,
  selectedOptions,
  options,
  setSelectedOptions,
  placeholder,
  enableSelectAll,
  tagCount,
  width,
  height,
  optionsLoading,
  popupStyling,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedOptions?.length > 0) {
      const defaultSelection = options
        ?.filter((option) => selectedOptions?.includes(option?.id))
        ?.map((option) => option.value);
      setSelectedItems(defaultSelection);
    }
  }, [selectedOptions]);
  const handleChange = (selected) => {
    if (!isLoading) {
      let updatedSelection = [...selected];

      if (selected.includes("select-all")) {
        updatedSelection =
          selected.length === options.length
            ? []
            : options.slice(0, selectionLimit).map((opt) => opt.value);
      } else if (selected.length > selectionLimit) {
        const overflow = selected.length - selectionLimit;
        updatedSelection = selected.slice(overflow, selected.length);
      }

      setSelectedItems(updatedSelection);
      setSelectAllChecked(updatedSelection.length === options.length);
      setSelectedOptions(updatedSelection);
    }
  };

  const handleSelectAll = () => {
    const newSelected = selectAllChecked
      ? []
      : options.slice(0, selectionLimit).map((opt) => opt.value);
    handleChange(newSelected);
  };

  return (
    <div className={styles.Dropdown}>
      <div
        className={
          popupStyling ? styles.popupDropdown : styles.MultiSelectDropdown
        }
        style={
          width || height ? { width: `${width}px`, height: `${height}px` } : {}
        }
      >
        <Select
          mode="multiple"
          suffixIcon={<DownOutlined />}
          showSearch={showSearch ? true : false}
          allowClear
          placeholder={
            <span className={styles.placeholder}>
              {placeholder ? placeholder : t("button.39")}
            </span>
          }
          value={selectedItems}
          optionFilterProp="title"
          maxTagCount={tagCount ? "responsive" : 1}
          listHeight={192}
          className={popupStyling ? styles.popupSelection : styles.selection}
          onChange={handleChange}
          style={
            width || height
              ? { width: `${width}px`, height: `${height}px` }
              : {}
          }
          loading={optionsLoading}
        >
          {enableSelectAll && options?.length > 0 && (
            <Option
              key="select-all"
              value="select-all"
              className={styles.option}
            >
              <Checkbox
                checked={selectAllChecked}
                indeterminate={
                  selectedItems.length > 0 &&
                  selectedItems.length < options.length
                }
                onChange={handleSelectAll}
              >
                <span className={styles.optionLabel}>{t("filter.8")}</span>
              </Checkbox>
            </Option>
          )}

          {options.map((option, index) => (
            <Option
              className={
                selectedItems.includes(option.value)
                  ? styles.checkedOption
                  : styles.option
              }
              key={option.value}
              value={option.value}
              title={option.label}
            >
              <Checkbox
                checked={selectedItems.includes(option.value)}
                onChange={(e) => {
                  const event = e.nativeEvent;
                  if (!event.target.closest(`.ant-select-selection-item`)) {
                    const newSelectedItems = selectedItems.includes(
                      option.value
                    )
                      ? selectedItems.filter((item) => item !== option.value)
                      : [...selectedItems, option.value];

                    if (newSelectedItems.length > selectionLimit) {
                      const overflow = newSelectedItems.length - selectionLimit;
                      const trimmedSelection = newSelectedItems.slice(
                        overflow,
                        newSelectedItems.length
                      );
                      handleChange(trimmedSelection);
                    } else {
                      handleChange(newSelectedItems);
                    }
                  }
                }}
              >
                {option.img && <img src={option.img} width={22} height={22} />}{" "}
                <span className={styles.optionLabel}>{option.label}</span>
              </Checkbox>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default MultipleSelect;

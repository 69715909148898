import * as firebase from "firebase";
let firebaseConfig = {
  apiKey: "AIzaSyDcXxRqbiKe3W2kEJa-Isi1z-hHR11qfvs",
  authDomain: "pivonystaging.firebaseapp.com",
  databaseURL: "https://pivonystaging-default-rtdb.firebaseio.com",
  projectId: "pivonystaging",
  storageBucket: "pivonystaging.appspot.com",
  messagingSenderId: "910788297409",
  appId: "1:910788297409:web:2de1eae415d5c8980b0a40",
  measurementId: "G-9MDT2K9P6B",
};

if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCBkYE9vYyRfJv7zGYmzdnTLhnztvBq9h0",
    authDomain: "pivony-ab6d2.firebaseapp.com",
    databaseURL: "https://pivony-ab6d2.firebaseio.com",
    projectId: "pivony-ab6d2",
    storageBucket: "pivony-ab6d2.appspot.com",
    messagingSenderId: "951788257851",
    appId: "1:951788257851:web:26cedf42e13e570a12d21e",
    measurementId: "G-TYFPX16HEE",
  };
}
// Initialize Firebase
export const provider = new firebase.auth.GoogleAuthProvider();
export default !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase;

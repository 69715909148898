import React, { useEffect, useRef, useState } from "react";
import ReviewCard from "./reviewCard";
import styles from "./style.module.scss";

const ScrollingCards = ({ reviews, setAllReviewsScrolled }) => {
  const [currReviews, setCurrReviews] = useState(reviews?.slice(0, 2));
  const [index, setIndex] = useState(0);
  const cardRef = useRef();
  useEffect(() => {
    const updateReviews = () => {
      if (!reviews || reviews?.length === 0) {
        setAllReviewsScrolled(true);
        return;
      }
      const nextIndex = index + 2 >= reviews?.length ? 0 : index + 2;
      setCurrReviews(reviews.slice(nextIndex, nextIndex + 2));
      setIndex(nextIndex);
      if (nextIndex === 0 && reviews?.length > 0) {
        setAllReviewsScrolled(true);
      }
    };
    // Scroll reviews for every 25 seconds
    const interval = setInterval(updateReviews, 24 * 1000);
    return () => clearInterval(interval);
  }, [reviews, index]);
  if (reviews?.length === 0) return <></>;
  return (
    <div
      className={`${
        reviews?.length > 2 ? styles.cardsWrapperAnimate : styles.cardsWrapper
      }`}
      ref={cardRef}
    >
      {currReviews?.map((review) => {
        return (
          <div className={styles.cardWrapper}>
            <ReviewCard review={review} />
          </div>
        );
      })}
    </div>
  );
};

export default ScrollingCards;

import React, { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "../../../util/services";
import Representation from './Representation';
import i18n from 'i18next';

const Preferences = ({ user }) => {
  const [emailToggled, setEmailToggled] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [slackToggled, setSlackToggled] = useState();
  const [notificationStatus, setNotificationStatus] = useState([])

  const getSubscribtionStatus = useCallback(() => {
    const payload = {
      user_id: user.uid
    };
    apiRequest(
      "subscriptions/all",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        setSubscriptionStatus(result?.[0]);
        setNotificationStatus(result?.[0].notification)
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }, [user.uid])

  useEffect(() => {
    getSubscribtionStatus()
  }, [getSubscribtionStatus])

  return <Representation
    notificationStatus={notificationStatus}
    getSubscribtionStatus={getSubscribtionStatus}
    subscriptionStatus={subscriptionStatus}
  />
}

export default Preferences;
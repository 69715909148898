import React, { useState } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';
import i18n from 'i18next';

const Modal = ({ user, showModal, setShowModal, getSubscriptionStatus }) => {
  const [appId, setAppId] = useState("");
  const [appSecret, setAppSecret] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const onSubmit = async () => {
    const payload = {
      instagram_app_id: appId,
      instagram_app_secret: appSecret,
      access_token: accessToken
    }
    const result = await apiRequest("users/add_instagram_keys", "POST", payload, true);
    if (result && !result.error) {
      notification.success({
        message: i18n.t("notifications.113"),
      });
      getSubscriptionStatus();
    } else {
      notification.error({ message: result.error });
    }
    setShowModal(false);
  }

  return <Representation
    setAccessToken={setAccessToken}
    setAppSecret={setAppSecret}
    setAppId={setAppId}
    onSubmit={onSubmit}
    showModal={showModal}
    setShowModal={setShowModal}
  />
}

export default Modal;
import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { Button, notification, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { copyToClipboard, keyPressed } from "util/common";
import dayjs from "dayjs";
import { apiRequest } from "util/services";
import * as actions from "redux/Dashboard/actions.js";
import ShareButtonPopup from "helperComponents/ShareButtonPopup";
import { useTranslation } from "react-i18next";
import DateFilterPopUp from "../DateFilter";

const DashboardHeader = ({
  generalDataSrc,
  shared,
  currentDashboardHash,
  topicsType,
  user,
  insights,
  blur,
  openModal,
  disableDateBtn,
  setChangeDateRange,
  setShowDateFilter,
  savedSearch,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [allowDynamic, setAllowDynamic] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [teamsvisible, setTeamvisible] = useState(false);
  const [checkboxSelection, setCheckboxSelection] = useState([]);
  const [authViewers, setauthViewers] = useState(
    DashboardReducer.dashboard?.viewers || []
  );
  const [dynamicToggleStatus, setdynamicToggleStatus] = useState(
    DashboardReducer.dashboard?.RefreshRate || false
  );
  const [showDashbroadInfoPopup, setShowDashboardInfoPopup] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [showDynamicPopup, setShowDynamicPopup] = useState(false);
  const [showDateFilterPopup, setShowDateFilterPopup] = useState(false);
  const [selectedDaysRange, setSelectedDaysRange] = useState(
    DashboardReducer?.searchState?.selectedDaysRange
      ? DashboardReducer.searchState.selectedDaysRange
      : "last7d"
  );
  const [productLinks, setProductLinks] = useState([]);
  const [tempProductLinks, setTempProductLinks] = useState([]);
  const dynamicBtnRef = useRef();
  const dateFilterRef = useRef();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [queryFilters, setQueryFilters] = useState({});
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "decisionFilter" ||
          key === "child_others" ||
          key === "parent_others" ||
          key === "hotterm"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  useEffect(() => {
    if (
      DashboardReducer?.dashboard &&
      Object.keys(DashboardReducer?.dashboard)?.length !== 0
    ) {
      setauthViewers(DashboardReducer.dashboard?.viewers);
      setAllowDynamic(DashboardReducer?.dashboard?.PlatformId !== 3);
    }
  }, [DashboardReducer.dashboard]);
  useEffect(() => {
    if (queryFilters?.daysRange) {
      setSelectedDaysRange(queryFilters?.daysRange || "last7d");
    }
  }, [queryFilters.daysRange]);
  const fetchTeams = async () => {
    if (teamsvisible === true) {
      setTeamvisible(false);
      setCheckboxSelection(authViewers);
    } else {
      const payload = {
        dashboard_id: DashboardReducer.dashboard.ID,
      };
      const result = await apiRequest(
        "dashboards/viewers",
        "POST",
        payload,
        true
      );
      if (result.success) {
        if (result.success.length > 0) {
          setOrganizations(result.success);
          setCheckboxSelection(authViewers);
          setTeamvisible(true);
        } else {
          notification.error({
            message: t("notifications.49"),
          });
        }
      }
    }
  };
  const teamsShare = async (items) => {
    const payload = {
      team_ids_viewers: items.join(","),
      dashboard_id: DashboardReducer.dashboard.ID,
    };
    const result = await apiRequest(
      "dashboards/viewability",
      "POST",
      payload,
      true
    );
    if (result.success) {
      setauthViewers(checkboxSelection);
      notification.success({
        message: t("notifications.50"),
        description: "",
      });
    } else {
      notification.error({
        message: result.error,
        description: "",
      });
    }
    setTeamvisible(false);
  };

  const shareDashboard = async () => {
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    const result = await apiRequest(
      "dashboards/share/serialize",
      "POST",
      payload,
      true
    );
    if (!result.error) {
      notification.success({
        message: t("notifications.51"),
        duration: 0,
      });
      copyToClipboard(
        `${document.location.origin}/share/Dashboard/${result.hashed}`
      );
    } else {
      notification.error({
        message: result.error,
      });
    }
  };

  const getdashboardInfo = async () => {
    const result = await apiRequest(
      "dashboards/info",
      "POST",
      {
        dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
      },
      true
    );
    if (result && result.success) {
      setDashboardInfo(result.success);
      setShowDashboardInfoPopup(true);
      if (result.success?.platform_attributes[0]?.title === "Product Links") {
        let arr = [];
        for (let i = 0; i < result.success?.platform_attributes?.length; i++) {
          if (
            result.success?.platform_attributes[i].title === "Product Links"
          ) {
            arr = result.success?.platform_attributes[i].value.split("OR");
          }
        }
        if (arr?.length > 0) {
          setProductLinks(arr);
          setTempProductLinks(arr?.filter((link, index) => index < 3));
        }
      }
    } else {
      notification.error({
        message: t("notifications.52"),
      });
    }
  };

  const handleDynamic = async (changeToDynamic) => {
    let oldState = DashboardReducer?.dashboard?.RefreshRate ? true : false;
    if (oldState !== changeToDynamic) {
      const result = await apiRequest(
        "dashboards/dynamic",
        "POST",
        {
          dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
          refresh_rate: changeToDynamic ? "daily" : "cancel",
        },
        true
      );
      if (!result.error) {
        notification.success({
          message: t("notifications.53"),
        });
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            dashboard: {
              ...DashboardReducer.dashboard,
              RefreshRate: changeToDynamic ? "daily" : null,
            },
          },
        });
        // set toggle status
        setdynamicToggleStatus(changeToDynamic);
      } else {
        notification.error({
          message: result.error,
        });
      }
    }
  };

  const dynamicOutClick = (e) => {
    if (dynamicBtnRef.current && !dynamicBtnRef.current.contains(e.target)) {
      setShowDynamicPopup(false);
    }
  };

  const rangePresets = {
    last24: {
      label: t("filter.15"),
    },
    last7d: {
      label: t("filter.16"),
    },
    last14d: {
      label: t("filter.17"),
    },
    last30d: {
      label: t("filter.18"),
    },
    last90d: {
      label: t("filter.19"),
    },
    custom: {
      label: t("filter.20"),
    },
    allTime: {
      label: t("filter.21"),
    },
  };

  useEffect(() => {
    document.addEventListener("click", dynamicOutClick, true);
    return () => document.removeEventListener("click", dynamicOutClick, true);
  }, []);

  const handleCopyLink = (link) => {
    copyToClipboard(link);
    notification.success({
      message: t("notifications.54"),
    });
  };

  const handleShowAllLinks = () => {
    setTempProductLinks(productLinks);
  };

  const downloadDashboardFile = async () => {
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    const res = await apiRequest(
      "dashboards/file_download",
      "POST",
      payload,
      true
    );

    if (res.success) {
      notification.success({
        message: (
          <span>
            {t("notifications.47")}.
            <br />
            Go to{" "}
            <a
              onClick={() => {
                history.push({
                  pathname: `/console/report`,
                });
              }}
            >
              downloads page
            </a>
          </span>
        ),
      });
    } else {
      notification.error({
        message: res?.error ? res?.error : "Download failed",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5>
              {!shared && (
                <span
                  className={styles.allDashboardTitle}
                  onClick={() => history.push("/console/myDashboards")}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                  <span className={styles.allDashboardLabel}>
                    {t("dashboard_sidebar.1")}
                  </span>
                  <span className={styles.divider}>/</span>
                </span>
              )}
              <span className={styles.dashboardName}>
                {DashboardReducer.dashboard?.Name
                  ? DashboardReducer.dashboard?.Name
                  : DashboardReducer.dashboard?.Filename
                  ? DashboardReducer.dashboard?.Filename
                  : DashboardReducer.dashboard?.Keywords?.slice(0, 3).join(
                      ", "
                    )}
              </span>
            </h5>
            {/* <h6>
              {topicsType === 'insight' ? "" : generalDataSrc
                ? `${i18n.language === 'en' ? APP_MESSAGES.DATA_OVERVIEW.SUBHEADER : APP_MESSAGES_TUR.DATA_OVERVIEW.SUBHEADER}`
                : topicsType === "fixed" ? `${i18n.language === 'en' ? APP_MESSAGES.FIXED_TOPICS.SUBHEADER : APP_MESSAGES_TUR.FIXED_TOPICS.SUBHEADER}` : topicsType === "custom" ? `${i18n.language === 'en' ? APP_MESSAGES.CUSTOM_TOPICS.SUBHEADER : APP_MESSAGES_TUR.CUSTOM_TOPICS.SUBHEADER}` : `${i18n.language === 'en' ? APP_MESSAGES.AI_TOPICS.SUBHEADER : APP_MESSAGES_TUR.AI_TOPICS.SUBHEADER}`}
            </h6> */}
            {/* {(!shared && topicsType !== 'insight') && (
              <div className={styles.datepicker}>
                <DateRangePicker
                  onChange={
                    (value, dateString) => {
                      setChangeDateRange(true)
                      dispatch({
                        type: actions.UPDATE_SEARCH_STATE,
                        payload: {
                          dateRange: dateString,
                          chosenDate: ""
                        }
                      }
                      );
                    }
                  }
                  defaultStartDate={DashboardReducer?.start_date}
                  defaultEndDate={DashboardReducer?.end_date}
                  searchPeriod={[DashboardReducer?.searchState?.dateRange[0], DashboardReducer?.searchState?.dateRange[1]]}
                  disabled={disableDateRange} />
              </div>
            )} */}
          </div>

          {!shared && (
            <div className={styles.editBtn}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/info1.svg"
                    : "/assets/info1_black.svg"
                }
                alt=""
                className={styles.dashboardInfoBtn}
                onClick={() => getdashboardInfo()}
              />
            </div>
          )}
        </div>
        <div className={styles.headerRightSection}>
          {!shared &&
            topicsType !== "insight" &&
            topicsType !== "savedSearch" &&
            topicsType !== "journey" && (
              <button
                className={styles.dateFilterPopupBtn}
                onClick={() => setShowDateFilterPopup(!showDateFilterPopup)}
                disabled={disableDateBtn}
              >
                <img
                  src={
                    theme === "dark"
                      ? "/assets/calendar.svg"
                      : "/assets/calendar_black.svg"
                  }
                  alt=""
                />
                {rangePresets[params.get("daysRange") || "last24"].label}
                <img
                  src={
                    theme === "dark"
                      ? "/assets/arrow_down.svg"
                      : "/assets/arrow_down_black.svg"
                  }
                  alt=""
                />
              </button>
            )}
          {showDateFilterPopup && (
            <div ref={dateFilterRef}>
              <DateFilterPopUp
                onChange={(dateString, daysRange) => {
                  setChangeDateRange(true);
                  if (dateString[0]) {
                    updateQParams("since", dateString[0]);
                  } else {
                    params.delete("since");
                  }
                  if (dateString[1]) {
                    updateQParams("until", dateString[1]);
                  } else {
                    params.delete("until");
                  }
                  if (setShowDateFilter) setShowDateFilter(true);
                  params.delete("match_date");
                  updateQParams("daysRange", daysRange);
                  dispatch({
                    type: actions.UPDATE_SEARCH_STATE,
                    payload: {
                      dateRange: dateString,
                      selectedDaysRange: daysRange,
                      chosenDate: "",
                    },
                  });
                  setShowDateFilterPopup(false);
                  setSelectedDaysRange(daysRange);
                }}
                close={() => {
                  setShowDateFilterPopup(false);
                }}
                selectedDaysRange={selectedDaysRange}
                defaultStartDate={
                  queryFilters?.since && queryFilters?.until
                    ? queryFilters.since
                    : new Date()
                }
                defaultEndDate={
                  queryFilters?.since && queryFilters?.until
                    ? queryFilters.until
                    : new Date()
                }
                searchPeriod={[queryFilters?.since, queryFilters?.until]}
              />
            </div>
          )}
          {/* {allowDynamic && !shared && (topicsType === "ai" || generalDataSrc) && (
            <button ref={dynamicBtnRef} className={styles.dynamicPopupBtn} onClick={() => setShowDynamicPopup(true)}>
              <img src={theme === 'dark' ? "/assets/dynamic.svg" : "/assets/dynamic_black.svg"} alt="" />
              {
                showDynamicPopup && (
                  <div className={styles.dynamicBtn}>
                    <div className={styles.wrapper}>
                      <input
                        type="checkbox"
                        id="switch"
                        checked={dynamicToggleStatus}
                        onClick={(e) => handleDynamic(e.target.checked)}
                      />
                      <label for="switch">Toggle</label>
                      <div></div>
                      <span> {t("selected_dashboard_header.1")} </span>
                    </div>
                    <p>{t("selected_dashboard_header.2")}</p>
                  </div>
                )
              }
            </button>
          )} */}

          {!shared && (topicsType === "ai" || generalDataSrc) && (
            <div>
              {DashboardReducer.dashboard?.shareTeams === "teams" && (
                <ShareButtonPopup
                  marginRight={"0px"}
                  showSharePopup={teamsvisible}
                  setShowSharePopup={setTeamvisible}
                  headingText="Share with teams"
                  shareList={organizations}
                  handleShare={teamsShare}
                  shareClick={fetchTeams}
                  savedSearch={savedSearch}
                />
              )}
              <div className={styles.shareBtn}>
                {DashboardReducer.dashboard?.shareTeams === "public" && (
                  <Button
                    icon={<i className="fa fa-share" />}
                    onClick={shareDashboard}
                    disabled={savedSearch}
                  >
                    {t("button.26")}
                  </Button>
                )}
                {DashboardReducer.dashboard?.shareTeams === "disabled" && (
                  <Button icon={<i className="fa fa-share" />} disabled>
                    {t("button.26")}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {shared && (
        <div className={styles.bottom}>
          <div
            className={`${styles.navBtn} ${!generalDataSrc &&
              !insights &&
              styles.navBtnActive}`}
          >
            <Button
              onClick={() =>
                blur
                  ? openModal()
                  : history.push(
                      `${shared ? "/share/" : "/console/"}Dashboard/${
                        currentDashboardHash
                          ? currentDashboardHash
                          : `${DashboardReducer.dashboard?.ID}`
                      }`
                    )
              }
            >
              {t("selected_dashboard_header.3")}
            </Button>
          </div>
          <div
            className={`${styles.navBtn} ${generalDataSrc &&
              styles.navBtnActive}`}
            onClick={() =>
              blur
                ? openModal()
                : history.push(
                    `${shared ? "/share/" : "/console/"}DashboardData/${
                      currentDashboardHash
                        ? currentDashboardHash
                        : `${DashboardReducer.dashboard?.ID}`
                    }`
                  )
            }
          >
            <Button id="generaldata">{t("selected_dashboard_header.4")}</Button>
          </div>
          <div
            className={`${styles.navBtn} ${insights && styles.navBtnActive}`}
            onClick={() =>
              blur
                ? openModal()
                : history.push(
                    `${shared ? "/share/" : "/console/"}insights/${
                      currentDashboardHash
                        ? currentDashboardHash
                        : `${DashboardReducer.dashboard?.ID}`
                    }`
                  )
            }
          >
            <Button>{t("reports_downloads.20")}</Button>
          </div>
        </div>
      )}
      {showDashbroadInfoPopup && (
        <div className={styles.dashboardInfoPopupModal}>
          <div className={styles.dashboardInfoModalContent}>
            <div className={styles.titleBox}>
              <span className={styles.infoTitle}>
                {theme === "dark" ? (
                  <img src="/assets/info1.svg" alt="" />
                ) : (
                  <img src="/assets/info1_black.svg" alt="" />
                )}
                <span className={styles.infoTitleWithDownload}>
                  <span>{t("dashboard_info.1")}</span>
                  {DashboardReducer?.dashboard?.PlatformId === 3 ? (
                    <span
                      className={styles.downloadFileBtn}
                      onClick={downloadDashboardFile}
                    >
                      <Tooltip title="Download Dashboard File">
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/download.svg"
                              : "/assets/download_black.svg"
                          }
                          alt=""
                          width={26}
                          height={26}
                        />
                      </Tooltip>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </span>
              <span>
                {theme === "dark" ? (
                  <img
                    src="/assets/x.svg"
                    alt=""
                    onClick={() => setShowDashboardInfoPopup(false)}
                  />
                ) : (
                  <img
                    src="/assets/x_black.svg"
                    alt=""
                    onClick={() => setShowDashboardInfoPopup(false)}
                  />
                )}
              </span>
            </div>

            <div className={styles.belowContent}>
              <div className={styles.wrapperTop}>
                <div className={styles.wrapperItem}>
                  <span>
                    <img
                      width="22"
                      height="22"
                      className={styles.icon}
                      src={
                        theme === "dark"
                          ? "/assets/single_user.svg"
                          : "/assets/single_user_black.svg"
                      }
                      alt=""
                    />
                    {t("dashboard_info.2")}
                  </span>
                  <span>{dashboardInfo.owner_email}</span>
                </div>

                <div className={styles.wrapperItem}>
                  <span>{t("dashboard_info.3")}</span>
                  <span>{DashboardReducer.dashboard?.ID}</span>
                </div>
              </div>
              <div
                className={
                  dashboardInfo.date_range.length > 0
                    ? styles.wrapperMid
                    : styles.wrapperTop
                }
              >
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/d_name.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/d_name_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.4")}
                  </span>
                  <span>{dashboardInfo.Name}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/database.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/database_black.svg"
                        alt=""
                      />
                    )}
                    {t("selected_dashboard_page.7")}
                  </span>
                  <span>{dashboardInfo?.collected_data}</span>
                </div>

                {dashboardInfo.date_range.length > 0 && (
                  <div className={styles.wrapperItem}>
                    <span>
                      {theme === "dark" ? (
                        <img
                          className={styles.icon}
                          src="/assets/calendar.svg"
                          alt=""
                        />
                      ) : (
                        <img
                          className={styles.icon}
                          src="/assets/calendar_black.svg"
                          alt=""
                        />
                      )}
                      {t("dashboard_info.5")}
                    </span>
                    <span>{dashboardInfo.date_range}</span>
                  </div>
                )}
              </div>

              <div className={styles.wrapper}>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/database.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/database_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.6")}
                  </span>
                  <span>{dashboardInfo.Platform}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/category.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/category_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.7")}
                  </span>
                  <span>{dashboardInfo.category}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/outline_world.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/outline_world_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.8")}
                  </span>
                  <span>{dashboardInfo.lang}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/group.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/group_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.9")}
                  </span>
                  <span>{dashboardInfo.num_topics}</span>
                </div>
                {dashboardInfo.nps_scale && (
                  <div className={styles.wrapperItem}>
                    <span>
                      {theme === "dark" ? (
                        <img
                          className={styles.icon}
                          src="/assets/nps_scale.svg"
                          alt=""
                          width={24}
                          height={24}
                        />
                      ) : (
                        <img
                          className={styles.icon}
                          src="/assets/nps_scale_black.svg"
                          alt=""
                          width={24}
                          height={24}
                        />
                      )}
                      {"Rating Scale"}
                    </span>
                    <span>{dashboardInfo.nps_scale}</span>
                  </div>
                )}
              </div>

              {dashboardInfo?.platforms?.length > 0 && (
                <div className={styles.platformTags}>
                  <span>{t("dashboard_info.10")}</span>
                  {dashboardInfo?.platforms?.map((item) => {
                    return <span>{item}</span>;
                  })}
                </div>
              )}
              {dashboardInfo?.pivot_cols && (
                <div className={styles.platformTags}>
                  <span>{t("dashboard_info.11")}</span>
                  {dashboardInfo?.pivot_cols.split(",")?.map((item) => {
                    return <span>{item}</span>;
                  })}
                </div>
              )}
              {productLinks?.length > 0 && (
                <p className={styles.productLinkLabel}>{t("others.54")}</p>
              )}
              {dashboardInfo?.platform_attributes?.length > 0 && (
                <div className={styles.platformTagsDetailWrapper}>
                  {productLinks?.length > 0
                    ? tempProductLinks?.map((item) => {
                        return (
                          <div className={styles.tagDetails}>
                            <span>
                              {item.length > 20
                                ? item.substring(0, 20) + "..."
                                : item}
                              {item ? (
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/copy.svg"
                                      : "/assets/copy_black.svg"
                                  }
                                  onClick={() => handleCopyLink(item)}
                                  alt=""
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>
                        );
                      })
                    : dashboardInfo?.platform_attributes?.map((item) => {
                        return (
                          <div className={styles.tagDetails}>
                            {item.title && (
                              <>
                                <span>{item.title}</span>
                                <span>
                                  {item?.value?.length > 20
                                    ? item?.value.substring(0, 20) + "..."
                                    : item?.value}
                                  {item?.value ? (
                                    <img
                                      src={
                                        theme === "dark"
                                          ? "/assets/copy.svg"
                                          : "/assets/copy_black.svg"
                                      }
                                      onClick={() => handleCopyLink(item.value)}
                                      alt=""
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                        );
                      })}
                </div>
              )}
              {productLinks?.length > 2 &&
                productLinks?.length !== tempProductLinks?.length && (
                  <p
                    className={styles.showMoreLabel}
                    onClick={handleShowAllLinks}
                  >
                    {t("others.51")}
                  </p>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;

import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import DashboardHeader from "components/Dashboard/Header";
import NestedTabs from "components/NestedTabs";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { Spin, notification, Button, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import SavedSearchItem from "./SavedSearchItem";
import { apiRequest } from "util/services";
import { UNLOGGED } from "util/constants";
import { useTranslation } from "react-i18next";
import SortPopup from "helperComponents/SortPopup";
const { Option } = Select;
const SavedSearches = ({
  authenticated,
  user,
  currentDashboard,
  shared,
  currentDashboardHash,
  match,
  theme,
}) => {
  const [loading, setLoading] = useState(true);
  const [searchItems, setSearchItems] = useState([]);
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortValue, setSortValue] = useState("newest");
  const [searchWord, setSearchWord] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (!currentDashboard && !DashboardReducer.dashboard && !match.params.id) {
    history.push("/console/myDashboards");
  }
  let initialState = {
    currentDashboard: shared
      ? currentDashboard
      : match.params.id || DashboardReducer.dashboard?.ID,
  };
  const [localState, setState] = useState(initialState);
  useEffect(() => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    if (authenticated === UNLOGGED && !shared) {
      document.location.href = document.location.origin;
      return;
    }
    if (!localState.currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/console/myDashboards");
      return;
    }
    const getDashboardID = async () => {
      const payload = {
        hashed: localState.currentDashboard,
      };
      const result = await apiRequest(
        "dashboards/share/deserialize",
        "POST",
        payload
      );
      if (result) {
        setState({ ...localState, currentDashboard: result.dashboard_id });
        checkDashboardStatus(result.dashboard_id);
      } else {
        notification.error({
          message: t("notifications.43"),
        });
        document.location.href = document.location.origin;
        return;
      }
    };
    if (shared) getDashboardID();
    else checkDashboardStatus(localState.currentDashboard);
  }, []);
  const checkDashboardStatus = async (DashboardId) => {
    const result = await apiRequest(
      "dashboards/v2/get",
      "POST",
      {
        dashboard_id: parseInt(DashboardId, 10),
      },
      true
    );

    if (result.cancel || result.error) {
      setLoading(false);
      notification.error({
        message: result.error ? result.error : "Unable to get dashboard status",
      });
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          loading: false,
        },
      });
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    } else if (result.status.ID === -1 || result.status.ID === 5) {
      notification.info({
        message: t("notifications.44"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/myDashboards`);
    } else if (
      result.status.ID !== 4 &&
      result.status.ID !== 6 &&
      result.status.ID !== 7 &&
      !result?.dashboard?.IsDynamic
    ) {
      notification.info({
        message: t("notifications.45"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/journey/${initialState.currentDashboard}`);
    }
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        ...result,
        loading: false,
      },
    });
    getAllSavedSearches();
  };
  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };
  const handleBackward = async () => {
    setShowRowItems(true);
  };
  const getAllSavedSearches = async () => {
    setLoading(true);
    const payload = {
      dashboard_id: parseInt(initialState.currentDashboard, 10),
      sort: sortValue || "newest",
      start_index: startIndex,
      page_size: pageSize,
    };
    if (searchWord !== "") {
      payload.search = searchWord;
    }
    const result = await apiRequest(
      "dashboards/saved-searches/list",
      "POST",
      payload,
      true
    );
    if (result.success) {
      setSearchItems(result.success.docs);
      setTotalItems(result.success.total_docs);
    } else {
      notification.error({
        message: result?.error ? result.error : "Unable to get saved views",
      });
    }
    setLoading(false);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setStartIndex(0);
      getAllSavedSearches();
    }
  };
  const handleUserPage = (event) => {
    if (event.key === "Enter") {
      if (event.target.value < 1) {
        notification.warning({
          message: "Page number cannot be 0 or empty",
        });
        return;
      } else if (event.target.value > Math.ceil(totalItems / pageSize)) {
        notification.warning({
          message: "Page number cannot be greater than total pages",
        });
        return;
      }
      setStartIndex(parseInt(event.target.value, 10) - 1);
    }
  };
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setStartIndex(0);
  };
  const stopFirstCall = useRef(true);
  useEffect(() => {
    if (stopFirstCall.current) {
      stopFirstCall.current = false;
      return;
    }
    setPageNumber(startIndex + 1);
    getAllSavedSearches();
  }, [startIndex, sortValue, pageSize]);
  return (
    <div className={styles.mainWindow}>
      <DashboardHeader
        shared={shared}
        currentDashboardHash={match.params.id}
        user={user}
        topicsType="savedSearch"
      />
      {!shared && <NestedTabs />}
      <Spin spinning={loading}>
        {showRowItems && (
          <div className={styles.firstRow}>
            <div className={styles.actionBtnBlock}>
              <span className={styles.search} onClick={handleShowSearch}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/search.svg"
                      : "/assets/search_black.svg"
                  }
                  alt=""
                />
              </span>
              <span
                className={styles.search}
                onClick={() => {
                  getAllSavedSearches();
                }}
                title="Refresh status"
              >
                <img
                  src={
                    theme === "dark"
                      ? "/assets/refresh.svg"
                      : "/assets/refresh_black.svg"
                  }
                  width={18}
                  height={18}
                />
              </span>
              <img
                src={
                  theme === "dark"
                    ? "/assets/sort_icon.svg"
                    : "/assets/sort_icon_black.svg"
                }
                alt=""
                className={styles.sort}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSortPopup(!showSortPopup);
                }}
              />
              {showSortPopup && (
                <SortPopup
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  setShowSortPopup={setShowSortPopup}
                />
              )}
              {window.innerWidth > 880 && (
                <div
                  className={`${styles.searchBox} ${showSearchBox &&
                    styles.showSearch} `}
                >
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/search.svg"
                        : "/assets/search_black.svg"
                    }
                    alt=""
                  />
                  <input
                    type="text"
                    placeholder={"Search here and hit 'Enter.'"}
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                    onKeyPress={handleSearch}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!showRowItems && (
          <div
            className={`${styles.searchBoxSmall} ${showSearchBox &&
              styles.showSearch} `}
          >
            <i
              onClick={() => {
                handleBackward();
              }}
              className="fa-solid fa-arrow-left"
            ></i>
            <input
              type="text"
              placeholder={"Search here and hit 'Enter.'"}
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onKeyPress={handleSearch}
            />
            <button onClick={handleSearch}>{t("button.5")}</button>
          </div>
        )}
        {searchItems.length !== 0 && <SavedSearchItem header />}
        {searchItems.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginTop: "1rem",
              color: theme === "dark" ? "white" : "black",
            }}
          >
            <p>No Saved Views Found</p>
          </div>
        ) : (
          searchItems.map((item) => (
            <SavedSearchItem
              item={item}
              dashboardId={parseInt(initialState.currentDashboard, 10)}
              getAllSavedSearches={getAllSavedSearches}
            />
          ))
        )}
        {searchItems?.length !== 0 && (
          <div className={styles.paginationWrapper}>
            <div className={styles.innerWrapper}>
              {
                <div className={styles.paginationBox}>
                  <Button
                    disabled={startIndex === 0}
                    onClick={() => {
                      setStartIndex((prev) => prev - 1);
                    }}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <span className={styles.pagination}>
                    <input
                      className={styles.pageIndex}
                      onKeyPress={handleUserPage}
                      type="number"
                      min={1}
                      step={1}
                      onChange={(e) => {
                        setPageNumber(parseInt(e.target.value, 10));
                      }}
                      max={Math.ceil(totalItems / pageSize)}
                      value={pageNumber}
                    />
                    {` / ${Math.ceil(totalItems / pageSize)}`}
                  </span>
                  <Button
                    disabled={
                      startIndex + 1 === Math.ceil(totalItems / pageSize)
                    }
                    onClick={() => {
                      setStartIndex((prev) => prev + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                </div>
              }

              {searchItems?.length > 0 && (
                <Select
                  className="review-page-number-selector"
                  value={pageSize + ` / ${t("label.10")}`}
                  onChange={handlePageSizeChange}
                  style={{ backgroundColor: "transparent" }}
                >
                  <Option value={10}>10</Option>
                  <Option disabled={totalItems <= 10 ? true : false} value={25}>
                    25
                  </Option>
                  <Option disabled={totalItems <= 25 ? true : false} value={50}>
                    50
                  </Option>
                  <Option
                    disabled={totalItems <= 50 ? true : false}
                    value={100}
                  >
                    100
                  </Option>
                </Select>
              )}
            </div>
            {totalItems > 0 && (
              <div className={styles.dashboardsTotal}>
                {pageSize * startIndex +
                  searchItems?.length +
                  " of " +
                  totalItems}
              </div>
            )}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default SavedSearches;

import styles from "./ruleBuilder.module.scss"
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;


const RuleBuilder = ({ close, save }) => {
    const [rules, setRules] = useState([])
    const [query, setQuery] = useState("")
    const { t } = useTranslation();

    const addRule = () => {
        let newRules = [...rules]
        newRules.push({
            id: "new",
            type: "single",
            operator: " AND ",
            searchType: "default",
            value: "",
        })
        setRules(newRules)
    }
    const addNesetedRule = () => {
        let newRules = [...rules]
        newRules.push({
            id: "new",
            type: "grouped",
            operator: " AND ",
            childerns: [
                {
                    id: "new2",
                    type: "single",
                    operator: " AND ",
                    searchType: "default",
                    value: "",
                }
            ]
        })
        setRules(newRules)
    }

    useEffect(() => {
        let query = QueryBuilder(rules)
        setQuery(query)
    }, [rules])

    const QueryBuilder = (queryRules) => {
        let newQuery = ""

        if (queryRules.length) {
            queryRules.forEach((rule, index) => {
                if (rule?.operator && index !== 0) {
                    newQuery = newQuery + rule.operator + " "
                }
                if (rule?.value?.length) {
                    if (rule?.searchType === 'default') {
                        newQuery = newQuery + rule.value
                    } else if (rule?.searchType === 'match') {
                        newQuery = newQuery + rule.value + "* "
                    } else if (rule?.searchType === 'exactAsterik'){
                        newQuery = newQuery + `"${rule.value}*"` + " "
                    } else {
                        newQuery = newQuery + `"${rule.value}"` + " "
                    }
                }
                if (rule?.childerns?.length) {
                    newQuery = newQuery + "( " + QueryBuilder(rule.childerns) + " )"
                }

            })
        }
        return newQuery
    }

    const handleUpdate = (node, index) => {
        let newRules = [...rules]
        newRules[index] = node
        setRules(newRules)
    }
    const handleDelete = (index) => {
        let newRules = [...rules]
        newRules.splice(index, 1)
        setRules(newRules)

    }
    const addRuleToNested = (index) => {
        const newRules = [...rules]
        newRules[index].childerns.push({
            id: "new",
            type: "single",
            operator: " AND ",
            searchType: "default",
            value: "",
        })
        setRules(newRules)
    }
    const theme = useSelector(state => state.DashboardReducer.theme);
    return <div className={styles.modalContent}>
        <div className={styles.header}>
            <span>
            <img src={theme === 'dark' ? "/assets/rule_builder.svg" : "/assets/rule_builder_black.svg"} alt="" />
            Easy Query Builder
            </span>

                <span>
                <a href="https://pivony.notion.site/Custom-Topics-Creation-56313a67db974e06ba0f9536af8bf3fa" target="_blank"  >
                    <img src={theme === 'dark' ? "/assets/help-circle.svg" : "/assets/help-circle-black.svg"} alt="" />
                    </a>
                </span>
        </div>
        <div className={styles.rulesWrapper}>
            {rules?.map((val, index) => {
                return <WhereFilter type={val.type} val={val} index={index} updateRuleValues={(node) => {
                    handleUpdate(node, index)
                }} addNewRule={() => addRuleToNested(index)} deleteNode={() => { handleDelete(index) }} />
            }
            )}
        </div>
        <div className={styles.wrapper}>
            <div className={styles.buttonWrapper}>
                <div className={styles.button} onClick={addRule}>
                    + Add Rule
                </div>
                {rules.length > 0 ? <div className={styles.button} onClick={addNesetedRule}>
                    + Add Nested Rule
                </div> : ""}

            </div>
            {
                rules.length ? <div className={styles.button} onClick={() => {
                    setRules([])
                }}>
                    Reset
                </div> : ""
            }

        </div>

        {
            rules.length && query.length ? <div className={styles.rulesViewer}>
                <p>Your Query</p>
                <div className={styles.rulesQuery}>
                    {query}
                </div>
            </div> : ""
        }

        <div className={styles.footer}>
        <div className={styles.texts}>
            <p className={styles.text} onClick={close} >
                <img className={styles.removeTopicIcon} width={16} style={theme === 'dark' ? { fill: "white" } : { fill: "black" }} src={"/assets/uil_pen.svg"} alt="" />
                Build rule manually</p>
        </div>
          
            <div className={styles.btns}>
                <button onClick={close} >Cancel</button>
                <button onClick={() => { save(query) }}>Save</button>
            </div>
        </div>

    </div>
}

const WhereFilter = ({ index, val, type, addNewRule, updateRuleValues, deleteNode }) => {
    const theme = useSelector(state => state.DashboardReducer.theme);
    const options = [
        {
            id: "default",
            name: "Use lemma of the keyword"
        },
        {
            id: "match",
            name: "Use keyword and lemma with suffixes"
        },
        {
            id: "exact",
            name: "Use Exact search (does not accept suffixes)"
        },
        {
            id: "exactAsterik",
            name: "Use Exact keyword with suffixes"
        }
    ]

    const updateChildNode = (index, node) => {
        let childRules = [...val.childerns];
        childRules[index] = node
        let newVal = val;
        val.childerns = childRules
        updateNode(newVal)
    }

    const handleChildresNodeDelete = (index) => {
        let childRules = [...val.childerns];
        childRules.splice(index, 1)
        let newVal = val;
        val.childerns = childRules
        updateNode(newVal)

    }

    const handleDelete = (index) => {
        deleteNode(index)
    }

    const updateNode = (node) => {
        updateRuleValues(node)
    }
    const handleUpdate = (key, value) => {
        let node = val
        node[key] = value
        updateNode(node)
    }

    if (type === 'grouped') {
        return <> <div className={styles.groupedWhereFilter}>
            <div className={styles.item}>
                {index === 0 ? <span className={styles.subLabel}>Where</span>
                    : <Select
                        className={styles.operatorSelector}
                        value={val.operator}
                        onChange={(e) => handleUpdate("operator", e)}
                    >
                        <Option value=" AND " key="AND" >AND</Option>
                        <Option value=" OR " key="OR" >OR</Option>
                    </Select>
                }
            </div>
            <div className={styles.wrapper}>
                {
                    val?.childerns?.map((newVal, i) => (
                        <WhereFilter index={i} val={newVal} type={newVal.type} updateRuleValues={(node) => {
                            updateChildNode(i, node)
                        }} deleteNode={() => { handleChildresNodeDelete(i) }} />
                    ))
                }
                <div className={styles.buttonWrapper}>

                    <div className={styles.buttonWrapper}>
                        <div className={styles.button} onClick={addNewRule}>
                            + Add Rule
                        </div>
                        <div className={styles.button} onClick={() => {
                            handleDelete(index)
                        }}>
                            <div className={styles.delete} ><img className={styles.removeTopicIcon} src={theme === 'dark' ? "/assets/trash.svg" : "/assets/trash_black.svg"} alt="" /></div>
                            delete rule
                        </div>
                    </div>
                </div>

            </div>



        </div>

        </>
    } else {
        return <> <div className={styles.whereFilterWrapper}>
            <div className={styles.item}>
                {index === 0 ? <span className={styles.subLabel}>Where</span>
                    : <Select
                        value={val.operator}
                        onChange={(e) => { handleUpdate("operator", e) }}
                        className={styles.operatorSelector}
                    >
                        <Option value=" AND " key="AND" >AND</Option>
                        <Option value=" OR " key="OR" >OR</Option>
                    </Select>
                }
            </div>
            <div className={styles.field}>
                <input type="text" placeholder='Enter text' onChange={(e) => {
                    handleUpdate("value", e.target.value)
                }} />
            </div>
            <Select
                placeholder="Select Custom Topics"
                value={val.searchType}
                style={{ width: "100%" }}
                showArrow
                maxTagCount='responsive'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                onChange={(e) => handleUpdate("searchType", e)}
            >
                {options?.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
            {index == 0 ? "" : <div className={styles.delete} onClick={() => { handleDelete(index) }} ><img className={styles.removeTopicIcon} src={theme === 'dark' ? "/assets/trash.svg" : "/assets/trash_black.svg"} alt="" /></div>
            }
        </div>
        </>

    }

}


export default RuleBuilder
import React from 'react';
import Representation from './Representation';

const ProgressBar = ({
  progress
}) => (
    <Representation
      completed={progress}
    />
  );

export default ProgressBar;
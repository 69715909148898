import React, { useState } from 'react';
import styles from './style.module.scss';
import CustomButton from 'helperComponents/CustomButton';
import { useTranslation } from 'react-i18next';
const AddRule = ({ showAddRulePopup, setShowAddRulePopup, onClick, renameRuleValue, setRenameRuleValue, disableBtn }) => {
   const { t } = useTranslation();

   return (
      <div className={styles.modal}>
         <div className={styles.contentCenterWrapper}>
            <div className={styles.addRuleContent}>
               <p className={styles.heading}>Add Topic Rule</p>
               <div className={styles.inputRuleBox}>
                  <p>Rule definition</p>
                  <textarea value={renameRuleValue} onChange={e => setRenameRuleValue(e.target.value)} rows="2" placeholder='Enter rules'></textarea>
               </div>
               <p className={styles.exampleText}>E.g. "Credi" AND Card" AND (Cancel OR Payment)</p>
               <div className={styles.btnBlock}>
                  <button onClick={() => setShowAddRulePopup(false)} className={styles.cancelBtn}>Cancel</button>
                  <CustomButton text={t("button.7")} onClick={onClick} loading={disableBtn} />
               </div>
            </div>
         </div>
      </div>
   )
}
export default AddRule;
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { useMediaQuery } from 'react-responsive'
import Representation from './Representation';
import { apiRequest } from 'util/services';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Teams = ({ user }) => {
  const history = useHistory();
  const [teams, setTeams] = useState([])
  const [deleteModalUser, setDeleteModalUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

  const { t } = useTranslation();

  const permissions = [
    t("settings.182"),
    t("settings.183"),
    t("settings.184")
  ]
  
  const getAccessLevel = (access) => {
    switch (access) {
      case "wrd":
        return t("settings.184");
      case "rw":
        return t("settings.183");
      case "r":
        return t("settings.182");
      default:
        return t("settings.182");
    }
  }
  
  const mapPermissions = (permission) => {
    switch (permission) {
      case t("settings.184"):
        return "wrd"
      case t("settings.183"):
        return "rw";
      default:
        return "r";
    }
  }
  

  const fetchTeams = useCallback(() => {
    const payload = {
      user_id: user.uid,
    }
    apiRequest(
      "users/organization/teams",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        setTeams(result.success);
        setLoading(false);
      } else {
        notification.error({
          message: result.error,
        });
        setLoading(false);
      }
    })
  }, [user.uid]);

  useEffect(() => {
    setLoading(true);
    fetchTeams();
  }, [fetchTeams]);

  const deleteMember = (email) => {
    const payload = {
      user_id: user.uid,
      email_to_be_deleted: email
    }
    apiRequest(
      "users/organization/delete_user",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        fetchTeams();
        setDeleteModalUser(null);
        notification.success({
          message: i18n.t("notifications.130"),
        });
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }

  const updatePermission = (val, id, email) => {
    const payload = {
      admin_id: user.uid,
      organization_ids: [id],
      user_emails: [email],
      new_permissions: [mapPermissions(val)]
    }
    apiRequest(
      "users/organization/change_permission",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        fetchTeams();
        setDeleteModalUser(null);
        notification.success({
          message: i18n.t("notifications.131"),
        });
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }

  return <Representation
    isMobile={isMobile}
    teams={teams}
    getAccessLevel={getAccessLevel}
    permissions={permissions}
    deleteMember={deleteMember}
    deleteModalUser={deleteModalUser}
    setDeleteModalUser={setDeleteModalUser}
    history={history}
    updatePermission={updatePermission}
    fetchTeams={fetchTeams}
    loading={loading}
  />
}

export default Teams;
import React, { useEffect, useState } from "react";
import Loading from "../../helperComponents/Loading";
import PasswordVerification from "./PasswordVerification";
import ResetPassword from "./ResetPassword";
import EmailVerification from "./EmailVerification";
import { getParams } from "../../util/common";
import { UNKNOWN } from "../../util/constants";

const Verification = (props) => {
  const [params, setParams] = useState({ mode: "loading" });

  useEffect(() => {
    setParams(getParams(document.location.href));
  }, []);

  const layout = {
    loading: <Loading />,
    resetPassword: <ResetPassword code={params.oobCode} />,
    verifyEmail: (
      <EmailVerification
        user={props.user}
        history={props.history}
        authenticated={props.authenticated}
        continueUrl={params.continueUrl}
      />
    ),
  };
  // if (params.mode === "resetPassword") {
  //   document.location.href =  document.location.origin + `/reset-password?oobCode=${params.oobCode}`;
  //   return <div></div>
    
  // } else {
    return props.authenticated === UNKNOWN ? layout.loading : layout[params.mode];
  
};

export default Verification;

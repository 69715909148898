import React from "react";
import { Select } from "antd";
import styles from "./style.module.scss";

const Representation = ({
  list,
  onChange,
  placeholder,
  displayKey,
  idKey,
  title,
}) => (
  <div className={styles.row}>
    <p className={styles.label}>{title}</p>
    <div className={styles.dropdown}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder={placeholder}
        onChange={onChange}
        showSearch
        optionFilterProp="title"
      >
        {list?.map((elem) => (
          <Select.Option
            key={elem[idKey]}
            value={elem[idKey]}
            title={elem[displayKey]}
          >
            {elem[displayKey]}
          </Select.Option>
        ))}
      </Select>
    </div>
  </div>
);

export default Representation;

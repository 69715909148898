import XLSX from "xlsx";

export const getFileHeaderFromXLSX = (file) => {
  const reader = new FileReader();

  const result = new Promise((resolve) => {
    reader.onload = () => {
      const binaryStr = reader.result;
      let response = {
        success: false,
        encrypted: false,
        headers: [],
        message: "",
      };

      try {
        // Attempt to read the file
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheet_name_list = workbook.SheetNames;
        let columnHeaders = [];

        // Extract column headers
        for (
          let sheetIndex = 0;
          sheetIndex < sheet_name_list.length;
          sheetIndex++
        ) {
          const worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
          for (let key in worksheet) {
            const regEx = new RegExp("^([A-Za-z]{1,2})(1){1}$");
            if (regEx.test(key)) {
              columnHeaders.push(worksheet[key].v);
            }
          }
        }

        response = {
          success: true,
          encrypted: false,
          headers: columnHeaders,
          message: "File processed successfully.",
        };
      } catch (error) {
        // Handle encrypted or invalid files
        if (error.message.includes("password")) {
          response = {
            success: false,
            encrypted: true,
            headers: [],
            message: "Upload failed! File protected by password",
          };
        } else {
          response = {
            success: false,
            encrypted: false,
            headers: [],
            message: "Failed to process the file.",
          };
        }
      }

      resolve(response);
    };

    reader.onerror = () => {
      resolve({
        success: false,
        encrypted: false,
        headers: [],
        message: "Failed to read the file.",
      });
    };

    reader.readAsArrayBuffer(file);
  });

  return result;
};

import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const ShareButtonPopup = ({
  setShowSharePopup,
  showSharePopup,
  headingText,
  handleShare,
  shareList,
  shareClick,
  marginRight,
  savedSearch,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems(selectedItems.filter((e) => e !== value));
    }
  };

  useEffect(() => {
    if (!showSharePopup) {
      setSelectedItems([]);
    }
    if (showSharePopup) {
      // shareList.map(item => {
      //    if (item.status === 1) {
      //       setSelectedItems([...selectedItems, item.team_id]);
      //    }

      // })
      let arr = [];
      for (let i = 0; i < shareList.length; i++) {
        if (shareList[i].status === 1) {
          arr.push(shareList[i].team_id);
        }
      }
      setSelectedItems(arr);
    }
  }, [showSharePopup]);

  // useEffect(() => {
  //    if (showSharePopup) {
  //       let items = document.querySelectorAll('.share-team-name');
  //       if (shareList.length !== 0) {
  //          shareList.map(item => {
  //             for (let i = 0; i < items.length; i++) {
  //                if (items[i].value === item.team_id && item.status === 1) {
  //                   items[i].checked = true;
  //                   setSelectedItems([...selectedItems, items[i].value]);
  //                }
  //             }
  //          })
  //       }
  //    }
  //    if (!showSharePopup) setSelectedItems([])
  // }, [showSharePopup]);

  const handleShowPopup = () => {
    if (shareList.length === 0) {
      notification.error({
        message: t("notifications.49"),
      });
    } else setShowSharePopup(true);
  };

  return (
    <>
      <button
        type="button"
        disabled={savedSearch}
        className={`${styles.shareBtn} ${styles.share}`}
        onClick={shareClick ? shareClick : handleShowPopup}
        style={{
          color: theme === "dark" ? "" : "#111111",
          marginRight: marginRight ? marginRight : "24px",
        }}
      >
        {theme === "dark" ? (
          <img src="/assets/users.svg" alt="" />
        ) : (
          <img src="/assets/users_black.svg" alt="" />
        )}
        {t("button.13")}
      </button>
      {showSharePopup && (
        <div
          className={styles.sharePopupModal}
          onClick={() => setShowSharePopup(false)}
        >
          <div className={styles.modalContent}>
            <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
              <p className={styles.title}>{headingText}</p>
              <div className={styles.itemsWrapper}>
                {shareClick
                  ? shareList?.map((item, index) => (
                      <div className={styles.item} key={index}>
                        <input
                          type="checkbox"
                          defaultChecked={item.status === 1 ? true : false}
                          className="share-team-name"
                          id={item.team_id}
                          value={item.team_id}
                          onChange={handleChange}
                        />
                        <label htmlFor={item.team_id}>{item.team_name}</label>
                      </div>
                    ))
                  : shareList?.map((item, index) => (
                      <div className={styles.item} key={index}>
                        <input
                          type="checkbox"
                          className="share-team-name"
                          id={item.team_id}
                          value={item.team_id}
                          onChange={handleChange}
                          defaultChecked={item.status === 1 ? true : false}
                        />
                        <label htmlFor={item.team_id}>{item.team_name}</label>
                      </div>
                    ))}
              </div>
              <div className={styles.actionBtn}>
                <button type="button" onClick={() => setShowSharePopup(false)}>
                  {t("button.3")}
                </button>
                <button
                  type="button"
                  onClick={() => handleShare(selectedItems)}
                >
                  {t("button.16")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareButtonPopup;

import React, { useEffect } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import firebase from "../../FirebaseConfig";
import "../../stylesheets/EmailVerification.css";
// import Subscriptions from "../../LandingComponents/Subscriptions";
import Signup from "../Signup";

const EmailVerification = ({ user, history, continueUrl }) => {
  useEffect(() => {
    if (user?.uid) {
      const payload = {
        user_id: user.uid
      };
      apiRequest(
        "users/emailVerification",
        "POST",
        payload,
        true
      ).then(result => {
        if (!result.error) {
          firebase.database().ref(`/users/${user.uid}/emailVerified`).set(true);
          notification.success({
            message: "Success",
            description: "Email verified successfully",
          });
          if (continueUrl) history.push(continueUrl.replace(document.location.origin, ""))
        } else {
          notification.error({
            message: result.error,
          });
        }
      })
    } else if (!user?.uid) {
      notification.error({
        message: "Incorrect page",
        description: "You will be directed to pivony home page",
      });
      history.push("/");
    }
  }, []);

  return <Signup user={user} />;
};

export default EmailVerification;

import React, { useState } from "react";
import styles from "./styles.module.scss";
import { notification, Menu, Dropdown, Select, DatePicker, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import { Tree } from "antd";
import { apiRequest } from "util/services";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import DateFilterPopUp from "components/Dashboard/DateFilter";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Step } = Steps;
dayjs.extend(weekday);
dayjs.extend(localeData);

const startAnalysis = async (
  id,
  name,
  text_questions,
  nps_questions,
  close,
  since,
  until
) => {
  const payload = {
    survey_id: id,
    survey_name: name,
    text_questions: text_questions,
    nps_question: nps_questions,
    since: since,
    until: until,
  };
  const result = await apiRequest("survey/analysis", "POST", payload, true);
  if (result?.success) {
    notification.success({ message: result?.success });
    close();
  } else {
    notification.error({ message: result?.error });
  }
};

const MoreOptions = function({ id, date, name, setShowSurveyQuestions }) {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  function handleStartAnalysis(e) {
    // e.domEvent.stopPropagation();
    setShowSurveyQuestions(true);
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu className={styles.customMenu} theme="dark">
        {!date && (
          <Menu.Item
            className={styles.customMenuItem}
            key={1}
            onClick={handleStartAnalysis}
          >
            <img
              className={styles.more}
              width={20}
              src={
                theme === "dark"
                  ? "/assets/analyze_dashboards.svg"
                  : "/assets/survey_black.svg"
              }
              alt=""
            />
            Start Analysis
          </Menu.Item>
        )}
        <Menu.Item
          key={2}
          onClick={() => {
            downloadSurvey(id, date, name);
          }}
        >
          <img
            className={styles.more}
            width={20}
            src={
              theme === "dark"
                ? "/assets/download.svg"
                : "/assets/download_black.svg"
            }
            alt=""
          />

          {t("reports_downloads.12")}
        </Menu.Item>
        {/* <Menu.Item key={3}>
                <img className={styles.more} width={20} src={theme === 'dark' ? "/assets/trash.svg" : "/assets/trash_black.svg"} alt="" />
                Delete
            </Menu.Item> */}
      </Menu>
    </div>
  );
};
const CarryOutOutlined = () => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <img
      className={styles.more}
      width={20}
      src={theme === "dark" ? "/assets/survey.svg" : "/assets/survey_black.svg"}
      alt=""
    />
  );
};

const SurveyQuestionsPopUp = ({
  survey_id,
  survey_name,
  text_questions,
  nps_questions,
  close,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const [selectedNpsQuestionId, setSelectedNpsQuestionId] = useState([]);
  const [since, setSince] = useState("");
  const [until, setUntil] = useState("");

  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    setCurrent(value);
  };
  const handleSelectQuestions = (value) => {
    setSelectedQuestionIds(value);
  };
  const handleSelectNpsQuestion = (value) => {
    setSelectedNpsQuestionId(value);
  };
  function onMouseEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <>
      <div className={styles.surveryInfoModalPopup}>
        <div className={styles.dashboardInfoModalContent}>
          <div className={styles.header}>
            <div className={styles.content}>
              <p className={styles.title}>{survey_name} Survey Questions</p>
            </div>
            <div
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation();
                close();
              }}
            >
              <img
                className={styles.message_img}
                src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
              />
            </div>
          </div>
          <div className={styles.steps}>
            <Steps current={current} type="navigation" size="small">
              <Step navArrowColor="white" title="Select"></Step>
              <Step navArrowColor="white" title="Digest"></Step>
            </Steps>
          </div>

          <div className={styles.content}>
            {current === 0 ? (
              <>
                <div className={styles.field}>
                  <p className={styles.boxLabel}>Select text questions:</p>
                  <Select
                    placeholder=""
                    style={{ width: "100%" }}
                    mode="multiple"
                    showSearch
                    showArrow
                    onChange={handleSelectQuestions}
                    onClick={onMouseEnter}
                    value={selectedQuestionIds}
                    maxTagCount="responsive"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {text_questions?.map((item, index) => (
                      <Option
                        className="topic-metric-option"
                        value={item.id}
                        key={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.field}>
                  <p className={styles.boxLabel}>
                    Select NPS question associated to the selected text
                    questions (Optional):
                  </p>
                  <Select
                    placeholder=""
                    style={{ width: "100%" }}
                    showSearch
                    showArrow
                    onChange={handleSelectNpsQuestion}
                    onClick={onMouseEnter}
                    value={selectedNpsQuestionId}
                    maxTagCount="responsive"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {nps_questions?.map((item, index) => (
                      <Option
                        className="topic-metric-option"
                        value={item.id}
                        key={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.dateSelector}>
                  <p>Start date (Optional):</p>
                  <DatePicker
                    onChange={(date, dateString) => {
                      setSince(dateString);
                    }}
                  />
                </div>
                <div className={styles.dateSelector}>
                  <p>End date (Optional):</p>
                  <DatePicker
                    onChange={(date, dateString) => {
                      setUntil(dateString);
                    }}
                  />
                </div>
                <div className={styles.Btns}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      close();
                    }}
                  >
                    cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrent(1);
                    }}
                  >
                    next
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.field}>
                  <ul className={styles.summaryList}>
                    <li>
                      Number of text question selected ?{" "}
                      <tag>
                        {selectedQuestionIds.length}{" "}
                        {selectedQuestionIds.length > 1
                          ? "questions"
                          : "question"}
                      </tag>
                    </li>
                    <li>
                      NPS question selected ?{" "}
                      <tag>
                        {selectedNpsQuestionId.length == 0 ? "No" : "Yes"}
                      </tag>
                    </li>
                    {since.length === 0 && until.length === 0 && (
                      <li>
                        Analysis will cover the <tag>Last 30 days data</tag>
                      </li>
                    )}
                    {since.length > 0 && until.length == 0 && (
                      <li>
                        Analysis will cover answers starting from{" "}
                        <tag>{since}</tag>
                      </li>
                    )}
                    {since.length == 0 && until.length > 0 && (
                      <li>
                        Analysis will cover answers until <tag>{until}</tag>
                      </li>
                    )}
                    {since.length > 0 && until.length > 0 && (
                      <li>
                        Analysis will cover answers between <tag>{since}</tag>{" "}
                        and <tag>{until}</tag>
                      </li>
                    )}
                  </ul>
                  <p className={styles.attentionText}>
                    If the analysis period does not cover newest data, dashboard
                    will not be set auto-dynamic.
                  </p>
                </div>

                <div className={styles.Btns}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrent(0);
                    }}
                  >
                    previous
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      startAnalysis(
                        survey_id,
                        survey_name,
                        selectedQuestionIds,
                        selectedNpsQuestionId,
                        close,
                        since,
                        until
                      );
                    }}
                  >
                    Start Analysis
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const SurveyInfoPopUp = ({ close, responseSum, title, dashboards }) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <div className={styles.surveryInfoModalPopup}>
      <div className={styles.dashboardInfoModalContent}>
        <div className={styles.header}>
          <img
            className={styles.message_img}
            src={
              theme === "dark" ? "/assets/info1.svg" : "/assets/info1_black.svg"
            }
          />
          <div className={styles.content}>
            <p className={styles.title}>Survey File Detail</p>
            <p className={styles.description}>{title}</p>
          </div>
          <div
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <img
              className={styles.message_img}
              src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div className={styles.item}>
            <div className={styles.title}>
              <img
                className={styles.more}
                width={20}
                src={
                  theme === "dark"
                    ? "/assets/survey_responses.svg"
                    : "/assets/survey_black.svg"
                }
                alt=""
              />{" "}
              Total Applications
            </div>
            <div className={styles.description}>{responseSum}</div>
          </div>
          {dashboards && dashboards?.length ? (
            <div className={styles.item}>
              <div className={styles.title}>
                <img
                  className={styles.more}
                  width={20}
                  src={
                    theme === "dark"
                      ? "/assets/analyze_dashboards.svg"
                      : "/assets/survey_black.svg"
                  }
                  alt=""
                />{" "}
                Analyzed in Dashboard
              </div>
              <div className={styles.dashItems}>
                {dashboards.map((item) => (
                  <div className={styles.dash}>{item.dashboard_name}</div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const downloadSurvey = async (id, date, name) => {
  // setDownloadUnmatchedReviews(true);
  notification.success({
    message: "Download your data from Reports & Downloads page!",
  });
  const payload = {
    survey_id: id,
    survey_name: name,
    ...(date ? { date: date } : {}),
  };
  const result = await apiRequest("survey/download", "POST", payload, true);
  // setDownloadUnmatchedReviews(false);
};

const SurveryListItem = (props) => {
  const { survey, responseSum } = props;
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [showSurveryDetail, setShowSurveyDetail] = useState(false);
  const [showSurveyQuestions, setShowSurveyQuestions] = useState(false);

  return (
    <div className={styles.surverylistItem}>
      <CarryOutOutlined />
      <p className={styles.title}>
        {survey?.survey_name} ({responseSum})
      </p>
      <div className={styles.surveyContent}>
        {/* <div className={styles.date}>{dayjs(survey?.last_refreshed_date).fromNow()}</div> */}
        <div className={styles.surveyActions}>
          {/* <div className={styles.surveyDetails} onClick={(e) => { e.stopPropagation(); setShowSurveyDetail(true) }}>                    
                <img className={styles.more} src={"/assets/detail.svg"} alt="" />
                </div> */}
        </div>
        {!showSurveyQuestions && (
          <Dropdown
            overlay={() => (
              <MoreOptions
                id={survey.survey_id}
                name={survey?.survey_name}
                setShowSurveyQuestions={setShowSurveyQuestions}
              />
            )}
            placement="left"
          >
            <img
              className={styles.more}
              src={
                theme === "dark"
                  ? "/assets/more-vertical.svg"
                  : "/assets/more-vertical-black.svg"
              }
              alt=""
            />
          </Dropdown>
        )}
      </div>
      {showSurveryDetail ? (
        <SurveyInfoPopUp
          responseSum={responseSum}
          title={survey?.survey_name}
          dashboards={survey?.runOn}
          close={() => {
            setShowSurveyDetail(false);
          }}
        />
      ) : (
        ""
      )}
      {showSurveyQuestions ? (
        <SurveyQuestionsPopUp
          survey_id={survey.survey_id}
          survey_name={survey.survey_name}
          text_questions={survey?.survey_text_questions}
          nps_questions={survey?.survey_nps_questions}
          close={() => {
            setShowSurveyQuestions(false);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
const ItemDetail = ({ treeData, survey }) => {
  return (
    <div className={styles.treeWrapper}>
      <Tree
        titleRender={(props) => (
          <TreeLeaf id={survey.survey_id} title={props.title} {...props} />
        )}
        treeData={treeData.children}
        selectable={false}
      ></Tree>
    </div>
  );
};

const TreeLeaf = (props) => {
  const { title, id, responseSum, date, downloadSurvey } = props;
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [showSurveryDetail, setShowSurveyDetail] = useState(false);

  return (
    <div className={styles.treeLeaf}>
      <div className={styles.title}>
        <CarryOutOutlined />
        {title} ({responseSum})
      </div>
      <div className={styles.actions}>
        <div className={styles.details}>
          {/* <div className={styles.button} onClick={(e) => { e.stopPropagation(); setShowSurveyDetail(true) }}>                    
                <img className={styles.more} src={"/assets/detail.svg"} alt="" />
                </div> */}
        </div>

        <div className={styles.more}>
          <Dropdown
            overlay={() => <MoreOptions id={id} date={date} name={title} />}
            placement="left"
          >
            <img
              className={styles.more}
              src={
                theme === "dark"
                  ? "/assets/more-vertical.svg"
                  : "/assets/more-vertical-black.svg"
              }
              alt=""
            />
          </Dropdown>
        </div>
      </div>
      {showSurveryDetail ? (
        <SurveyInfoPopUp
          title={title}
          responseSum={responseSum}
          close={() => {
            setShowSurveyDetail(false);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const WrappedItem = (props) => {
  const getTreeData = (timeData, key, index, responseSum) => {
    let childrens = [];
    let treeData = {
      title: key,
      key: `${index}-${key}-${Math.random()}`,
      date: key,
    };

    if (index === 0) {
      treeData["type"] = "first";
    } else if (index === 1) {
      treeData["type"] = "year";
      responseSum = 0;
    } else if (index === 2) {
      let date = key.split("-");
      let monthName = dayjs()
        .month(Number(date[1]) - 1)
        .format("MMMM");
      treeData["type"] = "month";
      treeData["title"] = monthName;
      responseSum = 0;
    } else if (index === 2) {
      treeData["type"] = "day";
    }
    if (Object.keys(timeData)?.length) {
      Object.keys(timeData).map((key) => {
        if (typeof timeData[key] === "object") {
          let LeafData = getTreeData(
            timeData[key],
            key,
            index + 1,
            responseSum
          );
          responseSum = responseSum + LeafData.responseSum;
          childrens.push(LeafData);
        } else {
          responseSum = responseSum + timeData[key];
          childrens.push({
            title: key,
            key: `${index}-${key}-${Math.random()}`,
            children: [],
            type: "day",
            date: key,
            value: timeData[key],
            responseSum: timeData[key],
          });
        }
      });
    } else {
    }
    return { ...treeData, children: childrens, responseSum };
  };
  let { survey } = props;
  let tree = {};
  if (survey.time_series && Object.keys(survey.time_series).length) {
    tree = getTreeData(survey.time_series, "First", 0, 0);
  }
  return (
    <div className={styles.collaps}>
      {" "}
      <Collapse>
        <Collapse.Panel
          onclick={(event) => event.stoppropagation()}
          showArrow={true}
          header={SurveryListItem({ ...props, responseSum: tree.responseSum })}
        >
          <ItemDetail survey={survey} treeData={tree} />{" "}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default WrappedItem;

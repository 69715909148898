import React from "react";
import { Button } from "antd";
import CustomInput from "components/General/CustomInput";
import styles from "./style.module.scss";
import i18n from 'i18next';

const Representation = ({
  onInputChange,
  save
}) => (
  <div className={styles.wrapper}>
    <h4 className={styles.containerTitle}>
      {i18n.t("settings.171")}
    </h4>
    <h7 className={styles.subtitle}>{i18n.t("settings.177")}</h7>
    <div className={styles.container}>
      <p className={styles.text}>{i18n.t("settings.178")}</p>
      <div className={styles.row}>
        <CustomInput
          label={i18n.t("settings.179")}
          placeholder={i18n.t("settings.180")}
          onChange={onInputChange}
          style={{ maxWidth: "700px", margin: "0px", backgroundColor: "transparent", minWidth: "275px" }}
          onOk={() => { }}
        />
      </div>
      <div className={`${styles.row} ${styles.marginTop}`}>
        <Button className={styles.btn} onClick={save}>{i18n.t("settings.181")}</Button>
      </div>
    </div>
  </div>
)

export default Representation;
import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducers";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from '@redux-devtools/extension';

const middlewares = [];

if (process.env.REACT_APP_ENV === "partialDevelopment" || process.env.REACT_APP_ENV === "fullDevelopment") {
  const logger = createLogger({

  });
  middlewares.push(logger);
}



const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  )
);
export default store;

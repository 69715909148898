import React from "react";
import { DatePicker } from "antd";
import styles from "./style.module.scss";

const Representation = ({ placeholder, value, onChange, title }) => (
  <div className={styles.row}>
    <p className={styles.label}>{title}</p>
    <DatePicker
      placeholder={placeholder || ""}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default Representation;
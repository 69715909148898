/** @format */

import React from 'react';
import styles from './style.module.scss';

//The component is used to show the system notification cn either be controlled from the env file or from the parent component
export default function SystemNotification({ msg, show }) {
  const [showMsg, setShowMsg] = React.useState(false);
  const [message, setMessage] = React.useState(
    msg ||
      '🚀 We are currently working on an accelerated experience. Some features may not work temporarily.'
  );
  const removeMsg = () => {
    setShowMsg(false);
  };

  React.useEffect(() => {
    //Here we are giving parent the precedent to show or hide the message
    if (show && typeof show === Boolean) setShowMsg(show);
    else if (process.env.REACT_APP_MAINTENANCE_MODE === 'true')
      setShowMsg(true);
    else setShowMsg(false);

    //We are giving priority to the message from the env file
    if (process.env.REACT_APP_MAINTENANCE_MODE_MESSAGE) {
      setMessage(process.env.REACT_APP_MAINTENANCE_MODE_MESSAGE);
    }
  }, [show]);
  if (!showMsg) return null;
  return (
    <div className={styles.systemMsg}>
      <div className={styles.msg}>{(message && message) || ''}</div>
      <span
        className={styles.close_btn}
        onClick={removeMsg}
      >
        <img
          src='/assets/x.svg'
          alt=''
        />
      </span>
    </div>
  );
}

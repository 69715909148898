import React, { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';
import { useTranslation } from 'react-i18next';

const TaskModalForClickup = ({
  showModal,
  onClose,
  user,
  dashboardId,
  getSearchState,
  timeInterval,
  total_num_docs,
  reporter
}) => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [spaces, setSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState();
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState();
  const [statuses, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState();
  const { t } = useTranslation();

  const clearState = () => {
    setSelectedTeam(null);
    setSelectedSpace(null);
    setSelectedList(null);
    setSelectedStatus(null);
    setSelectedMembers(null);
    setTitle(null);
    setDescription(null);
  }

  const sendRequest = useCallback((payload, onSuccess, reqURL, method = 'POST') => {
    apiRequest(
      reqURL,
      method,
      payload,
      true
    ).then(result => {
      if (!result.error) {
        onSuccess(result);
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = () => {
    const searchState = getSearchState();
    const payload = {
      user_id: user.uid,
      name: title,
      description,
      members: selectedMembers.join(","),
      date: date.valueOf().toString(),
      list_id: selectedList,
      status: selectedStatus,
      dashboard_id: dashboardId,
      timeInterval,
      total_num_docs,
      reporter,
      searchState
    }
    const onSuccess = () => {
      notification.success({
        message: t("trello_task.1")
      });
      clearState();
      onClose();
    }
    sendRequest(payload, onSuccess, "dashboards/clickup/create_task")
  }

  useEffect(() => {
    const payload = {
      user_id: user.uid
    }
    if (showModal) {
      const onSuccess = (result) => setTeams(result.teams)
      sendRequest(payload, onSuccess, "dashboards/clickup/teams")
    }
  }, [sendRequest, showModal, user.uid]);

  useEffect(() => {
    if (spaces?.length > 0 && selectedSpace) {
      const payload = {
        space_id: selectedSpace,
        user_id: user.uid
      }
      const onSuccess = (result) => {
        const list = result.folderless;
        const listsInFolders = Object.keys(result.folders).map(key => result.folders[key].lists).flat(1)
        setLists(list.concat(listsInFolders))
      }
      sendRequest(payload, onSuccess, "dashboards/clickup/all_lists");
    }
  }, [selectedSpace, sendRequest, spaces.length, user.uid]);

  useEffect(() => {
    if (lists?.length > 0 && selectedList) {
      const payload = {
        list_id: selectedList,
        user_id: user.uid
      }
      const onSuccess = (result) => setStatus(result.statuses)
      sendRequest(payload, onSuccess, "dashboards/clickup/statuses");
    }
  }, [selectedList, sendRequest, lists.length, user.uid]);

  useEffect(() => {
    if (teams?.length > 0 && selectedTeam) {
      const team = teams.filter(elem => selectedTeam === elem.id)
      setTeamMembers(team[0].members);
      const payload = {
        team_id: selectedTeam,
        user_id: user.uid
      }
      const onSuccess = (result) => setSpaces(result.spaces);
      sendRequest(payload, onSuccess, "dashboards/clickup/spaces")
    }
  }, [selectedTeam, sendRequest, teams, user.uid]);

  return <Representation
    showModal={showModal}
    onClose={() => { clearState(); onClose(); }}
    save={save}
    selectedStatus={selectedStatus}
    setSelectedStatus={setSelectedStatus}
    statuses={statuses}
    setSelectedTeam={setSelectedTeam}
    selectedTeam={selectedTeam}
    teams={teams}
    teamMembers={teamMembers}
    setSelectedSpace={setSelectedSpace}
    selectedSpace={selectedSpace}
    spaces={spaces}
    selectedList={selectedList}
    setSelectedList={setSelectedList}
    lists={lists}
    setSelectedMembers={setSelectedMembers}
    selectedMembers={selectedMembers}
    setDescription={setDescription}
    setTitle={setTitle}
    date={date}
    setDate={setDate}
    showSaveButton={
      date
      && title
      && description
      && selectedStatus
      && selectedTeam
      && selectedList
      && selectedSpace
    }
  />
}

export default TaskModalForClickup;
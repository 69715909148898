import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Spin, notification, Button } from "antd";
import { apiRequest } from "util/services";
import { UNLOGGED } from "util/constants";
import { useHistory } from "react-router-dom";
import AudienceHeader from "components/Audience/AudienceHeader";
import AudienceItem from "components/Audience/AudienceItem";

const Audience = ({ authenticated, user, theme }) => {
  const history = useHistory();
  const [localState, setState] = useState({
    active: true,
    searchTerm: "",
  });
  const [loading, setLoading] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [audience, setAudience] = useState([]);
  const [filteredAudience, setFilteredAudience] = useState([]);

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
    loadAudience();
  }, []);

  useEffect(() => {
    filterAudience(audience);
  }, [localState]);

  const loadAudience = async () => {
    const result = await apiRequest("audiences/all", "POST", {
      user_id: user.uid,
    });
    if (result) {
      setAudience(result);
      filterAudience(result);
    } else {
      notification.error({
        message: "An error occured while loading audience",
      });
    }
    setLoading(false);
  };

  const filterAudience = (audienceData) => {
    const { searchTerm, active } = localState;
    let data = audienceData.filter((item) => {
      if (!item.Name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      if (!active && item.Status === "active") return false;
      if (active && item.Status !== "active") return false;
      return true;
    });
    setFilteredAudience(data);
  };

  const audienceItems = filteredAudience?.map((item) => {
    const { active } = localState;
    return (
      <AudienceItem
        item={item}
        user={user}
        audience={audience}
        setAudience={setAudience}
        filterAudience={filterAudience}
      />
    );
  });

  return (
    <div className={styles.mainWindow}>
      <AudienceHeader
        localState={localState}
        setState={setState}
        user={user}
        audience={audience}
        filterAudience={filterAudience}
        setAudience={setAudience}
        theme={theme}
      />
      <Spin spinning={loading}>
        <div className={styles.content}>
          {audienceItems.length !== 0 && (
            <AudienceItem
              user={user}
              audience={audience}
              setAudience={setAudience}
              filterAudience={filterAudience}
              item={{}}
              header
            />
          )}
          {audienceItems.length === 0 && !loading ? (
            // <span className={styles.noBoards}>No Audience Available</span>
            <div className={styles.wrapper}>
              <div className={styles.message}>
                <div>
                  <img className={styles.message_img} src="/assets/info.svg" />
                </div>
                <div style={{ display: "flex" }}>
                  <p className={styles.message_text}>You haven't created any audience yet</p>
                </div>
              </div>
              <div className={styles.message_sub}>
                <p>
                  You can create user audiences by choosing the reviews on the Topics page. Later on, you can engage with your audiences, by multi-replying on Google Play or email marketing.
                </p>
              </div>
            </div>
          ) : (
            audienceItems
          )}
        </div>
        {/* <div className={styles.footer}>
          <div className={styles.btn}>
            <Button
              disabled={startIndex === 0}
              onClick={() => setStartIndex(startIndex - 1)}
            >
              Back
            </Button>
          </div>

          <span className={styles.paginator}>
            Page {startIndex + 1} {lastIndex ? ` / ${lastIndex + 1}` : ""}
          </span>

          <div className={styles.btn}>
            <Button
              disabled={startIndex === lastIndex}
              onClick={() => setStartIndex(startIndex + 1)}
            >
              Next
            </Button>
          </div>
        </div> */}
      </Spin>
    </div>
  );
};

export default Audience;

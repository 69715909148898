import React from "react";
import { Button } from 'antd';
import {
  CaretDownOutlined
} from '@ant-design/icons';
import styles from "./style.module.scss";
import i18n from 'i18next';

const Representation = ({
  activateSlack
}) => (
  <div className={styles.scroll}>
    <div className={styles.container}>
      <h4 className={styles.title}>{i18n.t("settings.84")} </h4>
      <div className={styles.content}>
        <div className={styles.column}>
          <div>
            <h7 className={styles.subtitle}>
              <CaretDownOutlined />
              {i18n.t("settings.73")}
            </h7>
            <p className={styles.paragraph}>
              {i18n.t("settings.74")}
            </p>
          </div>
          <div>
            <h7 className={styles.subtitle}>
              <CaretDownOutlined />
              {i18n.t("settings.75")}
            </h7>
            <p className={styles.paragraph}>
              {i18n.t("settings.76")}
            </p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.wrapper}>
            <p className={styles.text}>
              {i18n.t("settings.77")}
            </p>
            <img className={styles.image} src="/assets/settings/pivony-logo.png" alt=" pivony" />
            <p className={styles.text}>
              {i18n.t("settings.78")}
            </p>
            <Button
              className={styles.btn}
              onClick={activateSlack}
            >
              <img width="20" height="20" src="/assets/settings/slack-color.png" alt="" />
              <span className={styles.buttonText}>{i18n.t("settings.79")}<strong> Slack</strong></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.centeredText}>
      {i18n.t("settings.80")}
    </div>
    <div className={styles.content}>
      <div className={styles.column}>
        <img className={styles.asideImage} src="/assets/settings/slack-integrated.png" alt=" pivony" />
      </div>
      <div className={styles.column}>
        <div className={styles.asideContainer}>
          <h4 className={styles.title}>{i18n.t("settings.81")}</h4>
          <p className={styles.asideText}>
            {i18n.t("settings.82")}
          </p>
        </div>
      </div>
    </div>
    <div className={styles.linksContainer}>
      <a className={styles.link} href="mailto:support@pivony.com">{i18n.t("top_menu.3")}</a>
      <a className={styles.link} href="https://www.pivony.com/knowledge-center">{i18n.t("top_menu.2")}</a>
      <a className={styles.link} href="https://pivony.com/privacy">{i18n.t("others.38")}</a>
      <a className={styles.link} href="https://pivony.com/terms">{i18n.t("others.39")}</a>
      <a className={styles.link} href="https://pivony.com/cookie-policy">{i18n.t("others.6")}</a>
    </div>
  </div>
)

export default Representation;
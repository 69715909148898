import React, { useState } from 'react';
import styles from './style.module.scss';
import { copyToClipboard } from 'util/common';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const InfoPopup = ({ data, theme, close }) => {
   const { t } = useTranslation();
   const handleCopyToClipboard = (str) => {
      if (str) {
         copyToClipboard(str);
         notification.success({
            message: t("notifications.54")
         });
      }
   }

   return (
      <div className={styles.infoModal}>
         <div className={styles.centerWrapper}>
            <div className={styles.popupModalContent}>
               <div className={styles.header}>
                  <div className={styles.headerLabel}>
                     <img src={theme === 'dark' ? "/assets/info1.svg" : "/assets/info1_black.svg"} alt="" />
                     {t("alerts_page.21")}
                  </div>
                  <div className={styles.closeIcon}>
                     <img src={theme === 'dark' ? "/assets/x.svg" : "/assets/x_black.svg"} onClick={() => close(false)} alt="" />
                  </div>
               </div>
               <div className={styles.belowContent}>
                  <div className={styles.firstBlock}>
                     <span className={styles.label}>
                        <img src={theme === 'dark' ? "/assets/solid_line.svg" : "/assets/solid_line_black.svg"} className={styles.solidIcon} alt="" />
                        {t("alerts_page.22")}
                     </span>
                     {data.name ? (
                        <span className={styles.kpiNameBox}>
                           {data.name}
                           <img onClick={() => handleCopyToClipboard(data.name)} src={theme === 'dark' ? "/assets/copy.svg" : "/assets/copy_black.svg"} alt="" />
                        </span>
                     ) : <span className={styles.notAvailableField}>N/A</span>}
                  </div>
                  <div className={styles.secondBlock}>
                     <span className={styles.label}>
                        <img src={theme === 'dark' ? "/assets/dashboards.svg" : "/assets/dashboards_black.svg"} alt="" />
                        {t("alerts_page.23")}
                     </span>
                     <div className={styles.itemsWrapper}>
                        {data?.source.length > 0 ? data.source.map((item, i) => (
                           <span className={styles.underlineCopy} key={i + 1}>
                              {item}
                              <img src={theme === 'dark' ? "/assets/copy.svg" : "/assets/copy_black.svg"} alt="" onClick={() => handleCopyToClipboard(item)} />
                           </span>
                        )) : <span className={styles.notAvailableField}>N/A</span>}
                     </div>
                  </div>
                  <div className={styles.secondBlock}>
                     <span className={styles.label}>
                        <img src={theme === 'dark' ? "/assets/library.svg" : "/assets/library_black.svg"} alt="" />
                        {t("alerts_page.24")}
                     </span>
                     <div className={styles.itemsWrapper}>
                        {data?.metric.length > 0 && Array.isArray(data?.metric) ? data.metric.map((item, i) => (
                           <span className={styles.underlineCopy} key={i + 1}>
                              {item}
                              <img src={theme === 'dark' ? "/assets/copy.svg" : "/assets/copy_black.svg"} alt="" onClick={() => handleCopyToClipboard(item)} />
                           </span>
                        )) : data?.metric ?
                           <span className={styles.underlineCopy}>
                              {data?.metric}
                              <img src={theme === 'dark' ? "/assets/copy.svg" : "/assets/copy_black.svg"} alt="" onClick={() => handleCopyToClipboard(data?.metric)} />
                           </span>
                           :
                           <span className={styles.notAvailableField}>N/A</span>}
                     </div>
                  </div>

                  <div className={styles.firstBlock}>
                     <span className={styles.label}>
                        <span className={styles.operatorIcon}>∑∕μ</span>
                        {t("alerts_page.25")}
                     </span>
                     <span>
                        {data?.operation}
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default InfoPopup;
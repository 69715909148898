import React from "react";
import { Input } from "antd";
import styles from "./style.module.scss";

const Representation = ({ title, onChange }) => (
  <div className={styles.row}>
    <p className={styles.label}>{title}</p>
    <Input.TextArea
      className={styles.textArea}
      onChange={onChange}
    />
  </div>
);

export default Representation;
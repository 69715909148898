import React, { useState } from 'react'
import styles from "./styles.module.scss"
import { useSelector } from 'react-redux'
import { Select, Button } from 'antd';
import ListItem from "./followListItem"

const Listing = (props) => {
    const theme = useSelector((state) => state.DashboardReducer.theme);
    const { list, fetchList } = props

    return <div className={styles.listing}>
        {
            list.length == 0 ? <div className={styles.emptyState}>No Followed topics found</div> : <div className={styles.listingHeader}>
                <p className={styles.item}>
                    Topic name
                </p>
                <p className={styles.item}>
                    Dashboard
                </p>
            </div>
        }


        <div className={styles.mainListing}>
            {
                list.length ?
                    list.map((info) => {
                        return <ListItem fetchList={fetchList} item={info} />

                    }) : ""
            }
        </div>

    </div>
}

export default Listing
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import {
  notification,
  Spin,
  Menu,
  Dropdown,
  Button,
  Input,
  Tooltip,
  Checkbox,
  Select,
} from "antd";
import {
  EyeOutlined,
  ReloadOutlined,
  SwapOutlined,
  ShareAltOutlined,
  TagOutlined,
  PushpinOutlined,
  EditOutlined,
  DeleteOutlined,
  FireOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { copyToClipboard, getDateString } from "util/common";
import { apiRequest } from "util/services";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import mixpanel from "mixpanel-browser";
import { Modal } from "components/General/ModalFormComponents";
import { keyPressed } from "util/common";
import GroupsMangaer from "../../Groups/index";
import { PlatformStatus } from "helperComponents/Status";
import { useTranslation, Trans } from "react-i18next";
import Icon from "helperComponents/Icon";
import { Link } from "react-router-dom";

const DashboardItem = ({
  item,
  selectedDashboards,
  pinnedCount,
  addOrRemoveSelected,
  hideActions,
  header,
  Dashboards,
  setDashboards,
  getAllDashboards,
  shareList,
  shareClick,
  IsExpert,
}) => {
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [showDashbroadInfoPopup, setShowDashboardInfoPopup] = useState(false);
  const [showEditDashboard, setShowEditDashboard] = useState(false);
  const [renameDashboardName, setRenameDashboardName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [showMoveDashboardPopup, setShowMoveDashboardPopup] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [productLinks, setProductLinks] = useState([]);
  const [selectedItems, setSelectedItems] = useState();
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [savingGroup, setSavingGroup] = useState(false);
  const [rightClick, setRightClick] = useState(false);
  const [selected, SetSelected] = useState(
    selectedDashboards?.length && selectedDashboards.includes(item.ID)
      ? true
      : false
  );
  const [rightClickPostion, setRightClickPosition] = useState({
    x: 0,
    y: 0,
  });
  const [disableSave, setDisableSave] = useState(false);
  const [showCustomMenu, setShowCustomMenu] = useState(false);
  const [tempProductLinks, setTempProductLinks] = useState([]);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupColor, setGroupColor] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const customMenuRef = useRef();
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems(selectedItems.filter((e) => e !== value));
    }
  };
  useEffect(() => {
    SetSelected(
      selectedDashboards?.length && selectedDashboards.includes(item.ID)
        ? true
        : false
    );
  }, [selectedDashboards]);
  const handleShare = () => {
    teamsShare(selectedItems);
  };
  const updateDashboardItem = (key, value) => {
    let dashboard = null;
    let dashhIndex = null;
    Dashboards.forEach((val, index) => {
      if (val.ID === item.ID) {
        dashboard = val;
        dashhIndex = index;
      }
    });
    if (dashboard !== null) {
      dashboard = {
        ...dashboard,
        [key]: value,
      };
    }
    // update the dashboards
    let newDashboards = [...Dashboards];
    newDashboards[dashhIndex] = {
      ...dashboard,
    };
    setDashboards(newDashboards);
  };
  const teamsShare = async (items) => {
    setShareLoading(true);
    const payload = {
      team_ids_viewers: items.join(","),
      dashboard_id: item.ID,
    };
    const result = await apiRequest(
      "dashboards/viewability",
      "POST",
      payload,
      true
    );
    if (result.success) {
      // setauthViewers(checkboxSelection);
      notification.success({
        message: t("notifications.50"),
        description: "",
      });
      updateDashboardItem("viewers", selectedItems);
      setSelectedItems([]);
      setOrganizations([]);
      setShowSharePopup(false);
    } else {
      notification.error({
        message: result.error,
        description: "",
      });
    }
    setShareLoading(false);

    // setTeamvisible(false)
  };

  const EmailClickOut = (e) => {
    if (customMenuRef.current && !customMenuRef.current.contains(e.target)) {
      setShowCustomMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", EmailClickOut, true);
    return () => document.removeEventListener("click", EmailClickOut, true);
  }, []);

  const CustomContextMenu = () => {
    return (
      <div ref={customMenuRef} className={styles.customContext}>
        <div className={styles.menu}>
          <div
            className={styles.menuItem}
            onClick={() => {
              getdashboardInfo();
              setShowCustomMenu(false);
            }}
          >
            {" "}
            <Icon name={"new_info"} /> Info
          </div>
          <div
            className={styles.menuItem}
            onClick={() => {
              window.open(
                window.origin + `/console/DashboardData/${item.ID}`,
                "_blank"
              );
              setShowCustomMenu(false);
            }}
          >
            <Icon name={"open_in_new"} /> Open in new tab
          </div>
        </div>
      </div>
    );
  };

  const setSharedItems = (items) => {
    let newItems = [];
    if (items.length) {
      items.forEach((item) => {
        if (item.status == 1) {
          newItems.push(item.team_id);
        }
      });
    }
    setSelectedItems(newItems);
  };

  const id = item.ID;
  let title = item.Keywords?.slice(0, 3).join(" - ");
  if (item.Filename) title = item.Filename;
  if (item.Name) title = item.Name;
  const dispatch = useDispatch();
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);

  const fetchTeams = async () => {
    setTeamsLoading(true);
    const payload = {
      dashboard_id: item.ID,
    };
    const result = await apiRequest(
      "dashboards/viewers",
      "POST",
      payload,
      true
    );
    if (result.success) {
      if (result.success.length > 0) {
        setSharedItems(result.success);
        setOrganizations(result.success);
      } else {
        notification.error({
          message: t("notifications.49"),
        });
      }
    }
    setTeamsLoading(false);
  };
  const shareDashboard = async () => {
    if (item.shareTeams === "public") {
      sharePublic();
    } else {
      setShowSharePopup(true);
      await fetchTeams();
    }
  };
  const getdashboardInfo = async () => {
    const result = await apiRequest(
      "dashboards/info",
      "POST",
      {
        dashboard_id: parseInt(item.ID, 10),
      },
      true
    );
    if (result && result.success) {
      setDashboardInfo(result.success);
      setShowDashboardInfoPopup(true);
      if (result.success?.platform_attributes[0]?.title === "Product Links") {
        let arr = [];
        for (let i = 0; i < result.success?.platform_attributes?.length; i++) {
          if (
            result.success?.platform_attributes[i].title === "Product Links"
          ) {
            arr = result.success?.platform_attributes[i].value.split("OR");
          }
        }
        if (arr?.length > 0) {
          setProductLinks(arr);
          setTempProductLinks(arr?.filter((link, index) => index < 3));
        }
      }
    } else {
      notification.error({
        message: t("notifications.3"),
      });
    }
  };

  const handleRightClick = (e) => {
    if (e.ctrlKey || e.button == 2) {
      setShowCustomMenu(true);
      setRightClickPosition({
        x: e.x,
        y: e.y,
      });
    }
  };

  const handleDashboardSelection = () => {
    dispatch({
      type: actions.RESET_SEARCH_STATE,
      payload: {},
    });
    dispatch({
      type: actions.SET_Dashboard,
      payload: {
        dashboard: item,
      },
    });
    mixpanel.track("VIEW_Dashboard", { DASHBOARD_ID: item.ID });
    if (item.ID) {
      if (item.Status === "In Progress" && !item.IsDynamic) {
        notification.info({
          message: t("notifications.4"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
        history.push(`/console/journey/${item.ID}`);
      } else {
        history.push(`/console/DashboardData/${item.ID}`);
      }
    }
  };
  const handleInsightSelection = () => {
    dispatch({
      type: actions.SET_Dashboard,
      payload: {
        dashboard: item,
      },
    });
    if (item.ID) {
      if (item.Status === "In Progress") {
        notification.info({
          message: t("notifications.4"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
        history.push(`/console/journey/${item.ID}`);
      } else {
        history.push(`/console/insights/${item.ID}`);
      }
    }
  };

  const getPlatformName = (id) => {
    if (id === 1) return "Twitter";
    else if (id === 2) return "Google Play";
    else if (id === 3) return "Customer File";
    else if (id === 4) return "App Store";
    else if (id === 5) return "Şikayet Var";
    else if (id === 6) return "Instagram";
    else if (id === 7) return "Zendesk Tickets";
    else if (id === 8) return "Zendesk Livechat";
    else if (id === 9) return "Facebook Page";
    else if (id === 10) return "Facebook and Instagram Ad";
    else if (id === 11) return "Mobile App Reviews";
    else if (id === 12) return "Social media";
    else if (id === 13) return "E-commerce websites";
    else if (id === 16) return "Facebook Page DM";
    else if (id === 18) return "Reddit";
    else if (id === 19) return "Eksisozluk";
    else if (id === 28) return "Internal Surveys";
    else if (id === 29) return "TikTok";
    else if (id === 31) return "Papara Topluluk";
    else if (id === 32) return "LinkedIn";
    else if (id === 33) return "ACS";
    else if (id === 34) return "Otelpuan";
    else if (id === 35) return "Anket";
    return "Platform";
  };

  const getPlatformStatus = (status) => {
    if (status === "Error")
      return {
        icon: "fa fa-info",
        color: theme === "dark" ? "red" : "#E60000",
        text: status,
      };
    if (status === "Ready")
      return {
        icon: "fa fa-check-circle",
        color: theme === "dark" ? "lawngreen" : "#009900",
        text: status,
      };
    if (status === "In Progress" || status?.toLowerCase().includes("progress"))
      return {
        icon: "fa fa-hourglass",
        color: theme === "dark" ? "yellow" : "#FFA500",
        text: status,
      };
    return { text: status };
  };
  const status = getPlatformStatus(item.Status);
  const handleDynamic = async (changeToDynamic) => {
    const result = await apiRequest(
      "dashboards/dynamic",
      "POST",
      {
        dashboard_id: parseInt(item.ID, 10),
        refresh_rate: changeToDynamic ? "daily" : "cancel",
      },
      true
    );
    if (!result.error) {
      notification.success({
        message: t("notifications.53"),
      });
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          dashboard: {
            ...DashboardReducer.dashboard,
            RefreshRate: changeToDynamic ? "daily" : null,
          },
        },
      });
      getAllDashboards();
      // set toggle status
    } else {
      notification.error({
        message: result.error,
      });
    }
  };
  const sharePublic = async () => {
    const payload = {
      dashboard_id: parseInt(item.ID, 10),
    };
    const result = await apiRequest(
      "dashboards/share/serialize",
      "POST",
      payload,
      true
    );
    if (!result.error) {
      notification.success({
        message: t("notifications.51"),
        duration: 0,
      });
      copyToClipboard(
        `${document.location.origin}/share/Dashboard/${result.hashed}`
      );
      setShowSharePopup(false);
    } else {
      notification.error({
        message: result.error,
      });
    }
  };
  const addDashboardToHottermsReport = async (status) => {
    const res = await apiRequest(
      "dashboards/hotterm_follow_status",
      "POST",
      {
        status: status,
        dashboard_id: item.ID,
      },
      true
    );
    if (res?.success) {
      notification.success({
        message: res?.success,
      });
      getAllDashboards();
    } else {
      notification.error({
        message: res?.error,
      });
    }
  };

  const pinUnpinDashboard = async (status) => {
    const res = await apiRequest(
      "dashboards/pin/update",
      "POST",
      {
        status: status,
        dashboard_id: item.ID,
      },
      true
    );
    if (res?.success) {
      if (status === "pin") {
        notification.success({
          message: "Pinned Dashboard Successfully",
        });
      } else {
        notification.success({
          message: "Unpinned Dashboard Successfully",
        });
      }
      getAllDashboards();
    } else if (res?.success.length === 0) {
      notification.error({
        message: t("notifications.5"),
      });
    } else {
      notification.error({
        message: res.error,
      });
    }
  };

  const getUsers = async () => {
    const res = await apiRequest("users/all_accounts", "POST", true);
    if (res?.success?.length > 0) {
      setUsersList(res?.success);
      setShowMoveDashboardPopup(true);
      document.body.style.overflowY = "hidden";
    } else if (res?.success.length === 0) {
      notification.error({
        message: t("notifications.5"),
      });
    } else {
      notification.error({
        message: res.error,
      });
    }
  };

  const menu = (
    <Menu className={styles.customMenu} theme="dark">
      {IsExpert && (
        <Menu.Item key={5} onClick={getUsers} icon={<SwapOutlined />}>
          {t("others.16")}
        </Menu.Item>
      )}
      <Menu.Item
        key={3}
        onClick={() => getdashboardInfo()}
        icon={<InfoCircleOutlined />}
      >
        {t("all_dashboards_page.9")}
      </Menu.Item>
      <Menu.Item
        key={1}
        onClick={handleDashboardSelection}
        icon={<EyeOutlined />}
      >
        {t("all_dashboards_page.10")}
      </Menu.Item>
      <Menu.Item
        key={6}
        onClick={() => handleDynamic(!item.IsDynamic)}
        disabled={item.PlatformId == 3 ? true : false}
        icon={<ReloadOutlined />}
      >
        {item.IsDynamic
          ? t("all_dashboards_page.20")
          : t("all_dashboards_page.21")}
      </Menu.Item>
      <Menu.Item
        key={7}
        onClick={() => shareDashboard()}
        icon={<ShareAltOutlined />}
      >
        {t("all_dashboards_page.22")}
      </Menu.Item>
      {item.group && item.group.group_id ? (
        <Menu.Item
          key={8}
          onClick={() => setShowGroups(true)}
          icon={<TagOutlined />}
        >
          {t("all_dashboards_page.23")}
        </Menu.Item>
      ) : (
        <Menu.Item
          key={8}
          onClick={() => setShowGroups(true)}
          icon={<TagOutlined />}
        >
          {t("all_dashboards_page.24")}
        </Menu.Item>
      )}
      {pinnedCount > 4 && item.pined === false ? (
        ""
      ) : item.pined ? (
        <Menu.Item
          key={9}
          onClick={() => pinUnpinDashboard("unpin")}
          icon={<PushpinOutlined />}
        >
          {t("all_dashboards_page.25")}
        </Menu.Item>
      ) : (
        <Menu.Item
          key={9}
          onClick={() => pinUnpinDashboard("pin")}
          icon={<PushpinOutlined />}
        >
          {t("all_dashboards_page.26")}
        </Menu.Item>
      )}
      {/* {item.hottermFollowed ? (
        <Menu.Item
          key={11}
          onClick={() => addDashboardToHottermsReport("0")}
          icon={<FireOutlined />}
        >
          {t("all_dashboards_page.27")}
        </Menu.Item>
      ) : (
        <Menu.Item
          key={10}
          onClick={() => addDashboardToHottermsReport("1")}
          icon={<FireOutlined />}
        >
          {t("all_dashboards_page.28")}
        </Menu.Item>
      )} */}

      <Menu.Item
        key={4}
        onClick={() => setShowEditDashboard(true)}
        icon={<EditOutlined />}
      >
        {t("all_dashboards_page.11")}
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => setDeleteModalOpen(true)}
        icon={<DeleteOutlined />}
      >
        {t("all_dashboards_page.12")}
      </Menu.Item>
    </Menu>
  );

  const handleDeleteDashboard = async () => {
    setLoading(true);
    const result = await apiRequest(
      "dashboards/v2/delete",
      "POST",
      {
        dashboard_id: item.ID,
      },
      true
    );
    if (result.error || result.cancel) {
      notification.error({
        message: t("others.1"),
      });
    } else {
      if (DashboardReducer.dashboard?.ID === item.ID) {
        dispatch({
          type: actions.SET_Dashboard,
          payload: {
            dashboard: {},
          },
        });
      }
      notification.success({ message: t("notifications.21") });
      let newBoards = [];
      for (let i = 0; i < Dashboards.length; i++) {
        if (Dashboards[i].ID !== item.ID) newBoards.push(Dashboards[i]);
      }
      setDashboards(newBoards);
    }
    setLoading(false);
    setDeleteModalOpen(false);
  };

  const handleGroupDashboard = async () => {
    setSavingGroup(true);
    if (!selectedGroup && searchQuery === "") {
      if (item?.group?.group_id) {
        const payload = {
          dashboard_ids: [item.ID],
        };
        const result = await apiRequest(
          "dashboards/ungrouping",
          "POST",
          payload
        );
        if (!result) {
          notification.error({
            message: t("others.1"),
          });
        } else {
          getAllDashboards();
          setSelectedGroup(null);
          setShowGroups(false);
          notification.success({
            message: "Removed Group from Dashboard Successfuly",
          });
        }
        setSavingGroup(false);
        return;
      } else {
        notification.warning({
          message: "Group name can't be empty",
        });
        setSavingGroup(false);
        return;
      }
    }
    const payload = {
      dashboard_ids: [item.ID],
      group_name: selectedGroup?.group_name
        ? selectedGroup.group_name
        : searchQuery,
      group_color: selectedGroup?.group_color
        ? selectedGroup?.group_color
        : groupColor,
    };
    if (selectedGroup && selectedGroup.group_id) {
      payload.group_id = selectedGroup.group_id;
    }
    if (!selectedGroup && isEdit) {
      payload.group_id = item?.group?.group_id;
    }
    const result = await apiRequest("dashboards/grouping", "POST", payload);
    if (!result || result.error || result.validation_error) {
      notification.error({
        message: "Grouping failed.",
      });
    } else {
      getAllDashboards();
      setSelectedGroup(null);
      setIsEdit(null);
      setSearchQuery("");
      setShowGroups(false);
      notification.success({
        message: "Added Group to Dashboard Successfuly",
      });
    }
    setSelectedGroup(null);
    setSavingGroup(false);
  };

  const handleEditName = async () => {
    const payload = {
      dashboard_id: id,
      name: renameDashboardName,
    };
    const result = await apiRequest("dashboards/update", "POST", payload);
    if (!result) {
      notification.error({
        message: t("others.1"),
      });
    } else {
      notification.success({
        message: t("notifications.6"),
      });
      getAllDashboards();
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          dashboard: {
            ...DashboardReducer.dashboard,
            Name: renameDashboardName,
          },
        },
      });
    }
    setShowEditDashboard(false);
  };

  const handleCopyLink = (link) => {
    copyToClipboard(link);
    notification.success({
      message: t("notifications.54"),
    });
  };

  const handleClose = () => {
    document.body.style.overflowY = "visible";
    setShowMoveDashboardPopup(false);
  };

  const handleMoveDashboard = async () => {
    if (selectedUser) {
      const payload = {
        dashboard_id: parseInt(item.ID, 10),
        email: selectedUser,
      };
      const res = await apiRequest(
        "dashboards/move_dashboard",
        "POST",
        payload,
        true
      );
      if (res.success) {
        notification.success({
          message: t("notifications.7"),
        });
        setShowMoveDashboardPopup(false);
        document.body.style.overflowY = "visible";
        getAllDashboards();
      } else {
        notification.error({
          message: res.error,
        });
      }
    } else {
      notification.error({
        message: t("notifications.8"),
      });
    }
  };

  const handleShowAllLinks = () => {
    setTempProductLinks(productLinks);
  };

  const downloadDashboardFile = async () => {
    const payload = {
      dashboard_id: parseInt(item.ID, 10),
    };
    const res = await apiRequest(
      "dashboards/file_download",
      "POST",
      payload,
      true
    );

    if (res.success) {
      notification.success({
        message: (
          <span>
            {t("notifications.47")}.
            <br />
            Go to{" "}
            <a
              onClick={() => {
                history.push({
                  pathname: `/console/report`,
                });
              }}
            >
              downloads page
            </a>
          </span>
        ),
      });
    } else {
      notification.error({
        message: res?.error ? res?.error : "Download failed",
      });
    }
  };
  return (
    <div className={`${styles.container} ${header && styles.header}`}>
      {showSharePopup && (
        <div
          className={styles.sharePopupModal}
          onClick={() => setShowSharePopup(false)}
        >
          <div className={styles.modalContent}>
            <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
              <p className={styles.title}>Share with teams</p>
              <Spin spinning={teamsLoading}>
                <div className={styles.itemsWrapper}>
                  {shareClick
                    ? organizations?.map((item, index) => (
                        <div className={styles.item} key={index}>
                          <input
                            type="checkbox"
                            defaultChecked={item.status === 1 ? true : false}
                            className="share-team-name"
                            id={item.team_id}
                            value={item.team_id}
                            onChange={handleChange}
                          />
                          <label htmlFor={item.team_id}>{item.team_name}</label>
                        </div>
                      ))
                    : organizations?.map((item, index) => (
                        <div className={styles.item} key={index}>
                          <input
                            type="checkbox"
                            className="share-team-name"
                            id={item.team_id}
                            value={item.team_id}
                            onChange={handleChange}
                            defaultChecked={item.status === 1 ? true : false}
                          />
                          <label htmlFor={item.team_id}>{item.team_name}</label>
                        </div>
                      ))}
                </div>
              </Spin>
              <div className={styles.actionBtn}>
                <button type="button" onClick={() => setShowSharePopup(false)}>
                  {t("button.3")}
                </button>
                <button
                  disabled={shareLoading ? true : false}
                  type="button"
                  onClick={() => handleShare(selectedItems)}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.firstColumn}>
        {header ? (
          ""
        ) : item.pined ? (
          <div className={styles.pin}>
            {" "}
            <Icon name="pin" />
          </div>
        ) : (
          ""
        )}
        {header ? (
          ""
        ) : (
          <Checkbox
            className="checkbox"
            checked={selected}
            onChange={(e) => {
              e.stopPropagation();
              addOrRemoveSelected(item.ID);
            }}
          ></Checkbox>
        )}
        {item.IsDynamic && (
          <span className={styles.normalContent}>
            {item.DashboardScore}
            <i className="fa fa-calendar-days" />
          </span>
        )}
        {header ? (
          <span
            className={styles.title}
            title={title}
            onClick={handleDashboardSelection}
          >
            {t("all_dashboards_page.2")}
          </span>
        ) : (
          <Link to={`/console/DashboardData/${item.ID}`}>
            <span
              className={styles.title}
              title={title}
              onClick={handleDashboardSelection}
            >
              {title}
            </span>{" "}
          </Link>
        )}
        {item.Language && <span className={styles.label}>{item.Language}</span>}
      </div>
      <div className={`${styles.column} ${styles.insightColumn}`}>
        <span className={styles.title} onClick={handleInsightSelection}>
          {header ? `# ${t("all_dashboards_page.3")}` : item.insight_count}
        </span>
      </div>
      <div className={`${styles.column} ${styles.platformColumn}`}>
        <span className={styles.platformLabel}>
          {getPlatformName(item.PlatformId)}
        </span>
      </div>
      <div className={`${styles.column} ${styles.statusBox}`}>
        {header ? (
          t("all_dashboards_page.5")
        ) : (
          <PlatformStatus status={item.Status} dashId={item.ID} />
        )}
      </div>
      <div className={`${styles.column} ${styles.creationColumn}`}>
        <span className={styles.normalContent}>
          {header
            ? t("all_dashboards_page.6")
            : item.lastUpdated
            ? item.lastUpdated
            : item.CreationDate}
        </span>
      </div>
      <div
        className={`${styles.column} ${
          header || !item.group ? styles.groupHeader : styles.group
        }`}
      >
        {header ? (
          <span>{t("all_dashboards_page.18")}</span>
        ) : item.group && item.group.group_id ? (
          <Tooltip title={item.group.group_name}>
            {" "}
            <div className={styles.group}>
              {" "}
              <span className={styles.label}>{item.group.group_name}</span>
            </div>{" "}
          </Tooltip>
        ) : (
          ""
        )}
      </div>
      {hideActions ? (
        ""
      ) : (
        <div className={`${styles.column} ${styles.actions}`}>
          {header ? (
            <span>{t("all_dashboards_page.7")}</span>
          ) : (
            <Dropdown overlay={menu} placement="left">
              <img
                src={
                  theme === "dark"
                    ? "/assets/more-vertical.svg"
                    : "/assets/more-vertical-black.svg"
                }
                alt=""
              />
            </Dropdown>
          )}
        </div>
      )}

      {showDashbroadInfoPopup && (
        <div className={styles.dashboardInfoPopupModal}>
          <div className={styles.dashboardInfoModalContent}>
            <div className={styles.titleBox}>
              <span>
                {theme === "dark" ? (
                  <img src="/assets/info1.svg" alt="" />
                ) : (
                  <img src="/assets/info1_black.svg" alt="" />
                )}
                <span className={styles.infoTitleWithDownload}>
                  <span>{t("dashboard_info.1")}</span>
                  {item.PlatformId === 3 ? (
                    <span
                      className={styles.downloadFileBtn}
                      onClick={downloadDashboardFile}
                    >
                      <Tooltip title="Download Dashboard File">
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/download.svg"
                              : "/assets/download_black.svg"
                          }
                          alt=""
                          width={26}
                          height={26}
                        />
                      </Tooltip>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </span>
              <span>
                {theme === "dark" ? (
                  <img
                    src="/assets/x.svg"
                    alt=""
                    onClick={() => setShowDashboardInfoPopup(false)}
                  />
                ) : (
                  <img
                    src="/assets/x_black.svg"
                    alt=""
                    onClick={() => setShowDashboardInfoPopup(false)}
                  />
                )}
              </span>
            </div>

            <div className={styles.belowContent}>
              <div className={styles.wrapperTop}>
                <div className={styles.wrapperItem}>
                  <span>
                    <img
                      width="22"
                      height="22"
                      className={styles.icon}
                      src={
                        theme === "dark"
                          ? "/assets/single_user.svg"
                          : "/assets/single_user_black.svg"
                      }
                      alt=""
                    />
                    {t("dashboard_info.2")}
                  </span>
                  <span>{dashboardInfo.owner_email}</span>
                </div>

                <div className={styles.wrapperItem}>
                  <span>{t("dashboard_info.3")}</span>
                  <span>{item.ID}</span>
                </div>
              </div>
              <div
                className={
                  dashboardInfo.date_range.length > 0
                    ? styles.wrapperMid
                    : styles.wrapperTop
                }
              >
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/d_name.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/d_name_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.4")}
                  </span>
                  <span>{dashboardInfo.Name}</span>
                </div>

                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/database.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/database_black.svg"
                        alt=""
                      />
                    )}
                    {t("selected_dashboard_page.7")}
                  </span>
                  <span>{dashboardInfo?.collected_data}</span>
                </div>

                {dashboardInfo.date_range.length > 0 && (
                  <div className={styles.wrapperItem}>
                    <span>
                      {theme === "dark" ? (
                        <img
                          className={styles.icon}
                          src="/assets/calendar.svg"
                          alt=""
                        />
                      ) : (
                        <img
                          className={styles.icon}
                          src="/assets/calendar_black.svg"
                          alt=""
                        />
                      )}
                      {t("dashboard_info.5")}
                    </span>
                    <span>{dashboardInfo.date_range}</span>
                  </div>
                )}
              </div>

              <div className={styles.wrapper}>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/database.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/database_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.6")}
                  </span>
                  <span>{dashboardInfo.Platform}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/category.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/category_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.7")}
                  </span>
                  <span>{dashboardInfo.category}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/outline_world.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/outline_world_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.8")}
                  </span>
                  <span>{dashboardInfo.lang}</span>
                </div>
                <div className={styles.wrapperItem}>
                  <span>
                    {theme === "dark" ? (
                      <img
                        className={styles.icon}
                        src="/assets/group.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        className={styles.icon}
                        src="/assets/group_black.svg"
                        alt=""
                      />
                    )}
                    {t("dashboard_info.9")}
                  </span>
                  <span>{dashboardInfo.num_topics}</span>
                </div>
                {dashboardInfo.nps_scale && (
                  <div className={styles.wrapperItem}>
                    <span>
                      {theme === "dark" ? (
                        <img
                          className={styles.icon}
                          src="/assets/nps_scale.svg"
                          alt=""
                          width={24}
                          height={24}
                        />
                      ) : (
                        <img
                          className={styles.icon}
                          src="/assets/nps_scale_black.svg"
                          alt=""
                          width={24}
                          height={24}
                        />
                      )}
                      {"NPS Scale"}
                    </span>
                    <span>{dashboardInfo.nps_scale}</span>
                  </div>
                )}
              </div>

              {dashboardInfo?.platforms?.length > 0 && (
                <div className={styles.platformTags}>
                  <span>{t("dashboard_info.10")}</span>
                  {dashboardInfo?.platforms?.map((item) => {
                    return <span>{item}</span>;
                  })}
                </div>
              )}
              {dashboardInfo?.pivot_cols && (
                <div className={styles.platformTags}>
                  <span>{t("dashboard_info.11")}</span>
                  {dashboardInfo?.pivot_cols.split(",")?.map((item) => {
                    return <span>{item}</span>;
                  })}
                </div>
              )}
              {productLinks?.length > 0 && (
                <p className={styles.productLinkLabel}>{t("others.54")}</p>
              )}
              {dashboardInfo?.platform_attributes?.length > 0 && (
                <div className={styles.platformTagsDetailWrapper}>
                  {productLinks?.length > 0
                    ? tempProductLinks?.map((item) => {
                        return (
                          <div className={styles.tagDetails}>
                            <span>
                              {item.length > 20
                                ? item.substring(0, 20) + "..."
                                : item}
                              {item ? (
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/copy.svg"
                                      : "/assets/copy_black.svg"
                                  }
                                  onClick={() => handleCopyLink(item)}
                                  alt=""
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </div>
                        );
                      })
                    : dashboardInfo?.platform_attributes?.map((item) => {
                        return (
                          <div className={styles.tagDetails}>
                            {item.title && (
                              <>
                                <span>{item.title}</span>
                                <span>
                                  {item?.value?.length > 20
                                    ? item?.value.substring(0, 20) + "..."
                                    : item?.value}
                                  {item?.value ? (
                                    <img
                                      src={
                                        theme === "dark"
                                          ? "/assets/copy.svg"
                                          : "/assets/copy_black.svg"
                                      }
                                      onClick={() => handleCopyLink(item.value)}
                                      alt=""
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                        );
                      })}
                </div>
              )}
              {productLinks?.length > 2 &&
                productLinks?.length !== tempProductLinks?.length && (
                  <p
                    className={styles.showMoreLabel}
                    onClick={handleShowAllLinks}
                  >
                    {t("others.51")}
                  </p>
                )}
            </div>
          </div>
        </div>
      )}
      <Modal
        showModal={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSave={handleDeleteDashboard}
        showSaveButton={true}
        textonButton={t("button.33")}
        titleAsset=""
        title={t("all_dashboards_page.16")}
        widthofmodal="450px"
        showCancelButton={true}
        loadingSaveClick={loading}
        disabled={loading ? true : false}
        isDeleteModal={true}
      >
        <p className={styles.deletetext}>
          <Trans
            i18nKey="all_dashboards_page.13"
            t={t}
            values={{ title: title }}
            tOptions={{ interpolation: { escapeValue: false } }}
          />
        </p>
      </Modal>
      <Modal
        showModal={showEditDashboard}
        onClose={() => setShowEditDashboard(false)}
        onSave={handleEditName}
        showSaveButton={true}
        textonButton={t("all_dashboards_page.15")}
        titleAsset=""
        title={t("all_dashboards_page.14")}
        widthofmodal="450px"
      >
        <Input
          placeholder={t("placeholder.3")}
          onKeyPress={(e) => keyPressed(e, handleEditName)}
          style={{
            height: "35px",
            marginBottom: "7px",
            width: "350px",
            marginLeft: "15px",
            background: "transparent",
            color: theme === "dark" ? "white" : "black",
            borderColor: "#6f6c99",
            borderRadius: "10px",
          }}
          onChange={(e) => setRenameDashboardName(e.target.value)}
        />
      </Modal>
      <Modal
        showModal={showGroups}
        onClose={() => {
          setSelectedGroup(null);
          setSearchQuery(null);
          setShowGroups(false);
          setIsEdit(false);
        }}
        onSave={handleGroupDashboard}
        showSaveButton={true}
        textonButton={t("button.30")}
        disabled={savingGroup ? true : false}
        loadingSaveClick={savingGroup}
        showCancelButton={true}
        hideCloseIcon={true}
        titleAsset=""
        title={
          <div>
            {" "}
            <Icon name={"tag_group"} />
            {t("all_dashboards_page.30")}
          </div>
        }
        widthofmodal="450px"
      >
        <GroupsMangaer
          activeGroup={item.group}
          setGroup={setSelectedGroup}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          groupColor={groupColor}
          setGroupColor={setGroupColor}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      </Modal>
      {showMoveDashboardPopup && (
        <div className={styles.moveDashboardModal}>
          <div className={styles.centerWrapper}>
            <div className={styles.modalContent}>
              <div className={styles.title}>
                <span>{t("others.16")}</span>
                <span>
                  {t("others.40")} - {item.Name}
                </span>
              </div>
              <div className={styles.userListDropdown}>
                <p>Move to</p>
                <Select
                  onChange={(value) => setSelectedUser(value)}
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder={t("others.41")}
                >
                  {usersList?.map((user, index) => (
                    <Select.Option value={user} key={index}>
                      {user}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className={styles.buttons}>
                <button onClick={handleClose}>{t("button.3")}</button>
                <button onClick={handleMoveDashboard}>
                  {t("settings.200")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardItem;

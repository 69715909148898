import * as actions from "redux/Dashboard/actions.js";

// Define the initial state object
let initialSearchState = {
  chosenKeywords: [],
  chosenSentiment: "",
  chosenDate: "",
  // types = topic, keyword, date, dateKeyword, dateSentiment
  chosenFetchType: "topic",
  sortByDate: false,
  chosenIntent: "",
  chosenHotTerm: "",
  chosenDocRating: "",
  showNewReviewsLabel: false,
  chosenRestaurantName: "",
  chosenPlatformName: "",
  chosenTopTopic: [],
  chosenCustomTopic: [],
  chosenNPS: "",
  dateRange: [],
  selectedDaysRange:"last7d",
};
let initialState = {
  loading: true,
  searchState: initialSearchState,
  boardData: {},
  theme: 'dark',
};

const DashboardReducer = (state = initialState, action) => {
  if (action.type === actions.SET_Dashboard) {
    return {
      ...state,
      selected_topic: state?.selected_topic,
      dashboard: state?.dashboard,
      ...action.payload,
      boardData: {},
      searchState: {
        ...initialSearchState,
        ...state.searchState,
        ...action.payload.searchState,
        sortByDate: state.searchState.sortByDate,
      },
    };
  } else if (action.type === actions.RESET_SEARCH_STATE) {
    return {
      ...state,
      searchState: initialSearchState,
      showNewReviewsLabel: false,
    };
  } else if (action.type === actions.UPDATE_SEARCH_STATE) {
    return {
      ...state,
      searchState: { ...state.searchState, ...action.payload },
    };
  } else if (action.type === actions.UPDATE_Dashboard) {
    return {
      ...(({ failureMsg, ...o }) => o)(state),
      ...action.payload
    };
  } else if (action.type === actions.SELECT_TOPIC) {
    return {
      ...state,
      ...action.payload,
    };
  } else if (action.type === actions.THEME_MODE) {
    return {
      ...state,
      ...action.payload
    }
  }
  return state;
};

export { DashboardReducer };

import React, { useEffect, useState } from "react";
import styles from "./customSummaryItem.module.scss";
import { useSelector } from "react-redux";
import LineChartWrapper from "../Dashboard/LineChartWrapper/index";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import CustomChart from "components/Charts";

const CustomSummaryItem = ({
  item,
  toggleListCard,
  topicsType,
  DashboardId,
  close,
  uniqueAllDates,
  fillZeros,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const [dependency, setDependency] = useState(1);
  useEffect(() => {
    setDependency(dependency === 1 ? 2 : 1);
  }, [item]);
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "transparent",
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        ticks: {
          maxTicksLimit: 3,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        top: 5,
      },
    },
  };

  if (toggleListCard === "list") {
    return (
      <div className={styles.summaryItemWrapper}>
        <div className={styles.header}>
          <div className={styles.title}>ACTIVE TOPIC</div>
          <div
            className={styles.button}
            onClick={() => {
              close();
            }}
          >
            {" "}
            <img
              src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
              className="moreOptionSVG"
              alt=""
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.topicName}>{item.topic_name}</div>
          <div className={styles.trend}>
            <CustomChart
              type={"line"}
              data={{
                labels: uniqueAllDates,
                datasets: [
                  {
                    data: fillZeros(item.trend),
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.1)",
                    lineTension: 0.4,
                    borderWidth: 4,
                    borderColor: "rgba(33, 172, 158,0.8)",
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
              theme={theme}
              dependency={dependency}
            />
            {/* <Line
              data={{
                labels: uniqueAllDates,
                datasets: [
                  {
                    data: fillZeros(item.trend),
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.1)",
                    lineTension: 0.4,
                    borderWidth: 4,
                    borderColor: "rgba(33, 172, 158,0.8)",
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
            /> */}
          </div>
          <div className={styles.monthlyAverage}>{item.monthly_avg}</div>
          {item?.last_month?.value !== "" ? (
            <div className={`${styles.lastMonth}`}>
              {item.last_month.value}
              <span
                style={{
                  color: item?.last_month?.color
                    ? item?.last_month?.color
                    : "white",
                  paddingLeft: "5px",
                }}
              >
                {item?.last_month?.sign == "+" ? (
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.3125 5.2998H10.3125V12.8765C10.3125 13.1103 10.123 13.2998 9.88915 13.2998H5.73585C5.50204 13.2998 5.3125 13.1103 5.3125 12.8765V5.2998Z"
                      fill={item.last_month.color}
                    />
                    <path
                      d="M7.50446 0.258428C7.66588 0.11185 7.91224 0.111849 8.07366 0.258428L13.0399 4.76798C13.3264 5.02819 13.1423 5.50475 12.7553 5.50475H2.82285C2.43578 5.50475 2.2517 5.02819 2.53826 4.76798L7.50446 0.258428Z"
                      fill={item.last_month.color}
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill={item.last_month.color}
                      d="M9 4h6v8h4.84L12 19.84L4.16 12H9V4Z"
                    />
                  </svg>
                )}

                {item.last_month.change}
              </span>
            </div>
          ) : (
            <div className={styles.lastMonth}></div>
          )}
          <div className={styles.actions}>
            <span className="moreOptionIcon" onClick={() => {}}>
              {theme === "dark" ? (
                <img
                  src="/assets/more-vertical.svg"
                  className="moreOptionSVG"
                  alt=""
                />
              ) : (
                <img
                  src="/assets/more-vertical-black.svg"
                  className="moreOptionSVG"
                  alt=""
                />
              )}
              {/* {
                        selectedItem === item.topic_id &&
                        (
                            <div className="moreOptionBox">
                                <span>{t("button.2")}</span>
                            </div>
    
                        )
    
                    } */}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.summaryItemWrapper}>
        <div className={styles.header}>
          <div className={styles.title}>ACTIVE TOPIC</div>
          <div
            className={styles.button}
            onClick={() => {
              close();
            }}
          >
            <CloseOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          </div>
        </div>
        <div className={`${styles.itemMainWindow}`} key={item.TopicId}>
          <div className={styles.left}>
            <div className={styles.status}>
              {topicsType === "custom" &&
                (item.status === 3 ||
                  item.Posts === 0 ||
                  item.status === 0) && (
                  <img
                    src={`/assets/status${
                      item.status === 0 || item.status === 4
                        ? "0"
                        : item.Posts === 0
                        ? "3"
                        : item.status
                    }.png`}
                  />
                )}
              <h4>{item.Label}</h4>
            </div>
            {topicsType === "ai" && (
              <div className={styles.topwords}>
                {item.TopWords?.map((topWord) => (
                  <span>{topWord}</span>
                ))}
              </div>
            )}
          </div>
          {item.VolumeDaily.length > 0 ? (
            <div className={styles.rightChart}>
              <LineChartWrapper
                // TopicId={item.TopicId}
                DashboardId={DashboardId}
                topicsType={topicsType}
                VolumeDaily={item.VolumeDaily}
                hideLegend
              />
            </div>
          ) : item.Posts === 0 && item.status === 1 ? (
            <div
              style={{
                color: "red",
              }}
            >
              {t("notifications.81")}
            </div>
          ) : item.status === 0 ? (
            <div
              style={{
                color: "#F2A70C",
              }}
            >
              {t("selected_dashboard_page.53")}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
};

export default CustomSummaryItem;

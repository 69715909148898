import React, { useState, useEffect } from "react";
import {
  Button,
  Collapse,
  Modal,
  Select,
  Menu,
  Dropdown,
  notification,
  Table,
} from "antd";
import { Modal as CustomModal } from "components/General/ModalFormComponents";
import {
  CheckCircleOutlined,
  HourglassOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { apiRequest } from "util/services";
import i18n from "i18next";
import Highlighter from "helperComponents/Highlight";
import { t } from "i18next";

const CustomDropDown = ({ onChange, options, placeholder, selectedOption }) => (
  <Select
    className={styles.dropdown}
    placeholder={placeholder}
    onChange={onChange}
    value={selectedOption}
    showSearch
    optionFilterProp="title"
  >
    <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
    {options?.map((option) => (
      <Select.Option key={option} value={option} title={option}>
        {option}
      </Select.Option>
    ))}
  </Select>
);

const Status = ({ status }) => {
  switch (status) {
    case "pending":
      return (
        <>
          <HourglassOutlined style={{ fontSize: "18px" }} />
          <span>{t("settings.215")}</span>
        </>
      );
    default:
      return (
        <>
          <CheckCircleOutlined style={{ fontSize: "18px" }} />
          <span>{t("settings.216")}</span>
        </>
      );
  }
};

const CustomMenu = ({ onClick }) => (
  <Menu className={styles.menu} onClick={onClick}>
    <Menu.Item key="changeTeam">{i18n.t("settings.185")}</Menu.Item>
    <Menu.Item key="removeUser">{i18n.t("settings.186")}</Menu.Item>
  </Menu>
);

const Representation = ({
  teams,
  getAccessLevel,
  permissions,
  deleteMember,
  deleteModalUser,
  setDeleteModalUser,
  updatePermission,
  history,
  isMobile,
  fetchTeams,
  loading,
}) => {
  const [showChangeTeam, setShowChangeTeam] = useState(false);
  const [teamChangeId, setTeamChangeId] = useState("");
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [tempTeams, setTempTeams] = useState([]);
  const [showHighlight, setShowHighlight] = useState(false);
  const [teamDashboards, setTeamDashboards] = useState(null);
  const [teamForInfo, setTeamForInfo] = useState(null);
  const theme = useSelector((state) => state.DashboardReducer.theme);

  useEffect(() => {
    setTempTeams(teams);
  }, [teams]);

  const ModalStyle = {
    padding: "30px 45px",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };

  const onHeaderMenuClick = (val, fullName, email) => {
    if (val === "changeTeam") {
      setShowChangeTeam(true);
      setSelectedEmailId(email);
    } else if (val === "removeUser") {
      setDeleteModalUser({ fullName, email });
    }
  };

  const handleMoveTeam = async () => {
    const payload = {
      email: selectedEmailId,
      new_team_id: teamChangeId,
    };
    const response = await apiRequest(
      "users/organization/move_user",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: i18n.t("notifications.132"),
      });
      setShowChangeTeam(false);
      fetchTeams();
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  const handleShowSearch = () => {
    if (window.innerWidth < 750) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const filterTeam = () => {
    let data = teams?.filter((team) => {
      if (Object.keys(team)[0] !== "organization_id") {
        let data = Object.values(team)[0]?.filter(
          (team) =>
            (team.email &&
              team.email.toLowerCase().includes(searchValue.toLowerCase())) ||
            (team.fullName &&
              team.fullName.toLowerCase().includes(searchValue.toLowerCase()))
        );
        return data?.length === 0 ? false : true;
      } else {
        let data = Object.values(team)[1]?.filter(
          (team) =>
            (team.email &&
              team.email.toLowerCase().includes(searchValue.toLowerCase())) ||
            (team.fullName &&
              team.fullName.toLowerCase().includes(searchValue.toLowerCase()))
        );
        return data?.length === 0 ? false : true;
      }
    });
    setTempTeams(data);
    setShowHighlight(true);
    // let collapse = document.querySelector('.ant-collapse');
    // collapse.setAttribute('defaultActiveKey', [data?.map((i, index) => index.toString())])
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchValue.trim()) {
        filterTeam();
      } else {
        setTempTeams(teams);
        setShowHighlight(false);
      }
    }
  };

  const handleMobileSearch = () => {
    if (searchValue.trim()) {
      filterTeam();
    } else {
      setTempTeams(teams);
      setShowHighlight(false);
    }
  };

  const getTeamDashboardsInfo = async (team_id) => {
    const payload = {
      team_id: team_id,
    };
    const result = await apiRequest(
      "users/team/dashboards/info",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      if (typeof result.success === "object") {
        setTeamDashboards(result.success);
      } else if (typeof result.success === "string") {
        notification.info({
          message: result.success,
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      }
    } else {
      notification.error({
        message: result.error
          ? result.error
          : "Unable to get team dashboards info",
      });
    }
  };

  const cols = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      className: `${styles.idColumn}`,
    },
    {
      title: "Dashboard Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      className: `${styles.nameColumn}`,
    },
  ];
  const tableData = Object.keys(teamDashboards || {})?.map((key) => ({
    key: key,
    id: key,
    name: teamDashboards[key],
  }));
  const RowItem = ({
    isMobile,
    fullName,
    email,
    status,
    accessLevel,
    getAccessLevel,
    permissions,
    setDeleteModalUser,
    updatePermission,
    organizationId,
    theme,
  }) => (
    <div className={`${styles.row} ${styles.withBorder}`}>
      {isMobile ? (
        <>
          <div className={styles.mobileRow}>
            <div
              style={{ width: "50%" }}
              className={`${styles.fullName} ${styles.textAlignLeft}`}
            >
              {i18n.t("settings.187")}
            </div>
            <div className={styles.fullName}>
              {fullName && (
                <Highlighter
                  highlight={showHighlight && searchValue ? searchValue : ""}
                >
                  {fullName}
                </Highlighter>
              )}
            </div>
          </div>
          <div className={styles.mobileRow}>
            <div
              style={{ width: "50%" }}
              className={`${styles.email} ${styles.textAlignLeft}`}
            >
              {i18n.t("settings.188")}
            </div>
            <div className={styles.email}>
              {email && (
                <Highlighter
                  highlight={showHighlight && searchValue ? searchValue : ""}
                >
                  {email}
                </Highlighter>
              )}
            </div>
          </div>
          <div className={styles.mobileRow}>
            <div
              style={{ width: "50%" }}
              className={`${styles.status} ${styles.textAlignLeft}`}
            >
              {i18n.t("all_dashboards_page.5")}
            </div>
            <div className={styles.status}>
              <Status status={status} />
            </div>
          </div>
          <div className={styles.mobileRow}>
            <div
              style={{ width: "50%" }}
              className={`${styles.access} ${styles.textAlignLeft}`}
            >
              {i18n.t("settings.189")}
            </div>
            <div className={styles.access}>
              <CustomDropDown
                onChange={(value) =>
                  updatePermission(value, organizationId, email)
                }
                selectedOption={getAccessLevel(accessLevel)}
                options={permissions}
                placeholder={i18n.t("settings.190")}
              />
            </div>
          </div>
          {getAccessLevel(accessLevel) != "Admin" && (
            <div className={styles.mobileRow}>
              <div
                style={{ width: "50%" }}
                className={`${styles.delete} ${styles.textAlignLeft}`}
              >
                {" "}
                {i18n.t("settings.191")}
              </div>
              <div className={styles.delete}>
                <Dropdown
                  overlay={() => (
                    <CustomMenu
                      onClick={(e) => onHeaderMenuClick(e.key, fullName, email)}
                    />
                  )}
                  trigger={["click"]}
                >
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/more-vertical.svg"
                        : "/assets/more-vertical-black.svg"
                    }
                    alt=""
                  />
                </Dropdown>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.fullName}>
            {fullName && (
              <Highlighter
                highlight={showHighlight && searchValue ? searchValue : ""}
              >
                {fullName}
              </Highlighter>
            )}
          </div>
          <div className={styles.email}>
            {email && (
              <Highlighter
                highlight={showHighlight && searchValue ? searchValue : ""}
              >
                {email}
              </Highlighter>
            )}
          </div>
          <div className={styles.status}>
            <Status status={status} />
          </div>
          <div className={styles.access}>
            <CustomDropDown
              onChange={(value) =>
                updatePermission(value, organizationId, email)
              }
              selectedOption={getAccessLevel(accessLevel)}
              options={permissions}
              placeholder={i18n.t("settings.192")}
            />
          </div>
          <div className={styles.delete}>
            <Dropdown
              overlay={() => (
                <CustomMenu
                  onClick={(e) => onHeaderMenuClick(e.key, fullName, email)}
                />
              )}
              trigger={["click"]}
            >
              <img
                src={
                  theme === "dark"
                    ? "/assets/more-vertical.svg"
                    : "/assets/more-vertical-black.svg"
                }
                alt=""
              />
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4 className={styles.containerTitle}>
          {i18n.t("settings.193")}
          <p className={styles.subtitle}>{i18n.t("settings.194")}</p>
        </h4>
        {showRowItems && (
          <div className={styles.actionBlock}>
            <button className={styles.search} onClick={handleShowSearch}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/search.svg"
                    : "/assets/search_black.svg"
                }
                alt=""
              />
            </button>
            <Button
              className={`${styles.modalBtn} ${styles.padding}`}
              onClick={() => history.push("/settings/team/invite")}
            >
              {i18n.t("settings.195")}
            </Button>
            {window.innerWidth > 750 && (
              <div
                className={`${styles.searchBox} ${showSearchBox &&
                  styles.showSearch} `}
              >
                <img
                  src={
                    theme === "dark"
                      ? "/assets/search.svg"
                      : "/assets/search_black.svg"
                  }
                  alt=""
                />
                <input
                  type="text"
                  placeholder={i18n.t("placeholder.18")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyPress={handleSearch}
                />
              </div>
            )}
          </div>
        )}
        {!showRowItems && (
          <div
            className={`${styles.searchBoxSmall} ${showSearchBox &&
              styles.showSearch} `}
          >
            <i
              onClick={() => {
                setShowRowItems(true);
                setSearchValue("");
                setTempTeams(teams);
              }}
              className="fa-solid fa-arrow-left"
            ></i>
            <input
              type="text"
              placeholder={i18n.t("placeholder.18")}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={handleSearch}
            />
            <button onClick={handleMobileSearch}>{i18n.t("button.5")}</button>
          </div>
        )}
      </div>

      <Collapse
        className={styles.collapse}
        bordered={false}
        defaultActiveKey={[]}
        accordion
      >
        {tempTeams?.map((elem, index) => {
          let header;
          let organizationId;
          let values;
          if (Object.keys(elem)[0] !== "organization_id") {
            header = Object.keys(elem)[0];
            values = Object.values(elem)[0];
            organizationId = Object.values(elem)[1];
          } else {
            header = Object.keys(elem)[1];
            values = Object.values(elem)[1];
            organizationId = Object.values(elem)[0];
          }
          return (
            <Collapse.Panel
              header={
                <div className={styles.headerWrapper}>
                  <span className={styles.headerTitle}>{header}</span>
                  <span
                    className={styles.dashboardAccess}
                    title={i18n.t("settings.221")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setTeamForInfo(header);
                      getTeamDashboardsInfo(organizationId);
                    }}
                  >
                    <InfoCircleOutlined />
                  </span>
                </div>
              }
              key={index}
            >
              {!isMobile && (
                <div className={`${styles.row} ${styles.headerRow}`}>
                  <div className={styles.fullName}>
                    {i18n.t("settings.187")}
                  </div>
                  <div className={styles.email}>{i18n.t("settings.188")}</div>
                  <div className={styles.status}>
                    {i18n.t("all_dashboards_page.5")}
                  </div>
                  <div className={styles.access}>{i18n.t("settings.189")}</div>
                  <div className={styles.delete}></div>
                </div>
              )}

              {values.map((obj, index2) => (
                <RowItem
                  key={`${index}${index2}`}
                  getAccessLevel={getAccessLevel}
                  permissions={permissions}
                  setDeleteModalUser={setDeleteModalUser}
                  updatePermission={updatePermission}
                  organizationId={organizationId}
                  isMobile={isMobile}
                  {...obj}
                  theme={theme}
                />
              ))}
            </Collapse.Panel>
          );
        })}
        {tempTeams.length === 0 && !loading && (
          <p className={styles.notFound}>No Users Found</p>
        )}
      </Collapse>

      <Modal
        open={deleteModalUser}
        bodyStyle={ModalStyle}
        footer={null}
        onCancel={() => setDeleteModalUser(null)}
        style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
      >
        <h7 className={styles.modalTitle}> {i18n.t("settings.196")}</h7>
        <p className={styles.modalContent}>
          {i18n.t("settings.197", { label: deleteModalUser?.fullName })}
        </p>
        <div className={styles.footer}>
          <span
            className={`${styles.footerBtn} ${styles.cancelButton}`}
            onClick={() => setDeleteModalUser(null)}
          >
            {i18n.t("button.3")}
          </span>
          <span
            className={`${styles.footerBtn} ${styles.saveButton} ${styles.red} red`}
            onClick={() => deleteMember(deleteModalUser.email)}
          >
            {i18n.t("button.2")}
          </span>
        </div>
      </Modal>
      {showChangeTeam && (
        <div className={styles.changeTeamModal}>
          <div className={styles.centerWrapper}>
            <div className={styles.modalContent}>
              <div className={styles.title}>
                <span>{i18n.t("settings.185")}</span>
                <img
                  src={
                    theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"
                  }
                  alt=""
                  onClick={() => setShowChangeTeam(false)}
                />
              </div>

              <div className={styles.teamDropdown}>
                <span className={styles.label}>{i18n.t("settings.198")}</span>
                <Select
                  placeholder={i18n.t("settings.199")}
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => setTeamChangeId(value)}
                >
                  {teams?.map((elem, index) => {
                    let header;
                    let organizationId;

                    if (Object.keys(elem)[0] !== "organization_id") {
                      header = Object.keys(elem)[0];
                      organizationId = Object.values(elem)[1];
                    } else {
                      header = Object.keys(elem)[1];
                      organizationId = Object.values(elem)[0];
                    }
                    return (
                      <Select.Option key={header} value={organizationId}>
                        {header}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <button className={styles.moveBtn} onClick={handleMoveTeam}>
                {i18n.t("settings.200")}
              </button>
            </div>
          </div>
        </div>
      )}
      {teamDashboards && Object.keys(teamDashboards).length > 0 && (
        <CustomModal
          title={
            <span>
              The team <b>{teamForInfo}</b> have access to Dashboards
            </span>
          }
          titleAsset={""}
          description={`Total dashboards: ${
            Object.keys(teamDashboards).length
          }`}
          showModal={Object.keys(teamDashboards).length > 0}
          widthofmodal={window.innerWidth > 500 ? "500px" : "350px"}
          onClose={() => {
            setTeamDashboards(null);
            setTeamForInfo(null);
          }}
        >
          <div className={styles.dashboardsWrapper}>
            <Table
              columns={cols}
              dataSource={tableData}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "30"],
              }}
            />
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default Representation;

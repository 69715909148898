import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styles from "./style.module.scss";
import Tour from "reactour";
import { useLocation, withRouter } from "react-router";
import { useSelector } from "react-redux";
import { themeColor } from 'util/common';
import i18n from 'i18next';

const layoutExplorationSteps = [
    {
        selector: "#myDashboards",
        content: () => (
            <div>{i18n.t("user_guide.1")}</div>
        ),
    },
    {
        selector: "#source",
        content: () => (
            <div>{i18n.t("user_guide.2")}</div>
        ),
    },
    {
        selector: "#audience",
        content: () => (
            <div>{i18n.t("user_guide.3")}</div>
        ),
    },
    {
        selector: "#emailMarketing",
        content: () => <div>{i18n.t("user_guide.4")}</div>,
    },
    {
        selector: "#alldataplatforms",
        content: () => (
            <div>
                {i18n.t("user_guide.5")}
            </div>
        ),
    },
    {
        selector: "#popup_selector",
        content: () => (
            <div>
                {i18n.t("user_guide.6")}
            </div>
        ),
    },
    {
        selector: "#help",
        content: () => (
            <div>
                {i18n.t("user_guide.7")}
            </div>
        ),
    },
    {
        selector: "#myaccount",
        content: () => (
            <div>
                {i18n.t("user_guide.8")}
            </div>
        ),
    },
];

const DashboardCreationTour = withRouter(
    ({ isTour2Open, setIsTour2Open, location: { pathname } }) => {
        const newDashboardReducer = useSelector(
            (state) => state.newDashboardReducer
        );
        const { platform, creationType, currentStep } = newDashboardReducer;

        const [step, setStep] = useState(0);

        // useEffect(() => {
        //     if (currentStep === -1) {
        //         setIsTour2Open(true);
        //     }
        // }, [currentStep, step]);

        let DashboardCreationSteps = [];

        if (currentStep === -1) {
            DashboardCreationSteps = [
                {
                    selector: "",
                    content: () => (
                        <div>
                            <p>{i18n.t("user_guide.9")}</p>
                            <p>
                                {i18n.t("user_guide.10")}
                            </p>
                            <p>
                                {i18n.t("user_guide.11")}<i className="fa fa-cog" />{" "}
                            </p>
                        </div>
                    ),
                },
            ];
        }

        if (pathname === "/console/myDashboards") {
            if (currentStep !== -1) {
                DashboardCreationSteps = [
                    {
                        selector: "#createDashboard",
                        content: () => (
                            <div>
                                {i18n.t("user_guide.12")}
                            </div>
                        ),
                    },
                ];
            }
        } else if (pathname === "/console/source") {
            if (currentStep) {
                if (platform === 2 && creationType === "simple") {
                    if (currentStep === 1) {
                        DashboardCreationSteps = [
                            {
                                selector: "#custom_radio_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.13")}
                                    </div>
                                ),
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.14")}.
                                    </div>
                                ),
                                action: () => setStep(0)
                            },
                        ];
                    } else if (currentStep === 2) {
                        DashboardCreationSteps = [
                            {
                                selector: "#custom_text_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.15")}
                                    </div>
                                ),
                                action: (node) => {
                                    node.focus();
                                },
                            },
                            {
                                selector: "#custom_numeric_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.16")}
                                    </div>
                                ),
                                action: (node) => {
                                    node.focus();
                                },
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.17")}
                                    </div>
                                ),
                                action: () => setStep(0)
                            },
                        ];
                    }
                } else if (platform === 3 && creationType === "simple") {
                    if (currentStep === 1) {
                        DashboardCreationSteps = [
                            {
                                selector: "#custom_radio_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.13")}
                                    </div>
                                ),
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.18")}
                                    </div>
                                ),
                                action: () => setStep(0)
                            },
                        ];
                    } else if (currentStep === 2) {
                        DashboardCreationSteps = [
                            {
                                selector: "#customer_file_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.19")}
                                    </div>
                                )
                            },
                            {
                                selector: "#custom_numeric_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.16")}
                                    </div>
                                ),
                                action: (node) => {
                                    node.focus();
                                },
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.18")}
                                    </div>
                                ),
                                action: () => setStep(0)
                            },
                        ];
                    }
                    else if (currentStep === 3) {
                        DashboardCreationSteps = [
                            {
                                selector: "#custom_text_input",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.20")}
                                    </div>
                                ),
                                action: (node) => {
                                    node.focus();
                                },
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.21")}
                                    </div>
                                )
                            },
                            {
                                selector: "#datasource_section",
                                content: () => (
                                    <div>
                                        {i18n.t("user_guide.22")}
                                    </div>
                                ),
                                action: () => setStep(0)
                            },
                        ];
                    }
                } else {
                    DashboardCreationSteps = [
                        {
                            selector: "",
                            content: () => <div>{i18n.t("user_guide.23")}</div>,
                        },
                    ];
                }
            } else {
                DashboardCreationSteps = [
                    {
                        selector: "#selectplatforms",
                        content: () => (
                            <div>
                                {i18n.t("user_guide.24")}
                            </div>
                        ),
                    },
                    {
                        selector: "#datasourceselection",
                        content: () => (
                            <div>
                                {i18n.t("user_guide.25")}
                            </div>
                        ),
                        action: () => setStep(0)
                    },
                ];
            }
        }
        // else if (pathname === "/console/journey") {
        //     DashboardCreationSteps = [
        //         {
        //             selector: "#journey_steps",
        //             content: () => (
        //                 <div>
        //                     You have successfully finished creating your first
        //                     Dashboard. Congratulations! You can track
        //                     Dashboard creation progress here. We will notify
        //                     you with an email when it is ready.
        //                 </div>
        //             ),
        //         },
        //     ];
        // }

        return (
            <Tour
                steps={DashboardCreationSteps}
                isOpen={isTour2Open}
                onRequestClose={() => {
                    setIsTour2Open(false);
                }}
                onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                onBeforeClose={() => {
                    document.body.style.overflowY = "visible"
                }
                }
                accentColor="#4400AA"
                startAt={0}
                getCurrentStep={curr => console.log(`The current step is ${curr + 1}`)}
                update={pathname + currentStep}
                updateDelay={300}
                goToStep={step}
                showButtons={currentStep !== -1}
                showNavigation={currentStep !== -1}
                scrollOffset={1}
                disableFocusLock={true}
            />
        );
    }
);

const DashboardGuide = ({ theme }) => {
    const pathname = useLocation().pathname;

    const [isTour1Open, setIsTour1Open] = useState(false);
    const [isTour2Open, setIsTour2Open] = useState(false);

    const [isTour1Finished, setIsTour1Finished] = useState(false);
    const [isTour2Finished, setIsTour2Finished] = useState(false);

    const [hidden, setHidden] = useState(true);

    const newDashboardReducer = useSelector(
        (state) => state.newDashboardReducer
    );
    const { currentStep } = newDashboardReducer;

    useEffect(() => {
        try {
            let el = document.querySelector("#guide_header");
            let el2 = document.querySelector("#guide_shared");

            var styles = window.getComputedStyle(el);
            var margin =
                parseFloat(styles["marginTop"]) +
                parseFloat(styles["marginBottom"]);

            let header = Math.ceil(el.offsetHeight + margin) - 10;
            el2.style.bottom = `-${el2.offsetHeight - header}px`;
            hideWindow();
        }
        catch (er) {
            console.log(er);
        }
    }, [pathname]);

    useEffect(() => {
        if (!isTour2Finished && currentStep === -1) {
            setIsTour2Finished(true);
        }
    }, [currentStep])

    const hideWindow = () => {
        let el = document.querySelector("#guide_shared");
        if (!hidden) {
            el.style.transform = `translateY(0)`;
            setHidden(!hidden);
        }
    };

    const showWindow = () => {
        let el = document.querySelector("#guide_shared");
        if (hidden) {
            el.style.transform = `translateY(-${el.offsetHeight}px)`;
            setHidden(!hidden);
        }
    };

    if (
        !(
            pathname === "/console/myDashboards" ||
            pathname === "/console/source"
            // || pathname === "/console/journey"
        )
    ) {
        return <></>;
    }

    return (
        <>
            <Tour
                steps={layoutExplorationSteps}
                isOpen={isTour1Open}
                onRequestClose={() => {
                    setIsTour1Open(false);
                }}
                onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                onBeforeClose={() =>
                    (document.body.style.overflowY = "visible")
                }
                getCurrentStep={curr => curr + 1 === 7 && setIsTour1Finished(true)}
                accentColor="#4400AA"
                startAt={0}
                scrollOffset={1}
            />

            <DashboardCreationTour
                isTour2Open={isTour2Open}
                setIsTour2Open={setIsTour2Open}
            />
            {
                hidden &&
                <div className={styles.userGuideIcon}>
                    <img src="/assets/user_guide_icon.svg" alt="" onClick={() => {
                        showWindow();
                        hideWindow();
                    }} />
                </div>
            }

            <div id="guide_shared" className={styles.guide} style={{ backgroundColor: themeColor(theme) }}>
                {!hidden && (
                    <span
                        id="guide_close"
                        className={styles.guide_close}
                        onClick={hideWindow}
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                )}
                <h4
                    id="guide_header"
                    className={styles.guide_header}

                >
                    {i18n.t("user_guide.29")}
                </h4>
                <div className={styles.guide_wrapper}>
                    <ol className={styles.guide_list}>
                        {pathname.startsWith("/console") && (
                            <>
                                <li
                                    className={
                                        (styles.guide_list_item,
                                            styles.layoutstep)
                                    }
                                >
                                    <div
                                        style={{
                                            textDecoration:
                                                isTour1Finished && "line-through",
                                        }}
                                    >
                                        {i18n.t("user_guide.26")}
                                    </div>
                                    <Button
                                        type="primary"
                                        className="primary-background"
                                        onClick={() => {
                                            setIsTour1Open(true);
                                            hideWindow();
                                        }}
                                        disabled={isTour1Finished}
                                    >
                                        {i18n.t("user_guide.27")}
                                    </Button>
                                </li>
                                <li className={styles.guide_list_item}>
                                    <div
                                        style={{
                                            textDecoration:
                                                isTour2Finished && "line-through",
                                        }}
                                    >
                                        {i18n.t("user_guide.28")}
                                    </div>
                                    <Button
                                        type="primary"
                                        className="primary-background"
                                        onClick={() => {
                                            // scrollToElement("Dashboard_wrapper");
                                            // setTimeout(() => {
                                            setIsTour2Open(true);
                                            // }, 1000);
                                            hideWindow();
                                        }}
                                        disabled={isTour2Finished}
                                    >
                                        {i18n.t("user_guide.27")}
                                    </Button>
                                </li>
                            </>
                        )}
                    </ol>
                </div>
            </div>
        </>
    );
};

export default DashboardGuide;

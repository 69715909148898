import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import CustomButton from 'helperComponents/CustomButton';

const FileTypePopup = ({ loading, setShowFileTypePopup, downloadData, fileType, setFileType }) => {
   const { t } = useTranslation();

   const handleChange = (e) => {
      setFileType(e.target.value)
   }

   return (
      <div className={styles.fileTypeModal}>
         <div className={styles.centerWrapper}>
            <div className={styles.contentBox}>
               <p className={styles.title}>Export Reviews</p>
               <p className={styles.subtitle}>File Format</p>
               <div className={styles.radioInput}>
                  <div className={styles.item}>
                     <input onChange={handleChange} type="radio" id="xlsx" value={"xlsx"} checked={fileType === 'xlsx'} name="file-type" />
                     <label htmlFor="xlsx">XLSX</label>
                  </div>
                  <div className={styles.item}>
                     <input onChange={handleChange} type="radio" checked={fileType === 'csv'} value="csv" id="csv" name="file-type" />
                     <label htmlFor="csv">CSV</label>
                  </div>
               </div>
               <div className={styles.btnBlock}>
                  <button className={styles.underlineBtn} onClick={() => setShowFileTypePopup(false)}>{t("button.3")}</button>
                  <CustomButton onClick={downloadData} text={t("button.15")} loading={loading} />
               </div>
            </div>
         </div>
      </div>
   )
}

export default FileTypePopup;
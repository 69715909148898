import React from "react";
import CustomInput from "components/General/CustomInput";
import { Modal, Dropdown, MultiSelect, DatePicker, TextArea } from "components/General/ModalFormComponents";
import styles from "./style.module.scss";
import i18n from 'i18next';

const Representation = ({
  showModal,
  onClose,
  save,
  ressource,
  selectedRessource,
  setSelectedRessource,
  boards,
  selectedBoard,
  setSelectedBoard,
  boardMembersList,
  setSelectedBoardMembers,
  selectedBoardMembers,
  setProjectKey,
  setDescription,
  setTitle,
  showSaveButton,
  date,
  setDate,
  theme
}) => (
  <Modal
    showModal={showModal}
    onClose={onClose}
    onSave={save}
    showSaveButton={showSaveButton}
    titleAsset={"/assets/settings/jira.svg"}
    title="Jira"
  >
    {ressource &&<Dropdown
      title={"Choose a Ressource *"}
      options={ressource}
      selectedOption={selectedRessource}
      onChange={(value) => setSelectedRessource(value)}
      idKey="id"
      displayKey="name"
    />}
    {selectedRessource && boards &&
      <Dropdown
        title={"Choose a board *"}
        options={boards.map(elem => elem?.location)}
        selectedOption={selectedBoard}
        onChange={(value) => setSelectedBoard(value)}
        idKey="projectKey"
        displayKey="projectName"
      />}
    {selectedBoard && boardMembersList &&
      <>
        <div className={styles.row}>
          <p className={styles.label}>Summary *</p>
          <CustomInput
            style={{ margin: "-10px 0px 0px 0px", width: "300px", borderRadius: "10px" }}
            onChange={setTitle}
          />
        </div>
        <TextArea
          title={"Description *"}
          onChange={(e) => setDescription(e.target.value)}
        />
        <DatePicker
          title={"Due Date [Optional]"}
          value={date}
          onChange={(date) => setDate(date)}
        />
        <Dropdown
        title={"Assignee [optional]"}
        options={boardMembersList}
        selectedOption={selectedBoardMembers}
        onChange={(value) => setSelectedBoardMembers(value)}
        idKey="accountId"
        displayKey="displayName"
        />
      </>
    }

  </Modal>
)

export default Representation;
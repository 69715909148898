import React, { useState, useRef, useEffect } from "react";
import styles from "./style.module.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import { DatePicker, notification } from "antd";
import { Radio, Button, Space } from "antd";
import Icon from "helperComponents/Icon";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

const DateFilterPopUp = ({
  onChange,
  defaultStartDate,
  defaultEndDate,
  searchPeriod,
  disabled,
  setSelectedDaysRange,
  selectedDaysRange,
  close,
}) => {
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const dateFilterPopupRef = useRef();
  const { t } = useTranslation();
  const setSelectedDate = (date) => {
    setDateRange(date);
  };
  const applyDateFilter = () => {
    if (dateRange.length == 0) {
      onChange([], dayRangeId);
    } else {
      let dateString = [
        dayjs(dateRange[0]).format("YYYY-MM-DD"),
        dayjs(dateRange[1]).format("YYYY-MM-DD"),
      ];
      onChange(dateString, dayRangeId);
    }
  };

  const isDynamic = () => {
    if (DashboardReducer?.dashboard?.RefreshRate) {
      return true;
    } else return false;
  };
  const getDateRange = (range, type) => {
    if (isDynamic()) {
      return [dayjs().add(range, type), dayjs()];
    } else {
      let end_date = DashboardReducer?.end_date;
      if (end_date?.length) {
        return [dayjs(end_date).add(range, type), dayjs(end_date)];
      } else {
        return [
          dayjs(searchPeriod[1]).add(range, type),
          dayjs(searchPeriod[1]),
        ];
      }
    }
  };

  const rangePresets = [
    {
      id: "last24",
      label: t("filter.15"),
      value: getDateRange(-24, "h"),
    },
    {
      id: "last7d",
      label: t("filter.16"),
      value: getDateRange(-6, "d"),
    },
    {
      id: "last14d",
      label: t("filter.17"),
      value: getDateRange(-13, "d"),
    },
    {
      id: "last30d",
      label: t("filter.18"),
      value: getDateRange(-29, "d"),
    },
    {
      id: "last90d",
      label: t("filter.19"),
      value: getDateRange(-89, "d"),
    },
  ];
  const getDaysRange = (daysId) => {
    let date = [];
    if (rangePresets.length) {
      rangePresets.map((info) => {
        if (info.id == daysId) {
          date = info.value;
        }
      });
    }
    return date;
  };
  const [dateRange, setDateRange] = useState(
    selectedDaysRange !== "custom"
      ? getDaysRange(selectedDaysRange)
      : [
          searchPeriod[0] ? dayjs(searchPeriod[0], "YYYY-MM-DD") : "",
          searchPeriod[1] ? dayjs(searchPeriod[1], "YYYY-MM-DD") : "",
        ]
  );
  const [since, setSince] = useState(() => {
    if (selectedDaysRange !== "custom") {
      const dt = getDaysRange(selectedDaysRange);
      return dayjs(dt[0]).format("YYYY-MM-DD");
    } else {
      return searchPeriod[0] ? dayjs(searchPeriod[0], "YYYY-MM-DD") : "";
    }
  });

  const [until, setUntil] = useState(() => {
    if (selectedDaysRange !== "custom") {
      const dt = getDaysRange(selectedDaysRange);
      return dayjs(dt[1]).format("YYYY-MM-DD");
    } else {
      return searchPeriod[1] ? dayjs(searchPeriod[1], "YYYY-MM-DD") : "";
    }
  });
  const [dayRangeId, setDayRangeId] = useState(selectedDaysRange);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const dateClicked = event.target.closest(
  //       `.ant-dropdown, .ant-picker, .ant-picker-header, .ant-picker-body, .ant-radio-wrapper, .${styles.quickDates}`
  //     );
  //     if (!dateClicked) {
  //       if (
  //         dateFilterPopupRef.current &&
  //         !dateFilterPopupRef.current.contains(event.target)
  //       ) {
  //         close();
  //       }
  //     }
  //   };

  //   // Add event listener when click event occurs
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dateFilterPopupRef]);
  return (
    <div ref={dateFilterPopupRef} className={styles.dateFilterPopup}>
      <div className={styles.header}>
        <div className={styles.title}>{t("others.59")}</div>
        <div onClick={close}>
          <Icon name={"close"} />
        </div>
      </div>
      <div className={styles.quickDates}>
        <Radio.Group className={styles.radioWrapper} value={dayRangeId}>
          <Space direction="vertical">
            {rangePresets &&
              rangePresets.map((val) => {
                return (
                  <Radio
                    value={val.id}
                    key={val.id}
                    checked={true}
                    onClick={() => {
                      setSelectedDaysRange(val.id);
                      setDayRangeId(val.id);
                      setSelectedDate(val.value);
                      setSince(dayjs(val.value[0]).format("YYYY-MM-DD"));
                      setUntil(dayjs(val.value[1]).format("YYYY-MM-DD"));
                    }}
                  >
                    {val.label}
                  </Radio>
                );
              })}
            <Radio
              value="custom"
              onClick={() => {
                setSelectedDaysRange("custom");
                setDayRangeId("custom");
                setSelectedDate(dateRange);
                setSince(dayjs(dateRange[0]).format("YYYY-MM-DD"));
                setUntil(dayjs(dateRange[1]).format("YYYY-MM-DD"));
              }}
            >
              {t("filter.20")}
            </Radio>
            <Radio
              value="allTime"
              onClick={() => {
                setSelectedDaysRange("allTime");
                setDayRangeId("allTime");
                setSelectedDate([]);
                setSince("");
                setUntil("");
              }}
            >
              {t("filter.21")}
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.dateRangeView}>
        {selectedDaysRange === "custom" && (
          <div className={styles.datePicker}>
            <DatePicker
              value={since ? dayjs(since, "YYYY-MM-DD") : dayjs()}
              onChange={(date, dateString) => {
                setDayRangeId("custom");
                setSince(dateString);
              }}
              allowClear={false}
              placement="topLeft"
            />
            <DatePicker
              value={until ? dayjs(until, "YYYY-MM-DD") : dayjs()}
              onChange={(date, dateString) => {
                setDayRangeId("custom");
                setUntil(dateString);
              }}
              allowClear={false}
              placement="topRight"
            />
          </div>
        )}
        <div className={styles.footer}>
          <Button className={styles.cancelButton} onClick={close}>
            {t("button.3")}
          </Button>
          <Button className={styles.applyButton} onClick={applyDateFilter}>
            {t("button.40")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateFilterPopUp;

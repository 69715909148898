import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import styles from "./style.module.scss";
import { apiRequest } from "util/services";
import { notification, Spin, Button } from "antd";
import * as actions from "redux/Dashboard/actions.js";
import { useSelector, useDispatch } from "react-redux";
import TrendItem from "./TrendItem";
import CreationItem from "components/NewDashboard/CreationItem";
import CustomInput from "components/General/CustomInput";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Dashboard } from "@material-ui/icons";
import SingleSelect from "helperComponents/SingleSelect";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const TrendsView = ({
  DashboardId,
  getSelectedTopicData,
  selectedTopic,
  closeSelectedTopic,
  topicsType,
  setActiveTopic,
  selectedTopicData,
  isBubbleView,
  setDisableDateRange,
  setSelectedTopicData,
  changeDateRange,
  topicChanged,
  setTopicChanged,
  setShowDownload,
  trends,
  setTrends,
  tempTrends,
  setTempTrends,
  loading,
  setLoading,
}) => {
  const [view, setView] = useState(0);
  const [localState, setState] = useState({ chartView: "daily" });
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState();
  const [searchWord, setSearchWord] = useState("");
  const [newSearch, setNewSearch] = useState(false);
  // const trends = useSelector((state) => state.DashboardReducer?.trends?.trends);
  // const max_y = useSelector((state) => state.DashboardReducer?.trends?.max_y);
  const [max_y, setMaxY] = useState(0);
  const boardLoading = useSelector((state) => state.DashboardReducer.loading);
  const dispatch = useDispatch();
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const history = useHistory();
  const [totalTopics, setTotalTopics] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [queryFilters, setQueryFilters] = useState({});
  const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [sortedTopicsList, setSortedTopicsList] = useState([]);
  const [tempSortedTopicsList, setTempSortedTopicsList] = useState([]);
  const [chartView, setChartView] = useState({
    id: "daily",
    label: "Daily",
    value: "daily",
  });
  const [sortBy, setSortBy] = useState({
    id: "newest",
    label: "Newest",
    value: "newest",
  });
  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "selected_topic" ||
          key === "decisionFilter" ||
          key === "active_topic_data" ||
          key === "child_others" ||
          key === "parent_others" ||
          key === "hotterm"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  const skipOnce = useRef(true);
  useEffect(() => {
    if (topicsType !== "ai" && params.has("daysRange")) {
      if (skipOnce.current) {
        skipOnce.current = false;
        return;
      }
      getSortedTopicIds();
    }
  }, [queryFilters.daysRange, DashboardReducer.loadTrends, chartView, sortBy]);
  const skipAgain = useRef(true);
  useEffect(() => {
    if (params.has("daysRange")) {
      if (skipAgain.current) {
        skipAgain.current = false;
        return;
      }
      getTopics();
    }
  }, [startIndex]);
  useEffect(() => {
    if (newSearch) {
      getSortedTopicIds();
    }
  }, [newSearch]);
  useEffect(() => {
    if (DashboardReducer.loadTrends || loading === true) {
      setDisableDateRange([true, true]);
    } else {
      setDisableDateRange([false, false]);
    }
  }, [DashboardReducer.loadTrends, loading]);

  const skipAiTrendsOnce = useRef(true);
  useEffect(() => {
    if (
      topicsType === "ai" &&
      !isBubbleView &&
      params.get("view_type") === "trends" &&
      params.has("daysRange") &&
      (!boardLoading || DashboardReducer.loadTrends)
    ) {
      if (skipAiTrendsOnce.current) {
        skipAiTrendsOnce.current = false;
        return;
      }
      getSortedTopicIds();
    }
  }, [DashboardReducer.loadTrends, chartView, queryFilters.daysRange, sortBy]);

  useEffect(() => {
    if (topicsType !== "ai" && trends && trends.length > 0) {
      trends.forEach((trend, index) => {
        if (trend.TopicId === DashboardReducer.selected_topic) {
          setStartIndex(Math.floor(index / 10));
        }
      });
    }
  }, [DashboardReducer.selected_topic, trends]);

  const getSearchTopics = () => {
    setNewSearch(true);
    closeSelectedTopic();
  };

  const updatePage = (increment) => {
    setStartIndex(startIndex + increment);
    closeSelectedTopic();
  };
  const getTopics = async () => {
    setLoading(true);
    const payload = {
      dashboard_id: parseInt(DashboardId, 10),
      topic_ids: tempSortedTopicsList.slice(
        startIndex * pageSize,
        startIndex * pageSize + pageSize
      ),
      topic_type: topicsType === "ai" ? 1 : 2,
      period: chartView.id,
    };

    if (params.get("since") && params.get("until")) {
      payload.since = params.get("since");
      payload.until = params.get("until");
    }
    const result = await apiRequest(
      "dashboards/v2/get/trends_from_topics",
      "POST",
      payload,
      true
    );
    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: result?.error
          ? result.error
          : "Getting trend view data failed",
      });
      setTrends([]);
      setNewSearch(false);
      setTempTrends([]);
      setLoading(false);
    } else {
      setTrends(result.data);
      setNewSearch(false);
      setTempTrends(result.data);
      setLoading(false);
    }
  };
  const getSortedTopicIds = async () => {
    setLoading(true);
    const payload = {
      dashboard_id: parseInt(DashboardId, 10),
      topic_type: topicsType === "ai" ? 1 : 2,
      period: chartView.id,
      sort_by: sortBy.id,
    };

    if (params.get("since") && params.get("until")) {
      payload.since = params.get("since");
      payload.until = params.get("until");
    }
    if (searchWord && newSearch) {
      payload.search_term = searchWord;
      updatePage(-startIndex);
    } else if (searchWord && !newSearch) {
      payload.search_term = searchWord;
    } else if (searchWord === "" && newSearch) {
      payload.search_term = searchWord;
      updatePage(-startIndex);
    }
    const result = await apiRequest(
      "dashboards/v2/get/sorted_trend_topics",
      "POST",
      payload,
      true
    );

    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: result?.error
          ? result.error
          : "Getting trend view data failed",
      });
      setSortedTopicsList([]);
      setTempSortedTopicsList([]);
      setLoading(false);
    } else {
      if (!searchWord) {
        setSortedTopicsList(result.topic_ids);
        setTotalTopics(result.total_topics);
      }
      setNewSearch(false);
      setTempSortedTopicsList(result.topic_ids);
      setTotalTopics(result.total_topics);
    }
  };

  useEffect(() => {
    if (tempSortedTopicsList && tempSortedTopicsList.length > 0) {
      getTopics();
    } else {
      setTrends([]);
      setTempTrends([]);
      setLoading(false);
      setTotalTopics(0);
      setNewSearch(false);
    }
  }, [tempSortedTopicsList]);
  useEffect(() => {
    if (tempTrends && tempTrends.length === 0) {
      setShowDownload(false);
    } else {
      setShowDownload(true);
    }
  }, [tempTrends]);
  const sortOptions = [
    {
      id: "newest",
      label: "Newest",
      value: "newest",
    },
    {
      id: "oldest",
      label: "Oldest",
      value: "oldest",
    },
    {
      id: "volume-down",
      label: "Volume Down",
      value: "volume-down",
    },
    {
      id: "volume-up",
      label: "Volume Up",
      value: "volume-up",
    },
    {
      id: "a_z",
      label: "A-Z",
      value: "a_z",
    },
    {
      id: "z_a",
      label: "Z-A",
      value: "z_a",
    },
  ];
  return (
    <Spin spinning={loading}>
      {topicsType === "custom" && trends?.length > 0 && (
        <CustomInput
          value={searchWord}
          placeholder={t("placeholder.16")}
          onChange={setSearchWord}
          onOk={getSearchTopics}
          searchWord={searchWord}
          extra={
            <>
              <Button
                type="primary"
                onClick={getSearchTopics}
                style={{
                  backgroundColor: searchWord
                    ? theme === "brand"
                      ? "#4a4d4e"
                      : "#4400AA"
                    : theme === "dark"
                    ? "#100D31"
                    : "#ffffff",
                  margin: "0",
                  color: searchWord && "white",
                }}
              >
                {t("button.5")}
              </Button>
              {searchWord && (
                <div
                  className={styles.closeIcon}
                  style={{
                    color: theme === "dark" ? "white" : "black",
                    cursor: "pointer",
                  }}
                  onClick={() => setSearchWord("")}
                >
                  <CloseOutlined />
                </div>
              )}
            </>
          }
        />
      )}
      <div className={styles.MainWindow}>
        {trends && !trends.cancel && trends.length !== 0 ? (
          <>
            {tempTrends && tempTrends.length > 0 ? (
              <>
                <div className={styles.header} id="trendsview_header">
                  <div className={styles.headerOptions}>
                    <span
                      className={`${styles.btn} ${view === 0 &&
                        styles.activeBtn}`}
                      onClick={() => setView(0)}
                    >
                      {t("selected_dashboard_page.22")}
                    </span>
                    <span
                      className={`${styles.btn} ${view === 1 &&
                        styles.activeBtn}`}
                      onClick={() => setView(1)}
                    >
                      {t("selected_dashboard_page.23")}
                    </span>
                    <div className={styles.chartView} id="trendsview_chart">
                      <SingleSelect
                        options={["Daily", "Monthly", "Yearly"].map((item) => ({
                          id: item.toLowerCase(),
                          label: item,
                          value: item.toLowerCase(),
                        }))}
                        selectedOptions={chartView}
                        setSelectedOptions={setChartView}
                      />
                    </div>
                  </div>
                  <div className={styles.sortBox}>
                    <span>Sort by</span>
                    <SingleSelect
                      options={sortOptions}
                      selectedOptions={sortBy}
                      setSelectedOptions={setSortBy}
                    />
                  </div>
                </div>
                <div
                  className={`${styles.content} ${
                    topicsType === "custom" ? styles.gridLayout : undefined
                  }`}
                  id="trendsview_content"
                >
                  {!tempTrends.cancel &&
                    tempTrends?.map((trend, index) => {
                      return (
                        <TrendItem
                          selectedTopic={selectedTopic}
                          closeSelectedTopic={closeSelectedTopic}
                          trend={trend}
                          trends={tempTrends}
                          view={view}
                          setActiveTopic={setActiveTopic}
                          getSelectedTopicData={getSelectedTopicData}
                          topicsType={topicsType}
                          selected={
                            topicsType !== "ai" &&
                            trend.TopicId === DashboardReducer.selected_topic &&
                            !boardLoading
                          }
                          max_y={max_y}
                          theme={theme}
                          period={chartView.id}
                          getTopics={getSortedTopicIds}
                          setSelectedTopicData={setSelectedTopicData}
                          topicChanged={topicChanged}
                          setTopicChanged={setTopicChanged}
                        />
                      );
                    })}
                </div>
              </>
            ) : (
              <div className={styles.wrapper}>
                <div className={styles.message}>
                  <div>
                    <img
                      className={styles.message_img}
                      src="/assets/info.svg"
                      width={40}
                      height={40}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={styles.message_text}>
                      {t("notifications.79", { searchTerm: searchWord })}
                    </p>
                  </div>
                </div>
                <div className={styles.message_sub}>
                  <p>{t("selected_dashboard_page.52")}</p>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {!loading && topicsType === "ai" ? (
              <h4 className={styles.noTrends}>{t("notifications.80")}</h4>
            ) : (
              !loading && (
                <div className={styles.noTopics}>
                  <p className={styles.heading}>
                    {t("selected_dashboard_page.81")}
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/emptyStates/topic_library_icon.svg"
                          : "/assets/emptyStates/topic_library_black.svg"
                      }
                      alt=""
                    />
                  </p>
                  <div className={styles.secondRow}>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/error_outline.svg"
                          : "/assets/error_outline_black.svg"
                      }
                      alt=""
                    />
                    {t("selected_dashboard_page.78")}
                  </div>
                  <div className={styles.imgBlock}>
                    <div>
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/emptyStates/dashboard_custom_topic.png"
                            : theme === "light"
                            ? "/assets/emptyStates/dashboard_custom_topic_light.png"
                            : "/assets/emptyStates/dashboard_custom_topic_brand.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <p
                    className={styles.link}
                    onClick={() => history.push(`/console/topicLibrary`)}
                  >
                    {t("empty_states.4")}
                  </p>
                </div>
              )
            )}
          </>
        )}
        {tempTrends &&
          tempTrends.length !== 0 &&
          (topicsType === "ai" || totalTopics > 10) && (
            <div className={styles.footer}>
              <div className={styles.btn}>
                <Button
                  disabled={startIndex === 0}
                  className={view === 0 && styles.activeBtn}
                  onClick={() => updatePage(-1)}
                >
                  {t("button.28")}
                </Button>
              </div>
              <span className={styles.paginator}>
                {t("label.10")} {startIndex + 1}{" "}
                {lastIndex ? ` / ${lastIndex + 1}` : ""}
              </span>
              <div className={styles.btn}>
                <Button
                  disabled={
                    startIndex * pageSize + tempTrends.length === totalTopics
                  }
                  className={view === 1 && styles.activeBtn}
                  onClick={() => updatePage(1)}
                >
                  {t("button.29")}
                </Button>
              </div>
              <div>
                {tempTrends && tempTrends.length && (
                  <span>{`${startIndex * pageSize +
                    tempTrends.length}/${totalTopics}`}</span>
                )}
              </div>
            </div>
          )}
      </div>
    </Spin>
  );
};

export default TrendsView;

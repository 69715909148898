import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "components/General/ModalFormComponents";
import { InfoCircleFilled } from "@ant-design/icons";
import CustomInput from "components/General/CustomInput";
import { getDateString, numberWithCommas } from "util/common";
import styles from "./style.module.scss";
import { apiRequest } from "util/services";
import { notification } from "antd";
import { Button, Input, Popover } from "antd";
import { useSelector } from "react-redux";
import i18n from "i18next";

const Wrapper = ({ title, subtitle, children }) => (
  <div className={styles.wrapperHeader}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    <h7 className={styles.wrapperSubtitle}>{subtitle}</h7>
    {children}
  </div>
);

const Tooltip = ({ content }) => (
  <Popover content={content} trigger="hover">
    <InfoCircleFilled style={{ fontSize: "20px" }} />
  </Popover>
);

const UnsubscribeModal = ({
  isUnsubscribeModalVisible,
  setIsUnsubscribeModalVisisble,
  handleUnSubscribe,
}) => (
  <Modal
    showModal={isUnsubscribeModalVisible}
    onClose={() => setIsUnsubscribeModalVisisble(false)}
    onSave={handleUnSubscribe}
    showSaveButton={true}
    textonButton={i18n.t("button.15")}
    titleAsset=""
    title={i18n.t("button.32")}
    widthofmodal={window.innerWidth > 800 ? "650px" : "350px"}
  >
    <p>{i18n.t("settings.122")}</p>
  </Modal>
);

const TicketModal = ({
  isTicketModalLoading,
  isTicketModalVisible,
  setIsTicketModalVisible,
  ticketText,
  setTicketText,
  submitTicket,
  theme,
}) => (
  <Modal
    showModal={isTicketModalVisible}
    onClose={() => {
      setIsTicketModalVisible(false);
    }}
    onSave={submitTicket}
    showSaveButton={true}
    showCancelButton={true}
    textonButton={i18n.t("button.4")}
    titleAsset=""
    title={i18n.t("settings.123")}
    widthofmodal="450px"
  >
    <Input.TextArea
      style={{
        height: "130px",
        width: "350px",
        backgroundColor: "transparent",
        borderColor: "#6f6c99 ",
        borderRadius: "10px",
        color: theme === "dark" ? "white" : "black",
      }}
      placeholder={i18n.t("settings.124")}
      onChange={(e) => setTicketText(e.target.value)}
      value={ticketText}
    />
  </Modal>
);

const PlanDetails = ({
  plan,
  setIsUnsubscribeModalVisisble,
  setIsTicketModalVisible,
}) => (
  <div className={styles.content}>
    <h3 className={styles.header}>{i18n.t("settings.125")}</h3>
    <div className={styles.row}>
      <h5>{i18n.t("settings.126")}</h5>
      <h5>{plan?.PackageName}</h5>
    </div>
    <div className={styles.row}>
      <h5>{i18n.t("settings.127")}</h5>
      <h5>{getDateString(plan?.StartDate)}</h5>
    </div>
    <div className={styles.row}>
      <h5>{i18n.t("settings.128")}</h5>
      <h5>
        {plan?.EndDate ? getDateString(plan.EndDate) : i18n.t("settings.129")}
      </h5>
    </div>
    <div className={styles.row}>
      {plan?.PackageId === 68 ? (
        <h5>{i18n.t("settings.130")}</h5>
      ) : (
        <h5>{i18n.t("settings.131")}</h5>
      )}
      <h5>{numberWithCommas(plan?.RemainingConversationQuota) || 0}</h5>
    </div>
    {plan?.PackageId === 68 ? (
      <></>
    ) : (
      <div className={styles.row}>
        <h5>{i18n.t("settings.132")}</h5>
        <h5>{numberWithCommas(plan?.RemainingTopicBoards) || 0}</h5>
      </div>
    )}
    {plan?.PackageId === 68 ? (
      plan?.intentAnalysis === true ? (
        <div className={styles.row}>
          <h5>{i18n.t("settings.133")}</h5>
          <h5>{i18n.t("settings.134")}</h5>
        </div>
      ) : (
        <div className={styles.row}>
          <h5>{i18n.t("settings.133")}</h5>
          <h5>{i18n.t("settings.135")}</h5>
        </div>
      )
    ) : (
      <></>
    )}

    <div className={styles.rowBtns}>
      {plan?.PackageId !== 100 && plan?.PackageId !== 68 && (
        <div className={styles.btn}>
          <Button
            onClick={() =>
              (document.location.href = document.location.origin + "/checkout")
            }
          >
            {i18n.t("settings.136")}
          </Button>
        </div>
      )}
      {plan?.PackageId !== 68 && (
        <div className={`${styles.btn} ${styles.btnGray}`}>
          <Button onClick={() => setIsUnsubscribeModalVisisble(true)}>
            {i18n.t("button.32")}
          </Button>
        </div>
      )}
      <div className={styles.spacer} />
      <div className={`${styles.btn} ${styles.btnContact}`}>
        <button onClick={() => setIsTicketModalVisible(true)}>
          {i18n.t("settings.137")}
        </button>
      </div>
    </div>
  </div>
);

const AppSumo = ({
  show,
  inputLabel,
  inputPlaceholder,
  appSumoCode,
  setAppSumoCode,
  isReedemingAppsumoButton,
  handeAppsumoCoupon,
  handleAppsumoMultipleCoupons,
}) => (
  <>
    {show && (
      <div className={styles.content}>
        <h3 className={styles.header}>{i18n.t("settings.138")}</h3>
        <div className={styles.row}>
          <div>
            <CustomInput
              label={inputLabel}
              placeholder={inputPlaceholder}
              value={appSumoCode}
              onChange={(val) => setAppSumoCode(val)}
              style={{ margin: "0px" }}
            />
          </div>
        </div>
        <div className={styles.rowBtns}>
          <div className={styles.btn}>
            <Button
              disabled={isReedemingAppsumoButton}
              onClick={() => handeAppsumoCoupon()}
            >
              {i18n.t("settings.139")}
            </Button>
          </div>
        </div>
      </div>
    )}
  </>
);

let selectedadditionaldashboard = 0;
let selectedadditionaldashboardprice = 0;
let pricing = 0;
let adddashboard = 0;

const BuyCard = ({ plan, title, price1, pr, handleClick1, handleClick2 }) => {
  return (
    <div className={styles.buyCard}>
      <h5 className={styles.title}>{title} </h5>
      <div className={styles.row}>
        <div className={styles.typeWrapper}>
          {/* <p className={styles.buyCardText}>Type 1</p>  */}
          <p className={styles.buyCardText}>{price1}</p>
          <div className={`${styles.btn} ${styles.btnBuy}`}>
            <Button
              onClick={() => {
                selectedadditionaldashboard = title;
                selectedadditionaldashboardprice = price1;
                pricing = pr;
                adddashboard = title.substring(4, 7);
                handleClick1();
              }}
            >
              {i18n.t("settings.140")}
            </Button>
          </div>
        </div>
        {/* <div className={styles.typeWrapper}>
        <p className={styles.buyCardText}>Type 2</p>
        <p className={styles.buyCardText}>{price2}</p>
        <div className={`${styles.btn} ${styles.btnBuy}`}>
          <Button onClick={() => handleClick2()}>Buy</Button>
        </div>
      </div> */}
      </div>
    </div>
  );
};

const ConfirmAdditionalDashboardCard = ({ title, price1 }) => (
  <div className={styles.buyCard}>
    <h5 className={styles.title}>{title} </h5>
    <div className={styles.row}>
      <div className={styles.typeWrapper}>
        <p className={styles.buyCardText}>{price1}</p>
      </div>
    </div>
  </div>
);
const BuyAdditiopnalDashboards = ({ user, handleClick }) => {
  const [price, setprice] = useState("");
  const [currency, setcurrency] = useState("");
  const [currencynotfound, setcurrencynotfound] = useState(5);

  useEffect(() => {
    const payload = {
      user_id: user.uid,
    };
    apiRequest(
      "subscriptions/extradashboards/rates",
      "POST",
      payload,
      true
    ).then((result) => {
      if (!result.error) {
        setprice(result.success.Cost);
        setcurrency(result.success.Currency);
      } else {
        setcurrencynotfound("");
      }
    });
  }, []);

  let symbol = "";
  if (currency === "GBP") {
    symbol = "£ ";
  } else if (currency === "USD") {
    symbol = "$ ";
  } else if (currency === "TRY") {
    symbol = "₺ ";
  } else {
    symbol = "€ ";
  }

  const [Buyaddional, setBuyaddional] = useState(false);

  return (
    currencynotfound && (
      <>
        <div className={styles.content1}>
          <div className={styles.col}>
            <h3 className={styles.header}>
              {i18n.t("settings.141")}
              <Tooltip content={i18n.t("settings.142")} />
            </h3>
          </div>
          <p className={styles.expertSubtitle}>{i18n.t("settings.143")}</p>
          <div className={styles.row}>
            <BuyCard
              title={i18n.t("settings.144", { num: "10" })}
              price1={symbol + price["10"]}
              pr={price["10"]}
              handleClick1={() => setBuyaddional(true)}
            />
            <BuyCard
              title={i18n.t("settings.144", { num: "25" })}
              price1={symbol + price["25"]}
              pr={price["25"]}
              handleClick1={() => setBuyaddional(true)}
            />
            <BuyCard
              title={i18n.t("settings.144", { num: "50" })}
              price1={symbol + price["50"]}
              pr={price["50"]}
              handleClick1={() => setBuyaddional(true)}
            />
            <BuyCard
              title={i18n.t("settings.144", { num: "100" })}
              price1={symbol + price["100"]}
              pr={price["100"]}
              handleClick1={() => setBuyaddional(true)}
            />
          </div>
          <Modal
            title={i18n.t("button.33")}
            open={Buyaddional}
            onCancel={() => setBuyaddional(false)}
            showModal={Buyaddional}
            onClose={() => setBuyaddional(false)}
            onSave={function() {
              setBuyaddional(false);
              handleClick(user, adddashboard, pricing);
            }}
            showSaveButton={true}
            titleAsset=""
            textonButton={i18n.t("button.15")}
            widthofmodal="425px"
          >
            <ConfirmAdditionalDashboardCard
              title={selectedadditionaldashboard}
              price1={selectedadditionaldashboardprice}
            />
          </Modal>
        </div>
      </>
    )
  );
};

const CardContent = ({ handleClick, asset, text, btnText }) => (
  <div className={styles.expertWrapper}>
    <div className={styles.expertMessage}>
      <p className={styles.expertMessageText}>{text}</p>
    </div>
    <div className={styles.rowBtns}>
      <div className={`${styles.row} ${styles.btn} ${styles.btnSmall}`}>
        <Button onClick={() => handleClick()}>{btnText}</Button>
        <div className={styles.spacer} />
        <img className={styles.expertMessageImg} src={asset} alt="" />
      </div>
    </div>
  </div>
);

const Translation = ({ handleTranslation }) => (
  <div className={styles.content}>
    <h3 className={styles.header}>
      {i18n.t("settings.145")}
      <Tooltip content={i18n.t("settings.146")} />
    </h3>
    <p className={styles.expertSubtitle}>{i18n.t("settings.147")}</p>
    <div className={styles.spacer} />
    <CardContent
      handleClick={handleTranslation}
      asset="/assets/alarm.svg"
      text={i18n.t("settings.148")}
      btnText={i18n.t("button.35")}
    />
  </div>
);

const ExpertInsight = ({ plan, handleExpertInsight, theme }) => (
  <div className={styles.content}>
    <h3 className={styles.header}>
      {i18n.t("settings.149")}
      <Tooltip content={i18n.t("settings.150")} />
    </h3>
    <p className={styles.expertSubtitle}>{i18n.t("settings.151")}</p>
    <div className={styles.spacer} />
    {plan?.PackageAllowExpert ? (
      <>
        {!plan?.authorizedExpert ? (
          <CardContent
            handleClick={handleExpertInsight}
            asset="/assets/alarm.svg"
            text={i18n.t("settings.153")}
            btnText={i18n.t("button.34")}
          />
        ) : (
          <CardContent
            handleClick={handleExpertInsight}
            asset={
              theme === "brand"
                ? "/assets/verified_brand.svg"
                : "/assets/verified_color.svg"
            }
            text={i18n.t("settings.154")}
            btnText={i18n.t("settings.152")}
          />
        )}
      </>
    ) : (
      <CardContent
        handleClick={() =>
          (document.location.href = document.location.origin + "/checkout")
        }
        asset="/assets/alarm.svg"
        text={i18n.t("settings.155")}
        btnText={i18n.t("settings.136")}
      />
    )}
  </div>
);
const getInputLabel = (packageId) => {
  switch (packageId) {
    case 65:
      return i18n.t("settings.160");
    case 66:
      return i18n.t("settings.161");
    case 68:
      return i18n.t("settings.162");
    case 69:
      return i18n.t("settings.163");
    default:
      return "";
  }
};
const Representation = ({
  user,
  handleBuyAdditionalDashboard,
  handleTranslation,
  handleExpertInsight,
  plan,
  appSumoCode,
  setAppSumoCode,
  handeAppsumoCoupon,
  handleAppsumoMultipleCoupons,
  isReedemingAppsumoButton,
  isUnsubscribeModalVisible,
  setIsUnsubscribeModalVisisble,
  handleUnSubscribe,
  isTicketModalLoading,
  isTicketModalVisible,
  setIsTicketModalVisible,
  ticketText,
  setTicketText,
  submitTicket,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <Wrapper
      title={i18n.t("settings_sidebar.5")}
      subtitle={i18n.t("settings.156")}
    >
      <div className={styles.wrapper}>
        <PlanDetails
          plan={plan}
          setIsUnsubscribeModalVisisble={setIsUnsubscribeModalVisisble}
          setIsTicketModalVisible={setIsTicketModalVisible}
        />
        <AppSumo
          appSumoCode={appSumoCode}
          setAppSumoCode={setAppSumoCode}
          inputLabel={getInputLabel(plan?.PackageId)}
          inputPlaceholder={
            plan?.PackageId === 65 || plan?.PackageId === 68
              ? i18n.t("settings.157")
              : i18n.t("settings.158")
          }
          show={
            plan?.PackageId === 65 ||
            plan?.PackageId === 66 ||
            plan?.PackageId === 69
          }
        />
        {plan?.PackageId !== 68 && (
          <BuyAdditiopnalDashboards
            user={user}
            handleClick={handleBuyAdditionalDashboard}
          />
        )}
        {plan?.PackageId !== 68 ? (
          <div className={styles.row}>
            <ExpertInsight
              plan={plan}
              handleExpertInsight={handleExpertInsight}
              theme={theme}
            />
            <Translation handleTranslation={handleTranslation} />
          </div>
        ) : (
          <div className={styles.content}>
            <h4 className={styles.header}>{i18n.t("settings.159")}</h4>
            <div className={styles.row}>
              <CustomInput
                placeholder="COUPON1,COUPON2,COUPON3"
                value={appSumoCode}
                onChange={(val) => setAppSumoCode(val)}
                style={{ margin: "0px" }}
              />
            </div>
            <div className={styles.rowBtns}>
              <div className={styles.btn}>
                <Button
                  disabled={isReedemingAppsumoButton}
                  onClick={() => handleAppsumoMultipleCoupons()}
                >
                  {i18n.t("button.36")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <UnsubscribeModal
        isUnsubscribeModalVisible={isUnsubscribeModalVisible}
        handleUnSubscribe={handleUnSubscribe}
        setIsUnsubscribeModalVisisble={setIsUnsubscribeModalVisisble}
      />
      <TicketModal
        isTicketModalVisible={isTicketModalVisible}
        submitTicket={submitTicket}
        isTicketModalLoading={isTicketModalLoading}
        ticketText={ticketText}
        setTicketText={setTicketText}
        setIsTicketModalVisible={setIsTicketModalVisible}
        theme={theme}
      />
    </Wrapper>
  );
};

export default Representation;

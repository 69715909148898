import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import JourneyHeader from "components/General/JourneyHeader";
import CustomInput from "components/General/CustomInput";
import { UNLOGGED, APP_MESSAGES } from "util/constants";
import { apiRequest } from "util/services";
import { notification, Button, Select, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { editorConfigurations } from "./editorConfig";
import { Editor } from "@tinymce/tinymce-react";

const EmailMarketing = ({ authenticated, user }) => {
  const [audience, setAudience] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sender, setSender] = useState("");
  const [subject, setSubject] = useState("");
  const [htmlBody, setHtmlBody] = useState("");
  const [textBody, setTextBody] = useState("");
  const [target, setTarget] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
    const loadAudience = async () => {
      const result = await apiRequest("audiences/all", "POST", {
        user_id: user.uid,
      });
      if (result) {
        setAudience(result);
      } else {
        notification.error({ message: "An error occured in loading audience" });
      }
    };
    loadAudience();
  }, []);

  const sendEmail = async () => {
    if (!sender || !target || !subject || !htmlBody) {
      notification.error({
        message: "You must fill all the fields",
      });
      return;
    }
    const payload = {
      firebase_id: user.uid,
      sender_email: sender,
      audience_id: parseInt(target, 10),
      email_subject: subject,
      email_text: textBody,
      email_html: htmlBody,
    };
    const result = await apiRequest(
      "audiences/email/send",
      "POST",
      payload,
      true
    );
    if (!result.error) {
      notification.success({
        message: "Email sent successfully",
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
  };

  return (
    <div className={styles.mainWindow}>
      <JourneyHeader
        title={APP_MESSAGES.EMAIL_MARKETING.HEADER}
        subtitle={APP_MESSAGES.EMAIL_MARKETING.SUBHEADER}
      />
      <Spin spinning={loading}>
        <div className={styles.content}>
          <div className={styles.row}>
            <CustomInput
              label="From"
              placeholder="Sender Email Address"
              onChange={setSender}
            />
            <div className={`${styles.target}`}>
              <h3>To: </h3>
              <Select
                placeholder={"Select your target audience"}
                onChange={(value) => setTarget(value)}
                style={{
                  width: "100%",
                }}
                showSearch
                optionFilterProp="title"
              >
                <Select.Option key={"initial"} value="">
                  Select your target audience
                </Select.Option>
                {audience.map((item) => {
                  return (
                    <option key={item.ID} value={item.ID} title={item.Name}>
                      {item.Name}
                    </option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className={styles.row}>
            <CustomInput
              label="Subject"
              placeholder="Email Subject"
              onChange={setSubject}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.emailBody}>
              <h3>Body: </h3>
              <div className={styles.bodyContent}>
                <Editor
                  apiKey="iri3bbun68fbtokzf9f9fsabi5siq9yuml1b5atq31neulvc"
                  init={editorConfigurations}
                  onInit={() => setLoading(false)}
                  onEditorChange={(content, editor) => {
                    setHtmlBody(content);
                    setTextBody(editor.contentDocument.activeElement.innerText);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.btn}>
            <Button onClick={sendEmail}>Send</Button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default EmailMarketing;

import React, { useEffect, useState } from "react";
import { Radio, Tag, Badge, Spin } from "antd";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HotTerms = ({
  allNgrams,
  loading,
  setLoading,
  overLimit,
  disableSelection,
}) => {
  const [ngrams, setNgrams] = useState(null);
  const [terms, setTerms] = useState([]);
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const dispatch = useDispatch();
  const [noNgrams, setNoNgrams] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (allNgrams && Object.keys(allNgrams).length > 0) {
      for (let i = Object.keys(allNgrams).length; i > 0; i--) {
        if (allNgrams[i] && allNgrams[i].length > 0) {
          setNgrams(i);
          break;
        }
      }
      if (!ngrams) {
        setNoNgrams(true);
      }
    }
  }, [allNgrams]);

  const addHotTerm = (termsObj) => {
    const term = termsObj.terms;
    const similarTerms = [];
    if (
      termsObj?.hasOwnProperty("similar_ngrams") &&
      termsObj?.similar_ngrams?.length > 0
    ) {
      termsObj.similar_ngrams.forEach((ngram) =>
        similarTerms.push(ngram.terms)
      );
    }
    const selectedTerms = params.has("hotterm")
      ? JSON.parse(params.get("hotterm"))
      : [];
    if (selectedTerms.term === term) {
      params.delete("hotterm");
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenHotTerm: [],
        },
      });
    } else {
      params.delete("hotterm");
      params.append(
        "hotterm",
        JSON.stringify({
          term,
          similarTerms,
        })
      );
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenHotTerm: [term, ...similarTerms],
        },
      });
    }
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        showNewReviewsLabel: true,
      },
    });
  };
  const visualTerms = terms?.map((term) => {
    return (
      <Tag
        icon={
          <Badge
            count={term.count}
            overflowCount={9999}
            style={{
              marginRight: "10px",
              background: "#FFFFFF",
              border: "none",
              color: "#4400AA",
            }}
          />
        }
        className={
          term.terms === DashboardReducer.searchState.chosenHotTerm
            ? styles.activeTerm
            : ""
        }
        color={theme === "brand" ? "#4a4d4e" : "#4400AA"}
        onClick={() => !disableSelection && addHotTerm(term)}
        title={term.terms}
      >
        {term.terms}
      </Tag>
    );
  });

  useEffect(() => {
    if (
      allNgrams &&
      Object.values(allNgrams).flat()?.length > 0 &&
      !overLimit
    ) {
      setNoNgrams(false);
      setTerms(allNgrams[ngrams]);
    }
  }, [allNgrams, ngrams]);
  useEffect(() => {
    if (
      (allNgrams && Object.values(allNgrams).flat()?.length === 0) ||
      overLimit
    ) {
      setNoNgrams(true);
      setTerms([]);
    }
  }, [noNgrams, allNgrams]);
  useEffect(() => {
    if (ngrams) {
      setDefaultValue(ngrams);
    }
  }, [allNgrams, ngrams]);

  return (
    <div className={styles.HotWordsSection}>
      <Spin spinning={loading}>
        <div className={styles.radioContainer}>
          {allNgrams && ngrams && defaultValue && (
            <Radio.Group
              defaultValue={defaultValue}
              value={defaultValue}
              buttonStyle="solid"
              onChange={(e) => setNgrams(e.target.value)}
            >
              {[1, 2, 3, 4].map((i) => (
                <>
                  {allNgrams[i] && allNgrams[i].length > 0 && (
                    <Radio.Button
                      style={{
                        display:
                          allNgrams[i] && allNgrams[i].length == 0
                            ? "none"
                            : "",
                      }}
                      checked={i === defaultValue}
                      value={i}
                    >
                      {t("selected_dashboard_page.101", {
                        n: i,
                      })}
                    </Radio.Button>
                  )}
                </>
              ))}
            </Radio.Group>
          )}
        </div>

        <div className={styles.description}>
          {noNgrams ? (
            <></>
          ) : (
            <span>
              {t("selected_dashboard_page.102", {
                n: ngrams,
              })}
            </span>
          )}
        </div>
        <div className={styles.visualTerms}>
          {noNgrams ? (
            overLimit ? (
              <p className={styles.noNgramsMsg}>
                {t("selected_dashboard_page.103")}
              </p>
            ) : (
              <p className={styles.noNgramsMsg}>
                {t("selected_dashboard_page.104")}
              </p>
            )
          ) : (
            visualTerms
          )}
        </div>
      </Spin>
    </div>
  );
};

export default HotTerms;

import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";

import {
  EditFilled,
  DislikeFilled,
  LikeFilled,
  DeleteOutlined,
  FunnelPlotOutlined,
  WarningFilled,
  SettingFilled,
} from "@ant-design/icons";
import { apiRequest } from "util/services";
import { keyPressed, getDateString } from "util/common";
import { useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import LineChartWrapper from "components/Dashboard/LineChartWrapper";
import { notification, Card, Input, Tag, Button } from "antd";

import { Modal } from "components/General/ModalFormComponents";
import CustomInput from "components/General/CustomInput";
import { useTranslation } from "react-i18next";

const InsightsCard = ({
  insight,
  insights,
  setInsights,
  shared,
  currentDashboardHash,
  className,
  id,
  user,
  theme,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [insightTxt, setInsightTxt] = useState(insight?.insight);
  const [insightTitleTxt, setinsightTitleTxt] = useState(insight?.insightTitle);
  const [settingsModal, setSettingsModal] = useState(false);
  const [population, setPopulation] = useState(insight?.population_size || 0);
  // const [initialPopulation, setInitialPopulation] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);

  // useEffect(() => {
  //   const payload = {
  //     user_id: user.uid,
  //     dashboard_id: parseInt(id),
  //   }
  //   apiRequest(
  //     'dashboards/insights/population',
  //     'post',
  //     payload,
  //     true
  //   ).then(result => {
  //     if (!result.error) {
  //       setPopulation(result.population_size);
  //     }
  //   })
  // }, [])

  const deleteInsight = async () => {
    const result = await apiRequest(
      "dashboards/insights/delete",
      "POST",
      { insight_id: insight.ID },
      true
    );
    if (result.error) {
      notification.error({ message: result.error });
    } else {
      notification.success({ message: t("notifications.21") });
      setInsights(insights.filter((item) => item.ID !== insight.ID));
    }
    setDeleteModal(false);
  };

  const determinePeriod = (val) => {
    try {
      let identifier = (val.match(/-/g) || []).length;
      return identifier === 0
        ? "Yearly"
        : identifier === 1
        ? "Monthly"
        : "Daily";
    } catch (e) {
      console.log("Error", e);
      return "Monthly";
    }
  };

  const generateDateString = (date, period) => {
    let x;
    if (period === "Daily" || period === "Weekly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (String(date.getDate()).length === 1 ? "0" : "") +
        date.getDate();
    } else if (period === "Monthly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1);
    } else {
      x = String(date.getFullYear());
    }
    return x;
  };

  const editInsight = async () => {
    if (!insightTxt) {
      notification.error({ message: t("notifications.46") });
      return;
    }
    const result = await apiRequest(
      "dashboards/insights/update",
      "POST",
      {
        insight_id: insight.ID,
        edited_insight: insightTxt,
        edited_insight_title: insightTitleTxt,
      },
      true
    );
    if (result.error) {
      notification.error({ message: result.error });
    } else {
      notification.success({ message: t("notifications.91") });
      setInsights(
        insights.map((item) => {
          if (item.ID === insight.ID) {
            item.insightTitle = insightTitleTxt;
            item.insight = insightTxt;
          }
          return item;
        })
      );
    }
    setEditModal(false);
  };

  const reactInsight = async (reaction) => {
    if (reaction === insight.like) reaction = null;
    const result = await apiRequest(
      "dashboards/insights/like",
      "POST",
      { insight_id: insight.ID, reaction },
      true
    );
    if (result.error) {
      notification.error({ message: result.error });
    } else {
      setInsights(
        insights.map((item) => {
          if (item.ID === insight.ID) {
            item.like = reaction;
          }
          return item;
        })
      );
    }
  };
  const platformNamesAndIdsMap = {
    Twitter: "1",
    "Google Play": "2",
    "App Store": "4",
    "Şikayet Var": "5",
    "Instagram Hashtags": "6",
    "Facebook Page": "9",
    "Facebook and Instagram ads": "10",
    Trendyol: "14",
    Amazon: "17",
    Reddit: "18",
    "Ekşi Sözlük": "19",
    "Instagram Account Posts": "27",
    TikTok: "29",
    "Papara Topluluk": "31",
    "LinkedIn Posts": "32",
    ACS: "33",
    Otelpuan: "34",
    Anket: "35",
  };
  const navigateTo = () => {
    let dataOverview = true;
    let topic_type = "ai";

    if (insight.searchState) {
      const { topic_id, ...payloadState } = insight.searchState;

      if (insight.searchState?.topic_type) {
        topic_type = insight.searchState?.topic_type;
      }

      if (topic_id) {
        dataOverview = false;
      }

      const {
        chosenCustomTopic,
        chosenDate,
        chosenDocRating,
        chosenFetchType,
        chosenHotTerm,
        chosenIntent,
        chosenKeywords,
        chosenNPS,
        chosenPlatformName,
        chosenRestaurantName,
        chosenSentiment,
        chosenTopTopic,
        dateRange,
        end_date,
        selectedDaysRange,
        showNewReviewsLabel,
        sortByDate,
        start_date,
        status,
        topicsOperator,
        pivotFilter,
        pivots_operator,
        selectedL1Topic,
        selectedL2Topic,
        decisionFilter,
        child_others,
        parent_others,
      } = insight.searchState;
      const selectedTpcs = {};
      for (let level = 1; level >= 1; level++) {
        if (insight.searchState.hasOwnProperty(`level${level}Selection`)) {
          params.delete(`level${level}Selection`);
          params.append(
            `level${level}Selection`,
            JSON.stringify(insight.searchState[`level${level}Selection`])
          );
        } else {
          break;
        }
      }
      if (chosenDate) {
        params.delete("match_date");
        params.append("match_date", chosenDate);
      }
      if (chosenDocRating) {
        params.delete("rating");
        params.append("rating", chosenDocRating);
      }
      if (chosenHotTerm) {
        params.delete("hotterm");
        params.append("hotterm", JSON.stringify(chosenHotTerm));
      }
      if (chosenIntent) {
        params.delete("intent");
        params.append("intent", chosenIntent);
      }
      if (chosenKeywords && chosenKeywords.length > 0) {
        params.delete("keyword");
        params.append("keyword", JSON.stringify(chosenKeywords));
      }
      if (chosenNPS) {
        params.delete("nps");
        params.append("nps", chosenNPS);
      }
      if (chosenPlatformName) {
        const platform_id = platformNamesAndIdsMap[chosenPlatformName];
        params.delete("platform_id");
        params.append("platform_id", platform_id);
      }
      if (chosenSentiment) {
        params.delete("sentiment");
        params.append("sentiment", chosenSentiment);
      }
      if (chosenTopTopic && chosenTopTopic.length > 0 && topicsOperator) {
        params.delete("top_topics");
        params.delete("topics_operator");
        if (child_others?.length > 0) {
          params.append("child_others", JSON.stringify(child_others));
        } else if (parent_others?.length > 0) {
          params.append("parent_others", JSON.stringify(parent_others));
        }
        params.append("top_topics", JSON.stringify(chosenTopTopic));
        params.append("topics_operator", topicsOperator);
      }
      if (dateRange && dateRange.length > 0) {
        params.delete("since");
        params.delete("until");
        params.append("since", dateRange[0]);
        params.append("until", dateRange[1]);
      }
      if (selectedDaysRange) {
        params.delete("daysRange");
        params.append(
          "daysRange",
          dateRange.length === 0 || dateRange == ""
            ? "allTime"
            : selectedDaysRange
        );
      }
      if (
        pivotFilter &&
        Object.keys(pivotFilter) &&
        Object.keys(pivotFilter).length > 0
      ) {
        params.delete("pivotFilter");
        params.delete("pivots_operator");
        params.append("pivotFilter", JSON.stringify(pivotFilter));
        params.append("pivots_operator", pivots_operator);
      }
      if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
        params.delete("decisionFilter");
        params.append("decisionFilter", JSON.stringify(decisionFilter));
      }
      dispatch({
        type: actions.SELECT_TOPIC,
        payload: {
          selected_topic: topic_id,
          insightBasedSearch: payloadState,
          trends: {},
        },
      });
    }

    if (!shared) {
      if (dataOverview) {
        history.push({
          pathname: `/console/DashboardData/${id}`,
          state: {
            searchInsight: true,
          },
          search: params.toString(),
        });
      } else {
        if (topic_type === "ai") {
          history.push({
            pathname: `/console/Dashboard/${id}`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        } else if (topic_type === "fixed") {
          history.push({
            pathname: `/console/industryTopics`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        } else if (topic_type === "custom") {
          params.delete("insightTopicSearch");
          params.append("insightTopicSearch", insight?.searchState?.topic_id);
          history.push({
            pathname: `/console/customTopics/${id}`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        }
      }
    } else {
      if (dataOverview) {
        history.push({
          pathname: "/share/DashboardData/" + currentDashboardHash,
          state: {
            searchInsight: true,
          },
          search: params.toString(),
        });
      } else {
        if (topic_type === "ai") {
          history.push({
            pathname: `/share/Dashboard/${currentDashboardHash}`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        } else if (topic_type === "fixed") {
          history.push({
            pathname: `/console/industryTopics`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        } else if (topic_type === "custom") {
          params.delete("insightTopicSearch");
          params.append("insightTopicSearch", insight?.searchState?.topic_id);
          history.push({
            pathname: `/console/customTopics/${id}`,
            state: {
              searchInsight: true,
              selected_topic: insight?.searchState?.topic_id,
            },
            search: params.toString(),
          });
        }
      }
    }
  };

  const deletePopulation = async () => {
    const payload = {
      insight_id: parseInt(insight.ID),
    };
    apiRequest(
      "dashboards/insights/delete_population",
      "post",
      payload,
      true
    ).then((result) => {
      if (!result.error) {
        notification.success({
          message: t("notifications.92"),
        });
        // setInitialPopulation(0);
        setPopulation(0);
        setSettingsModal(false);
        // window.location.reload();
      } else {
        notification.error({
          message: result.error,
        });
      }
    });
  };

  const handleInsightSettings = async () => {
    const payload = {
      insight_id: parseInt(insight.ID),
      population_size: parseInt(population),
    };
    apiRequest(
      "dashboards/insights/set_population",
      "post",
      payload,
      true
    ).then((result) => {
      if (!result.error) {
        notification.success({
          message: t("notifications.93"),
        });
        // setInitialPopulation(population);
        setSettingsModal(false);
        // window.location.reload();
      } else {
        notification.error({
          message: result.error,
        });
      }
    });
  };

  const buttons = () => {
    let buttonArr = [];

    // if(!shared) {
    buttonArr = [
      <DeleteOutlined
        key="delete"
        onClick={() => setDeleteModal(true)}
        style={{ color: "white", fontSize: "13px" }}
      />,
      <EditFilled
        key="edit"
        onClick={() => setEditModal(true)}
        style={{ color: "white", fontSize: "13px" }}
      />,
      <DislikeFilled
        style={{
          color: insight.like === 0 ? "red" : "white",
          fontSize: "13px",
        }}
        key="dislike"
        onClick={() => reactInsight(0)}
      />,
      <LikeFilled
        style={{
          color: insight.like === 1 ? "blue" : "white",
          fontSize: "13px",
        }}
        key="like"
        onClick={() => reactInsight(1)}
      />,
    ];
    // }

    if (insight.searchState) {
      buttonArr.push(
        <FunnelPlotOutlined
          onClick={() => navigateTo()}
          style={{ color: "white", fontSize: "13px" }}
        />
      );
    }
    return buttonArr;
  };

  return (
    <div className={className}>
      <Card
        style={{ width: "100%" }}
        title={getDateString(insight.date)}
        actions={buttons()}
        extra={
          !shared && (
            <>
              {insight.reporter === "1" ? (
                <span className={styles.userCreation}>
                  {shared ? t("insights_page.3") : t("insights_page.4")}
                </span>
              ) : (
                <span className={styles.pivonyCreation}>
                  {t("insights_page.2")}
                </span>
              )}
              <Button
                className={styles.settingsBtn}
                onClick={() => setSettingsModal(true)}
                icon={<SettingFilled />}
              />
            </>
          )
        }
      >
        {" "}
        {insight.insightTitle ? (
          <p className={styles.insightTitle}>{insight.insightTitle}</p>
        ) : (
          ""
        )}
        {insight.insightTitle ? (
          <p className={styles.insightTitleborder}></p>
        ) : (
          ""
        )}
        <p>{insight.insight}</p>
        {/* <div className={styles.details}>
          {
            insight.total_num_docs && <Tag icon={<i class="fa fa-users" aria-hidden="true"></i>} color="#6E43E8" style={{marginBottom: "5px"}}>
              &nbsp;&nbsp;{insight.total_num_docs}+ people were affected
            </Tag>
          }
          {
            insight.timeInterval && <Tag icon={<i class="fa fa-calendar" aria-hidden="true"></i>} color="#3C17A3">
              &nbsp;&nbsp;{moment(insight.timeInterval.start_date).format('ll')} - {moment(insight.timeInterval.end_date).format('ll')}
            </Tag>
          }
        </div> */}
        <div className={styles.details}>
          {insight.total_num_docs > 0 && (
            <div className={styles.detailsColumn}>
              <p className={styles.detailsTitle}>{t("insights_page.5")}</p>
              {population > 0 && insight?.dashboards_total_users ? (
                <div className={styles.outerCircle}>
                  <div>
                    <p className={styles.detailsNumber}>
                      {Math.round(
                        (insight.total_num_docs * population) /
                          insight.dashboards_total_users
                      )}
                    </p>{" "}
                    {t("insights_page.6")}
                  </div>
                  <div className={styles.middleCircle}>
                    <div>
                      <p className={styles.detailsNumber}>
                        {insight.total_num_docs * 30}
                      </p>{" "}
                      {t("insights_page.7")}
                    </div>
                    <div className={styles.smallCircle}>
                      <div>
                        <p className={styles.detailsNumber}>
                          {insight.total_num_docs}
                        </p>{" "}
                        {t("insights_page.8")}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.detailsWarning}>
                    <WarningFilled
                      style={{ color: "orange", fontSize: "12px" }}
                    />
                    <p className={styles.detailsWarnText}>
                      {t("insights_page.9")}
                    </p>
                  </div>
                  <div className={styles.middleCircle}>
                    <p className={styles.detailsNumber}>
                      {insight.total_num_docs * 30}
                    </p>{" "}
                    {t("insights_page.7")}
                    <div className={styles.smallCircle}>
                      <p className={styles.detailsNumber}>
                        {insight.total_num_docs}
                      </p>{" "}
                      {t("insights_page.8")}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {insight.trends !== null && insight.trends.length > 0 && (
            <div className={styles.detailsColumn}>
              <p className={styles.detailsTitle}>{t("insights_page.10")}</p>
              <LineChartWrapper
                id="Time-based Insight Participation"
                SentimentDaily={insight.trends || []}
                height="180px"
                hideLegend
              />
            </div>
          )}
        </div>
      </Card>
      <Modal
        showModal={deleteModal}
        onClose={() => setDeleteModal(false)}
        onSave={deleteInsight}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        titleAsset=""
        textonButton={t("button.2")}
        title={t("insights_page.12")}
        widthofmodal="450px"
        isDeleteModal={"true"}
      >
        <p className={styles.deletetext}>{t("insights_page.11")}</p>
      </Modal>
      <Modal
        showModal={editModal}
        onClose={() => {
          setEditModal(false);
          setInsightTxt(insight.insight);
          setinsightTitleTxt(insight.insightTitle);
        }}
        onSave={editInsight}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        titleAsset=""
        textonButton={t("button.15")}
        title={
          <span className={styles.insightTitle}>
            {t("selected_dashboard_page.65")}{" "}
            <img
              className={styles.message_img}
              src={
                theme === "dark"
                  ? "/assets/info1.svg"
                  : "/assets/info1_black.svg"
              }
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              onClick={() => {
                const win = window.open(
                  "https://pivony.notion.site/Insight-Creation-160faa1a90cc4191baf11fb62c88d808",
                  "_blank"
                );
                win.focus();
              }}
            />
          </span>
        }
        widthofmodal="450px"
      >
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "2px",
              marginBottom: "7px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("insights_page.14")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setinsightTitleTxt(e.target.value)}
            value={insightTitleTxt}
          />
        </div>
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "130px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("insights_page.15")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setInsightTxt(e.target.value)}
            value={insightTxt}
          />
        </div>
      </Modal>
      {!shared && (
        <Modal
          showModal={settingsModal}
          title={t("insights_page.16")}
          titleAsset=""
          onClose={() => {
            setSettingsModal(false);
          }}
          width="450px"
          showCancelButton={true}
          showSaveButton={true}
          onSave={deletePopulation}
          onDiscard={handleInsightSettings}
          textonButton={t("button.2")}
          cancelButtonText={`${t("button.30")} Population`}
          isDeleteModal={true}
        >
          <div className={styles.row}>
            <div className={styles.text}>
              <p className={styles.label}>{t("insights_page.17")}</p>
              <p className={styles.description}>{t("insights_page.18")}</p>
              <p
                style={{
                  color: theme === "dark" ? "#d9d9d9" : "#111111",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                ⚠️ {t("insights_page.1")}
              </p>
            </div>
            <CustomInput
              type="number"
              onChange={setPopulation}
              style={{ maxWidth: "150px", marginRight: "20px" }}
              value={population}
              onOk={() => {
                handleInsightSettings();
                setSettingsModal(false);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InsightsCard;

import React from "react";
import styles from "./style.module.scss";
import i18n from 'i18next';

const BoardError = ({ failureMsg, children, title }) => {
  return (
    <div className={styles.errorMainWindow}>
      <div className={styles.msg}>
        <h4>{title || i18n.t("notifications.137")}</h4>
        <h5>{failureMsg}</h5>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default BoardError;

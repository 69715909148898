import React, { useState, useEffect } from "react";
import styles from "./followListItem.module.scss";
import { notification, Menu, Dropdown, Button, Input, Select } from "antd";
import { apiRequest } from "util/services";
import { Modal } from "components/General/ModalFormComponents";

import { useDispatch, useSelector } from "react-redux";

const MoreOptions = function({ setShowFollowModal }) {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <Menu className={styles.customMenu} theme="dark">
      <Menu.Item
        className={styles.customMenuItem}
        key={1}
        onClick={() => {
          setShowFollowModal(true);
        }}
      >
        <img
          className={styles.more}
          width={20}
          src={
            theme === "dark"
              ? "/assets/follow_filled.svg"
              : "/assets/follow_filled_black.svg"
          }
          alt=""
        />
        Unfollow
      </Menu.Item>
    </Menu>
  );
};

const FollowTopic = () => {
  return (
    <div className={styles.followModal}>
      <div className={styles.description}>
        You will no longer get daily notifications from this topic. This may
        cause to miss the important information on time
      </div>
    </div>
  );
};

const FollowListItem = (props) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [showFollowModal, setShowFollowModal] = useState(false);

  const { item, fetchList } = props;

  const UnFollowTopic = async (id) => {
    try {
      const result = await apiRequest("topic_tracker/delete", "POST", {
        topic_id: id,
      });

      if (!result.error) {
        notification.success({
          message: "Unfollowed Topic Successfully",
        });
        fetchList();
        setShowFollowModal(false);
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.listItem}>
      <div className={styles.item}>{item.topic_name}</div>
      <div className={styles.item}>{item.dashboard_name}</div>
      <div className={styles.more}>
        <Dropdown
          overlay={() => (
            <MoreOptions setShowFollowModal={setShowFollowModal} />
          )}
          placement="left"
        >
          <img
            className={styles.more}
            src={
              theme === "dark"
                ? "/assets/more-vertical.svg"
                : "/assets/more-vertical-black.svg"
            }
            alt=""
          />
        </Dropdown>
      </div>
      <Modal
        showModal={showFollowModal}
        onClose={() => setShowFollowModal(false)}
        onSave={() => {
          UnFollowTopic(item.topic_id);
        }}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        description={"Are you sure that you want to unfollow this Topic?"}
        textonButton={"Unfollow"}
        titleAsset=""
        title={"Unfollow Topic"}
        widthofmodal="550px"
      >
        <FollowTopic />
      </Modal>
    </div>
  );
};

export default FollowListItem;

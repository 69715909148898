import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { apiRequest } from "util/services";
import { useSelector } from "react-redux";
import { PlatformStatus } from "helperComponents/Status";
import { useTranslation, Trans } from "react-i18next";
import {
  InfoCircleOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, notification, Tooltip } from "antd";
import { Modal } from "components/General/ModalFormComponents";
import { beautifyNumber } from "util/common";
const SavedSearchItem = ({
  item,
  header,
  dashboardId,
  getAllSavedSearches,
}) => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const params = new URLSearchParams(window.location.search);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const query = item?.search_query || {};
  const platformIdsANdNamesMap = {
    "1": t("feedback_dropdown_values.14"),
    "2": t("feedback_dropdown_values.15"),
    "4": t("feedback_dropdown_values.17"),
    "5": t("feedback_dropdown_values.18"),
    "6": "Instagram Hashtags",
    "9": t("feedback_dropdown_values.21"),
    "10": t("feedback_dropdown_values.22"),
    "14": t("feedback_dropdown_values.26"),
    "17": t("feedback_dropdown_values.29"),
    "18": t("feedback_dropdown_values.30"),
    "19": t("feedback_dropdown_values.31"),
    "27": t("feedback_dropdown_values.36"),
    "29": t("feedback_dropdown_values.38"),
    "31": t("feedback_dropdown_values.40"),
    "32": t("feedback_dropdown_values.41"),
    "33": t("feedback_dropdown_values.42"),
    "34": t("feedback_dropdown_values.43"),
    "35": t("feedback_dropdown_values.44"),
  };
  const overallIntentOptions = [
    {
      id: "33",
      value: "33",
      label: t("feedback_dropdown_values.13"),
      img: "/assets/mixed_intents.svg",
    },
    {
      id: "1",
      value: "1",
      label: t("feedback_dropdown_values.5"),
      img: "/assets/Appraisal.svg",
    },
    {
      id: "2",
      value: "2",
      label: t("feedback_dropdown_values.6"),
      img: "/assets/Complaint.svg",
    },
    {
      id: "3",
      value: "3",
      label: t("feedback_dropdown_values.7"),
      img: "/assets/Suggestion.svg",
    },
    {
      id: "4",
      value: "4",
      label: t("feedback_dropdown_values.8"),
      img: "/assets/Remark.svg",
    },
    {
      id: "5",
      value: "5",
      label: t("feedback_dropdown_values.9"),
      img: "/assets/Marketing & Spam.svg",
    },
    {
      id: "6",
      value: "6",
      label: t("feedback_dropdown_values.10"),
      img: "/assets/Information.svg",
    },
    {
      id: "7",
      value: "7",
      label: t("feedback_dropdown_values.11"),
      img: "/assets/Request.svg",
    },
    {
      id: "8",
      value: "8",
      label: t("feedback_dropdown_values.12"),
      img: "/assets/Comparison.svg",
    },
  ];
  const overallSentimentOptions = [
    {
      id: "33",
      value: "33",
      label: t("feedback_dropdown_values.13"),
      img: "/assets/mixed_sentiments.svg",
    },
    {
      id: "1.0",
      value: "1.0",
      label: t("feedback_dropdown_values.2"),
      img: "/assets/Positive.svg",
    },
    {
      id: "0.5",
      value: "0.5",
      label: t("feedback_dropdown_values.3"),
      img: "/assets/Neutral.svg",
    },
    {
      id: "0.0",
      value: "0.0",
      label: t("feedback_dropdown_values.4"),
      img: "/assets/Highly_negative.svg",
    },
  ];

  const searchItemClick = () => {
    if (item.status === 0) {
      return;
    }
    const newTab = window.open();
    const filterKeys = Object.keys(query);
    [
      "sentiment",
      "intent",
      "since",
      "until",
      "daysRange",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "decisionFilter",
      "cursor_next",
      "cursor_curr",
      "cursor_before",
      "savedSearchID",
      "child_others",
      "parent_others",
    ].forEach((item) => params.delete(item));
    filterKeys.forEach((key) => {
      if (
        key === "keyword" ||
        key === "pivotFilter" ||
        key === "top_topics" ||
        key === "level1Selection" ||
        key === "level2Selection" ||
        key === "decisionFilter" ||
        key === "child_others" ||
        key === "parent_others" ||
        key === "hotterm"
      ) {
        params.append(key, JSON.stringify(query[key]));
      } else {
        params.append(key, query[key]);
      }
    });
    params.append("savedSearchID", item.ID);
    params.append("savedSearchName", item.search_name);
    params.append("start_index", 0);
    params.append("page_offset", 10);
    if (query.since && query.until) {
      params.append("daysRange", "custom");
    } else {
      params.append("daysRange", "allTime");
    }
    newTab.location.href = `/console/DashboardData/${dashboardId}?${params.toString()}`;
  };
  const menu = (
    <Menu className={styles.customMenu} theme="dark">
      <Menu.Item
        key={1}
        onClick={() => setDeleteModalOpen(true)}
        icon={
          <DeleteOutlined
            style={{
              color: theme === "dark" ? "white" : "black",
              width: "22px",
              height: "22px",
              fontSize: "22px",
              fontWeight: "600",
            }}
          />
        }
      >
        {t("button.2")}
      </Menu.Item>
    </Menu>
  );
  const handleDeleteSavedSearch = async () => {
    if (item.status !== 1) {
      notification.error({
        message: "Cannot delete an 'In Progress' saved view",
      });
      return;
    }
    setDeletionLoading(true);
    const result = await apiRequest(
      `dashboards/saved-searches/delete`,
      "POST",
      { id: parseInt(item.ID, 10) },
      true
    );
    if (result.success) {
      notification.success({
        message: "Deleted saved view successfully.",
      });
      getAllSavedSearches();
    } else {
      notification.error({
        message: result.error ? result.error : "Unable to delete saved view!",
      });
    }
    setDeletionLoading(false);
    setDeleteModalOpen(false);
  };
  return (
    <div className={`${styles.container} ${header && styles.header}`}>
      <div className={styles.firstColumn}>
        <span className={styles.title}>
          {header ? (
            t("selected_dashboard_page.125")
          ) : (
            <span
              className={styles.searchTitle}
              style={
                item.status === 0
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
              onClick={searchItemClick}
            >
              {item.search_name}{" "}
              <img
                src={
                  theme === "dark"
                    ? "/assets/arrow-up-right.svg"
                    : "/assets/arrow-up-right_black.svg"
                }
                alt=""
                width={18}
                height={18}
              />
            </span>
          )}
        </span>
      </div>
      <div className={`${styles.column} ${styles.createdBy}`}>
        {header ? t("selected_dashboard_page.126") : item.user_email}
      </div>
      <div className={`${styles.column} ${styles.creationDate}`}>
        {header ? (
          t("selected_dashboard_page.127")
        ) : (
          <span className={styles.dateCol}>
            <span>{item.created_at?.split("T")[0]}</span>
            <span>
              {item.created_at
                ?.split("T")[1]
                ?.split(".")[0]
                ?.substr(0, 5)}
            </span>
          </span>
        )}
      </div>
      <div className={`${styles.column} ${styles.statusCol}`}>
        {header ? (
          t("all_dashboards_page.5")
        ) : (
          <PlatformStatus status={item.status === 1 ? "Ready" : "Progress"} />
        )}
      </div>
      <div
        className={`${styles.column} ${styles.actions} ${styles.detailsInfo}`}
      >
        {header ? (
          t("selected_dashboard_page.128")
        ) : (
          <span
            className={styles.actionsIconWrapper}
            onClick={() => setShowDetails(!showDetails)}
          >
            <InfoCircleOutlined
              style={{
                color: theme === "dark" ? "white" : "black",
                width: "22px",
                height: "22px",
                fontSize: "22px",
                fontWeight: "600",
                cursor: "pointer",
              }}
            />
          </span>
        )}
      </div>
      <div className={`${styles.column} ${styles.actions}`}>
        {header ? (
          t("selected_dashboard_page.129")
        ) : (
          <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
            <span className={styles.actionsIconWrapper}>
              <SettingOutlined
                style={{
                  color: theme === "dark" ? "white" : "black",
                  width: "22px",
                  height: "22px",
                  fontSize: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              />
            </span>
          </Dropdown>
        )}
      </div>
      {deleteModalOpen && (
        <Modal
          showModal={deleteModalOpen}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
          hideCloseIcon={true}
          onSave={handleDeleteSavedSearch}
          loadingSaveClick={deletionLoading}
          showSaveButton={true}
          showCancelButton={true}
          textonButton={t("button.2")}
          titleAsset=""
          title={t("selected_dashboard_page.147")}
          isDeleteModal={true}
          widthofmodal="400px"
        >
          <span>
            <Trans
              i18nKey="selected_dashboard_page.146"
              t={t}
              values={{ searchName: item.search_name }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </span>
        </Modal>
      )}
      {showDetails && (
        <Modal
          showModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          hideCloseIcon={false}
          showSaveButton={false}
          showCancelButton={false}
          titleAsset=""
          title={"Details"}
          widthofmodal="380px"
        >
          <div className={styles.detailsWrapper}>
            <div className={styles.dateFilter}>
              <span className={styles.title}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/calendar.svg"
                      : "/assets/calendar_black.svg"
                  }
                  alt=""
                  width={20}
                  height={20}
                />{" "}
                {"Date Range"}
              </span>
              <span className={styles.dataContent}>
                {query.since && query.until
                  ? `${query.since} to ${query.until}`
                  : "All Time"}
              </span>
            </div>
            {item.status === 1 && (
              <div className={styles.dataCollected}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/database.svg"
                        : "/assets/database_black.svg"
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Data Collected"}
                </span>
                <span className={styles.dataContent}>
                  <Tooltip title={beautifyNumber(item.total_docs, ",")}>
                    {beautifyNumber(item.total_docs)}
                  </Tooltip>
                </span>
              </div>
            )}
            {query.sentiment && (
              <div className={styles.sentiment}>
                <span className={styles.title}>
                  <img
                    src={
                      overallSentimentOptions.find(
                        (item) => item.id === query.sentiment
                      ).img
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Sentiment"}
                </span>
                <span className={styles.dataContent}>
                  {
                    overallSentimentOptions.find(
                      (item) => item.id === query.sentiment
                    )?.label
                  }
                </span>
              </div>
            )}
            {query.intent && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src={
                      overallIntentOptions.find(
                        (item) => item.id === query.intent
                      )?.img
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Intent"}
                </span>
                <span className={styles.dataContent}>
                  {
                    overallIntentOptions.find(
                      (item) => item.id === query.intent
                    )?.label
                  }
                </span>
              </div>
            )}
            {query.publish && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src="/assets/mPublish.svg"
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Publish"}
                </span>
                <span className={styles.dataContent}>{query.publish}</span>
              </div>
            )}
            {query.opencase && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src="/assets/mOpenCase.svg"
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Open Case"}
                </span>
                <span className={styles.dataContent}>{query.opencase}</span>
              </div>
            )}
            {query.takeaction && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src="/assets/mAction.svg"
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Take Action"}
                </span>
                <span className={styles.dataContent}>{query.takeaction}</span>
              </div>
            )}
            {query.rating && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/nps_scale.svg"
                        : "/assets/nps_scale_black.svg"
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Rating"}
                </span>
                <span className={styles.dataContent}>{query.rating}</span>
              </div>
            )}
            {query.platform_id && (
              <div className={styles.intent}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/platforms.svg"
                        : "/assets/platforms_black.svg"
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Platform"}
                </span>
                <span className={styles.dataContent}>
                  {platformIdsANdNamesMap[query.platform_id]}
                </span>
              </div>
            )}
            {query.top_topics?.length > 0 && (
              <div className={styles.topTopicsFilter}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/custom_words.svg"
                        : "/assets/custom_words_black.svg"
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Top Topics"}
                </span>
                <span>
                  {query.top_topics
                    .map((item) => {
                      const parent = query.top_topics.find(
                        (i) => i?.id === item?.parent_id
                      );
                      return parent
                        ? `${parent.label} > ${item.label}`
                        : item.label;
                    })
                    .join(` ${query.topics_operator || "AND"} `)}
                </span>
              </div>
            )}
            {query.pivotFilter && (
              <div className={styles.pivotFilter}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/pivot.svg"
                        : "/assets/pivot_black.svg"
                    }
                    width={20}
                    height={20}
                    alt=""
                  />{" "}
                  {"Pivot Columns"}
                </span>
                {Object.entries(query.pivotFilter)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(` ${query.pivots_operator || "AND"} `)}
              </div>
            )}
            {query.keyword?.length > 0 && (
              <div className={styles.pivotFilter}>
                <span className={styles.title}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/search.svg"
                        : "/assets/search_black.svg"
                    }
                    alt=""
                    width={20}
                    height={20}
                  />{" "}
                  {"Search Keywords"}
                </span>
                <span className={styles.dataContent}>
                  {query.keyword
                    .map((keyword) => `${keyword.label}`)
                    .join(" AND ")}
                </span>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SavedSearchItem;

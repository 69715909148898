import config from "Configurations";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { USER } from "util/constants";
import styles from "./styles.module.scss";
import CreationItem from "components/NewDashboard/CreationItem";
import {
  Radio,
  Input,
  DatePicker,
  Select,
  Spin,
  Button,
  notification,
} from "antd";
import ReactFileReader from "react-file-reader";
import { apiRequest, uploadFileToS3 } from "util/services";
import { useDropzone } from "react-dropzone";
import { Line, Circle } from "rc-progress";
import { none } from "ol/centerconstraint";
import { CompassOutlined } from "@ant-design/icons";
import Loading from "helperComponents/Loading";

function AppSumoBlackFriday() {
  const [pageIndex, setPageIndex] = useState(null);
  const [s3Link, setS3Link] = useState('');
  const [uploadFilename, setUploadFilename] = useState('');
  const [refresh,setRefresh] = useState(false);
  const [stoprefresh,setStopRefresh] = useState(null);

  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [userItems, setUserItems] = useState(null);
  const [loading,setLoading] = useState(true);


  useEffect(() => {
    getItems();
  },[]);

  useEffect(() => {
    setUserItems(userItems);
    setLoading(false);
    setStopRefresh(true);
  },[userItems]);
  
  const getItems = async () => {
      const result = await apiRequest(
        "sentiment_intent_analyzer/files",
        "POST",
        true
      )
    if (result){
      if (result.success) {
        setUserItems(result.success);
        if (result.success.length === 0){
          setPageIndex(1)
        }else{
          setPageIndex(0)
        }
      }
    }
  };

  const downloadData = async(fileId) =>{
    const payload = {file_id : fileId}
    const result = await apiRequest(
      "sentiment_intent_analyzer/analysis_result",
      "POST",
      payload,
      true
    )
    const el = document.createElement("a");
    el.href = result["success"];
    // el.setAttribute("download", "test"); // custom filename
    el.click();

  }

  const handleDelete = async (fileId) => {
    const payload = {file_id: fileId}
    const result = await apiRequest(
      "sentiment_intent_analyzer/delete",
      "POST",
      payload,
      true
    )
  if (result){
    if (result.success) {
      const new_arr = userItems.filter(item => item.file_id !== fileId)
      setUserItems(new_arr);
      if (new_arr.length === 0){
        setPageIndex(1)
      }
    }
  }
};

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ".txt",
  });

  useEffect(() => {
    if ((acceptedFiles || []).length > 0) {
      setPageIndex(2);

      // start upload
      handleFiles(acceptedFiles);

    }
  }, [acceptedFiles]);

  const handleFiles = async (files) => {
    const user = firebase.auth().currentUser;
    if (!files[0].path.includes(".txt")) {
      notification.error({
        message: "The file should be a .txt file",
      });
      return;
    }

    setUploading(true);

    let urlResponse = await apiRequest("sentiment_intent_analyzer/bucket", "POST", {
      user_id: user.uid,
      filename: files[0].name,
    });
    if (urlResponse) {
      const result = await uploadFileToS3(urlResponse, files[0], setProgress);
      if (result) {
        const s3link = urlResponse.fields.key;
        const filename = files[0].name;

        setS3Link(s3link);
        setUploadFilename(filename);
      } else {
        setPageIndex(1);
        notification.error({
          message: "Couldn't upload the file please try again later",
        });
      }
    } else {
        setPageIndex(1);
        notification.error({
        message: "An error occured while creating the dashboard",
      });
    }
    setUploading(false);
  };

  const startAnalysis = async () => {
    const user = firebase.auth().currentUser;

    const urlResponse = await apiRequest(
      "sentiment_intent_analyzer/analyze",
      "POST",
      {
        firebase_id: user.uid,
        s3link: s3Link,
        filename: uploadFilename,
      },
      true
    );

    if (urlResponse.success) {
      notification.success({
        message: "Analysis started successfully",
      });
      getItems();
      setPageIndex(0);

    } else {
      notification.error({
        message: urlResponse.error,
      });
      setPageIndex(1);
    }
  }
  const handleCancel = () => {
    setPageIndex(1);
  };

  const getPlatformStatus = (status) => {
    if (status === "Error")
      return { icon: "fa fa-info", color: "#EB3B34", text: status };
    if (status === "Ready")
      return { icon: "fa fa-check-circle", color: "#81C568", text: status };
    if (status === "In Progress" || status?.toLowerCase().includes("progress"))
      return { icon: "fa fa-hourglass", color: "#faad14", text: status + " (5 min max)" };
    return { text: status };
  };

  return (
    <div className={styles.container}>

<div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
                <div
          className={styles.title}
          style={{ width: "100%", maxWidth: 400}}
        >
      <h1 style={{ textAlign: "center" }}>
        AI Provided Sentiment and Intent Analysis from{" "}
        <span style={{ color: "#81C568" }}>your</span> customer file{" "}
      </h1>
      </div>

        </div>
      {pageIndex === 1 ? (
        <section
          className="container"
          style={{ border: "1px dashed #ccc", padding: 30, cursor: "pointer",marginTop:"40px" }}
        >
          <div
            style={{
              margin: "30px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <div
              className={styles.dragdropplus}
              style={{
                border: "2px dashed #81C568",
                padding: 15,
                borderRadius: "50%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#81C568",
                  width: 200,
                  height: 200,
                  borderRadius: "50%",
                  color: "#fff",
                  fontSize: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i className="fas fa-plus" />
              </div>
            </div>
            <div style={{ marginTop: 60, fontSize: 15, color: "#999" }}>
              Drag and drop your file here, or click to select your file
            </div>
            <div
              style={{
                marginTop: 5,
                fontSize: 14,
                color: "#999",
                maxWidth: 500,
              }}
            >
              File extension should be{" "}
              <span style={{ color: "#EB3B34" }}>.txt</span>  containing the
              texts to be analyzed. Note that we only support{" "}
              <span style={{ color: "#EB3B34" }}>english</span> language.
            </div>
          </div>
        </section>
      ) : pageIndex === 2 ? (
        <section className="container">
          <h4 style={{ marginBottom: 30 }}>
            {progress !== 100 ? (
              <>Your file is uploading...</>
            ) : (
              <>Your file is ready for analysis!</>
            )}
          </h4>
          <div
            style={{ maxWidth: 260, position: "relative", margin: "0 auto" }}
          >
            <Circle percent={progress} strokeWidth="1" strokeColor="#81C568" />
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: 32,
                color: "#81C568",
              }}
            >
              {progress} %
            </div>
          </div>
          <div style={{ margin: "30px 0", justifyContent:"center"}}>
            File: {((acceptedFiles || [{}])[0] || {}).path}
            <button onClick={handleCancel} style={{border:"None",marginLeft:"10px",textOverflow:"ellipsis",overflow:"hidden"}}><i className="fa fa-times" /></button>
            <br />
          </div>
          <Button
            style={{
              backgroundColor: "#81C568",
              border: 0,
              borderRadius: 8,
              height: 50,
              width: 200,
              fontSize: 16,
            }}
            disabled={progress !== 100}
            onClick={startAnalysis}
          >
            {progress !== 100 ? (
              <>Waiting for upload</>
            ) : (
              <>Start analyzing</>
            )}
          </Button>
        </section>
      ) : pageIndex === 0  ? (
        <section className="container">
            <div>
            <div  style={{
              display: "flex",
              alignItems: "center",
              justifyContent : "space-between",
              paddingTop: "30px",
            }}>
            <h5>Uploaded Documents</h5>

            <div style={{display:"flex",flexDirection:"inherit"}}>
            <button  onClick={() => {setStopRefresh(false);setRefresh(true);getItems()}} loading style = {{padding:"10px",size:"14px",borderRadius:"6px",border:"None",marginRight:"5px"}}>
            {refresh === true && stoprefresh === false ? <i className="fas fa-refresh fa-spin" /> : <i className="fas fa-refresh" />}
            </button>
            <button onClick={() => {setPageIndex(1)}} style = {{backgroundColor:"rgb(129, 197, 104)",padding:"10px",size:"14px",borderRadius:"6px",border:"None"}}>
            
            <i className="fas fa-download" /> Upload file
            </button>
            
            </div>
            </div>
        <table
            className="table table-striped table-bordered"
            style={{ marginTop: 30}}
          >
            <thead>
              <tr>
                <th scope="col">File name</th>
                <th scope="col"># of lines</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {userItems.map((item) => {
                const status = getPlatformStatus(item.status);
                return (
                  <tr>
                    <td style={{textOverflow:"ellipsis",overflow:"hidden",maxWidth:"20px"}}>{item.name}</td>
                    <td>{item.numdocs} lines</td>
                    <td style={{ color: status.color }}>
                      <i style={{ marginRight: 8 }} className={status.icon} />
                      {status.text}
                    </td>
                    <td>
                      <Button
                        style={{ marginRight: 8 ,borderRadius:"6px",border:"none",backgroundColor:"#81c568"}}
                        disabled={status.text !== "Ready"}
                        type="primary"
                        onClick={() => {downloadData(item.file_id)}}
                      >
                        <i className="fas fa-download" />
                      </Button>
                      <Button
                        style={{marginRight: 8 ,borderRadius:"6px",border:"none",backgroundColor:"#b43636"}}
                        disabled={(status.text !== "Ready" && status.text !== "Error")}
                        type="primary"
                        onClick={() => {handleDelete(item.file_id)}}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
        </section>
      ) : pageIndex === 3 ? (
        <section className="container"></section>
      ) : (
        <section className="container">
          <Spin spinning={loading} delay={500}></Spin>
        </section>
      )}
    </div>
  );
}

export default AppSumoBlackFriday;

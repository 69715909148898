import React from "react";
import { Button, Menu } from 'antd';
import {
  CloseOutlined,
  MenuOutlined,
  CalendarOutlined,
  ClusterOutlined,
  ControlOutlined,
  LockOutlined,
  UserOutlined,
  TeamOutlined,
  DatabaseOutlined,
  HomeOutlined
} from '@ant-design/icons';
import styles from "./style.module.scss";
import { useTranslation } from 'react-i18next';

const ButtonStyle = (collapse) => ({
  // left: collapse ? "0px" : "185px",
})

const MenuStyle = (collapse, mobileView) => ({
  display: "block",
  paddingTop: !mobileView && "30px",
  width: collapse ? "0px" : mobileView ? "185px" : "220px",
  transition: "all 0.3s ease-in",
  overflow: "hidden"
})

const Representation = ({ toggleCollapsed, mobileView, collapse, isAdmin, plan, options, selected, onChange, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Menu
        onClick={(elem) => onChange(elem.key)}
        defaultSelectedKeys={[selected || "details"]}
        mode="inline"
        theme="dark"
        style={MenuStyle(collapse, mobileView)}
        className={props.theme === 'dark' ? "" : props.theme === 'light' ? "setting-light-sidebar" : "setting-brand-sidebar"}
      >
        <Menu.Item key={"home"} icon={<HomeOutlined />}>
          {t("settings_sidebar.8")}
        </Menu.Item>
        <Menu.Item key={"details"} icon={<UserOutlined />}>
          {t("settings_sidebar.1")}
        </Menu.Item>
        <Menu.Item key={"security"} icon={<LockOutlined />}>
          {t("settings_sidebar.2")}
        </Menu.Item>
        {
          plan === 68 ? (
            <Menu.Item key={"plan"} icon={<CalendarOutlined />}>
              {t("settings_sidebar.5")}
            </Menu.Item>
          ) : (
            <>
              <Menu.Item key={"preferences"} icon={<ControlOutlined />}>
                {t("settings_sidebar.3")}
              </Menu.Item>
              <Menu.Item key={"integrations"} icon={<ClusterOutlined />}>
                {t("settings_sidebar.4")}
              </Menu.Item>
            </>
          )
        }
        {(isAdmin && plan !== 68) ? (
          <>
            <Menu.Item key={"plan"} icon={<CalendarOutlined />}>
              {t("settings_sidebar.5")}
            </Menu.Item>
            <Menu.Item key={"teams"} icon={<DatabaseOutlined />}>
              {t("settings_sidebar.6")}
            </Menu.Item>
            <Menu.Item key={"users"} icon={<TeamOutlined />}>
              {t("settings_sidebar.7")}
            </Menu.Item>
          </>) : <></>
        }
      </Menu>
      <Button className={styles.collapseBtn} style={ButtonStyle(collapse)} onClick={toggleCollapsed}>
        {collapse ? <MenuOutlined /> : <CloseOutlined />}
      </Button>
    </div>
  )
}

export default Representation;
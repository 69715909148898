import React, { useState, useEffect } from "react";
import styles from "./AudienceFooter.module.scss";
import { Button, Input, notification, Select, Alert } from "antd";
import { apiRequest } from "../../../util/services";
import { Modal } from "components/General/ModalFormComponents";
import { useTranslation } from "react-i18next";

const AudienceFooter = ({
  usersCheckList,
  setUsersCheckList,
  setTweetsCheckList,
  user,
}) => {
  const [newAudienceModalOpen, setNewAudienceModalOpen] = useState(false);
  const [addToAudienceModalOpen, setAddToAudienceModalOpen] = useState(false);
  const [audience, setAudience] = useState([]);
  const [target, setTarget] = useState("");
  const [currentAudience, setCurrentAudience] = useState("");
  const usersLength = Object.keys(usersCheckList).length;
  const opacity = usersLength === 0 ? 0 : 1;
  const { t } = useTranslation();

  const handleAddAudience = async () => {
    if (!currentAudience) {
      notification.error({
        message: t("notifications.33"),
      });
      return;
    }

    const result = await apiRequest("audiences/create", "POST", {
      user_id: user.uid,
      name: currentAudience,
    });
    if (result) {
      loadAudience(currentAudience);
    } else {
      notification.error({
        message: t("notifications.32"),
      });
    }
    setTweetsCheckList({});
    setUsersCheckList({});
    setNewAudienceModalOpen(false);
  };

  const loadAudience = async (currentAudience) => {
    const result = await apiRequest("audiences/all", "POST", {
      user_id: user.uid,
    });
    if (result) {
      setAudience(result);
      if (currentAudience) {
        let currTarget;
        result.forEach((aud) => {
          if (aud.Name === currentAudience) currTarget = aud.ID;
        });
        if (currTarget) {
          setTarget(currTarget);
          addCheckedToAudience(currTarget);
        }
      }
    }
    // else {
    //   notification.error({ message: "Action not allowed" });
    // }
  };

  const addCheckedToAudience = async (currTarget) => {
    let user_ids = [];
    let user_emails = [];
    let user_phones = [];
    Object.keys(usersCheckList).forEach((key) => {
      if (usersCheckList[key].gpid) user_ids.push(usersCheckList[key].gpid);
      if (usersCheckList[key].DocEmail)
        user_emails.push(usersCheckList[key].DocEmail);
      if (usersCheckList[key].DocPhone)
        user_phones.push(usersCheckList[key].DocPhone);
    });
    let audience_id = currTarget ? currTarget : target;
    const result = await apiRequest(
      "audiences/users/add",
      "POST",
      {
        firebase_id: user.uid,
        audience_id,
        user_ids,
        user_phones,
        user_emails,
      },
      true
    );
    if (!result?.error) {
      notification.success({
        message: t("topic_library.22"),
      });
    } else {
      notification.error({
        message: result?.error,
      });
    }
    setUsersCheckList({});
    setTweetsCheckList({});
    setAddToAudienceModalOpen(false);
  };

  const keyPressed = (event, callback) => {
    if (event.key === "Enter") {
      callback();
    }
  };

  return (
    <div>
      <div className={styles.container} style={{ opacity }}>
        <h4>{usersLength} users selected</h4>
        <Button
          onClick={() => {
            loadAudience();
            setAddToAudienceModalOpen(true);
          }}
          type="primary"
        >
          <i className="fa fa-plus"></i>
        </Button>
      </div>
      <Modal
        title={t("engage.11")}
        showModal={newAudienceModalOpen}
        onSave={handleAddAudience}
        onClose={() => setNewAudienceModalOpen(false)}
        showSaveButton={true}
        showCancelButton={true}
        textonButton={t("button.15")}
        titleAsset=""
        widthofmodal="450px"
      >
        <Input
          placeholder={t("placeholder.10")}
          onKeyPress={(e) => keyPressed(e, handleAddAudience)}
          onChange={(e) => setCurrentAudience(e.target.value)}
        />
      </Modal>
      <Modal
        showModal={addToAudienceModalOpen}
        onClose={() => setAddToAudienceModalOpen(false)}
        onSave={() => addCheckedToAudience()}
        showSaveButton={true}
        textonButton={t("button.11")}
        titleAsset=""
        title="Add Users To Audience"
        widthofmodal="525px"
      >
        {
          <p
            style={{
              color: "white",
              fontSize: "15px",
              marginBottom: "20px",
              marginLeft: "20px",
            }}
          >
            {usersLength} users selected
          </p>
        }

        <div className={styles.audienceSelection}>
          <Select
            showSearch
            style={{
              width: "235px",
              marginBottom: "20px",
              marginRight: "20px",
              marginLeft: "20px",
              color: "black",
            }}
            optionFilterProp="children"
            onChange={setTarget}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={<div className={styles.on}>{t("placeholder.8")}</div>}
          >
            {audience.map((item) => {
              return <Select.Option value={item.ID}>{item.Name}</Select.Option>;
            })}
          </Select>
          <p className={styles.or}>OR</p>
          <div className={styles.con}>
            <Button
              type="primary"
              onClick={() => {
                setAddToAudienceModalOpen(false);
                setNewAudienceModalOpen(true);
              }}
            >
              {t("button.37")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AudienceFooter;

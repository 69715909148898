export const USER = "user";

export const UNKNOWN = "unknown";
export const UNLOGGED = "unlogged";
export const LOGGED = "logged";

export const countryList = [
    "United States of America",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo (the Democratic Republic of the)",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands [Malvinas]",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland",
    "United States Minor Outlying Islands",
    // "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
];

export const APP_MESSAGES = {
    ALL_DashboardS: {
        HEADER: "All Dashboards",
        SUBHEADER: "Find all your Dashboards at one place",
    },
    NEW_Dashboard: {
        HEADER: "New Dashboard",
        SUBHEADER:
            "The Dashboard you will create from this section will contain the key conversation topics so that you can find useful insights easily.",
    },
    AUDIENCE: {
        HEADER: "Audience",
        SUBHEADER: "After getting your insights, you can create user groups for targeting",
    },
    EMAIL_MARKETING: {
        HEADER: "Email Marketing",
        SUBHEADER: "You can target your audience here.",
    },
    DATA_OVERVIEW: {
        SUBHEADER: "Take a look at all the data.",
    },
    AI_TOPICS: {
        SUBHEADER: "Topics found by Pivony AI.",
    },
    FIXED_TOPICS: {
        SUBHEADER: "Industry-specific topics by Pivony.",
    },
    CUSTOM_TOPICS: {
        SUBHEADER: "You can create your User-defined topics.",
    },
    JOURNEY: {
        HEADER: "Journey",
        SUBHEADER:
            "Pivony AI Engine provides a fully-automated journey. Take your time, you will get notified when your Dashboard gets ready.",
    },
    SETTINGS: {
        HEADER: "Settings",
        SUBHEADER: "You can edit your settings here.",
    },
    PLAN: {
        HEADER: "My Plan",
        SUBHEADER: "View or upgrade your existing plan.",
    },
    INSIGHTS: {
        HEADER: "Insights",
        SUBHEADER:
            "Create sticky notes, add your insights to remember, and share. Additionally, Pivony experts are in your service to generate insights for you.",
    },
    MAP: {
        HEADER: "Geolocation Map",
        SUBHEADER:
            "See where people write reviews from",
    },
};

export const APP_MESSAGES_TUR = {
    ALL_DashboardS: {
        HEADER: "Tüm Dashboard'lar",
        SUBHEADER: "Tüm Panolarınızı tek bir yerde bulun",
    },
    NEW_Dashboard: {
        HEADER: "Yeni Dashboard",
        SUBHEADER:
            "Bu kısımdan oluşturacağınız Dashboard key'i içerecektir Yararlı içgörüleri kolayca bulabilmeniz için konuşma konuları.",
    },
    AUDIENCE: {
        HEADER: "Kitle",
        SUBHEADER: "İçgörülerinizi aldıktan sonra, hedefleme için kullanıcı grupları oluşturabilirsiniz.",
    },
    EMAIL_MARKETING: {
        HEADER: "Email Pazarlama",
        SUBHEADER: "Kitlenizi burada hedefleyebilirsiniz.",
    },
    DATA_OVERVIEW: {
        SUBHEADER: "Tüm verilere bir göz atın.",
    },
    AI_TOPICS: {
        SUBHEADER: "Pivony AI tarafından bulunan konular.",
    },
    FIXED_TOPICS: {
        SUBHEADER: "Pivony'den sektöre özgü konular.",
    },
    CUSTOM_TOPICS: {
        SUBHEADER: "Kullanıcı tanımlı konularınızı oluşturabilirsiniz.",
    },
    JOURNEY: {
        HEADER: "İlerleme",
        SUBHEADER:
            "Pivony AI Engine, tam otomatik bir yolculuk sunuyor. Acele etmeyin, Panonuz hazır olduğunda bilgilendirileceksiniz.",
    },
    SETTINGS: {
        HEADER: "Ayarlar",
        SUBHEADER: "Ayarlarınızı buradan düzenleyebilirsiniz.",
    },
    PLAN: {
        HEADER: "Planım",
        SUBHEADER: "Mevcut planınızı görüntüleyin veya yükseltin",
    },
    INSIGHTS: {
        HEADER: "İçgörüler",
        SUBHEADER:
            "Yapışkan notlar oluşturun, hatırlamak ve paylaşmak için içgörülerinizi ekleyin. Ek olarak, Pivony uzmanları sizin için içgörüler oluşturmak üzere hizmetinizdedir.",
    },
    MAP: {
        HEADER: "Konum Haritası",
        SUBHEADER:
            "İnsanların nereden yorum yazdıklarını görün",
    },
};

export const citiesTurkey = [
    "ADANA",
    "ADIYAMAN",
    "AFYONKARAHİSAR",
    "AĞRI",
    "AKSARAY",
    "AMASYA",
    "ANKARA",
    "ANTALYA",
    "ARDAHAN",
    "ARTVİN",
    "AYDIN",
    "BALIKESİR",
    "BARTIN",
    "BATMAN",
    "BAYBURT",
    "BİLECİK",
    "BİNGÖL",
    "BİTLİS",
    "BOLU",
    "BURDUR",
    "BURSA",
    "ÇANAKKALE",
    "ÇANKIRI",
    "ÇORUM",
    "DENİZLİ",
    "DİYARBAKIR",
    "DÜZCE",
    "EDİRNE",
    "ELAZIĞ",
    "ERZİNCAN",
    "ERZURUM",
    "ESKİŞEHİR",
    "GAZİANTEP",
    "GİRESUN",
    "GÜMÜŞHANE",
    "HAKKARİ",
    "HATAY",
    "IĞDIR",
    "ISPARTA",
    "İSTANBUL",
    "İZMİR",
    "KAHRAMANMARAŞ",
    "KARABÜK",
    "KARAMAN",
    "KARS",
    "KASTAMONU",
    "KAYSERİ",
    "KIRIKKALE",
    "KIRKLARELİ",
    "KIRŞEHİR",
    "KİLİS",
    "KOCAELİ",
    "KONYA",
    "KÜTAHYA",
    "MALATYA",
    "MANİSA",
    "MARDİN",
    "MERSİN",
    "MUĞLA",
    "MUŞ",
    "NEVŞEHİR",
    "NİĞDE",
    "ORDU",
    "OSMANİYE",
    "RİZE",
    "SAKARYA",
    "SAMSUN",
    "SİİRT",
    "SİNOP",
    "SİVAS",
    "ŞANLIURFA",
    "ŞIRNAK",
    "TEKİRDAĞ",
    "TOKAT",
    "TRABZON",
    "TUNCELİ",
    "UŞAK",
    "VAN",
    "YALOVA",
    "YOZGAT",
    "ZONGULDAK",
];

export const districtsTurkey = {
    ADANA: [
        "ALADAĞ",
        "CEYHAN",
        "ÇUKUROVA",
        "FEKE",
        "İMAMOĞLU",
        "KARAİSALI",
        "KARATAŞ",
        "KOZAN",
        "POZANTI",
        "SAİMBEYLİ",
        "SARIÇAM",
        "SEYHAN",
        "TUFANBEYLİ",
        "YUMURTALIK",
        "YÜREĞİR",
    ],
    ADIYAMAN: [
        "BESNİ",
        "ÇELİKHAN",
        "GERGER",
        "GÖLBAŞI",
        "KAHTA",
        "MERKEZ",
        "SAMSAT",
        "SİNCİK",
        "TUT",
    ],
    AFYONKARAHİSAR: [
        "BAŞMAKÇI",
        "BAYAT",
        "BOLVADİN",
        "ÇAY",
        "ÇOBANLAR",
        "DAZKIRI",
        "DİNAR",
        "EMİRDAĞ",
        "EVCİLER",
        "HOCALAR",
        "İHSANİYE",
        "İSCEHİSAR",
        "KIZILÖREN",
        "MERKEZ",
        "SANDIKLI",
        "SİNANPAŞA",
        "SULTANDAĞI",
        "ŞUHUT",
    ],
    AĞRI: [
        "DİYADİN",
        "DOĞUBAYAZIT",
        "ELEŞKİRT",
        "HAMUR",
        "MERKEZ",
        "PATNOS",
        "TAŞLIÇAY",
        "TUTAK",
    ],
    AKSARAY: [
        "AĞAÇÖREN",
        "ESKİL",
        "GÜLAĞAÇ",
        "GÜZELYURT",
        "MERKEZ",
        "ORTAKÖY",
        "SARIYAHŞİ",
    ],
    AMASYA: [
        "GÖYNÜCEK",
        "GÜMÜŞHACIKÖY",
        "HAMAMÖZÜ",
        "MERKEZ",
        "MERZİFON",
        "SULUOVA",
        "TAŞOVA",
    ],
    ANKARA: [
        "AKYURT",
        "ALTINDAĞ",
        "AYAŞ",
        "BALA",
        "BEYPAZARI",
        "ÇAMLIDERE",
        "ÇANKAYA",
        "ÇUBUK",
        "ELMADAĞ",
        "ETİMESGUT",
        "EVREN",
        "GÖLBAŞI",
        "GÜDÜL",
        "HAYMANA",
        "KALECİK",
        "KAZAN",
        "KEÇİÖREN",
        "KIZILCAHAMAM",
        "MAMAK",
        "NALLIHAN",
        "POLATLI",
        "PURSAKLAR",
        "SİNCAN",
        "ŞEREFLİKOÇHİSAR",
        "YENİMAHALLE",
    ],
    ANTALYA: [
        "AKSEKİ",
        "AKSU",
        "ALANYA",
        "DEMRE",
        "DÖŞEMEALTI",
        "ELMALI",
        "FİNİKE",
        "GAZİPAŞA",
        "GÜNDOĞMUŞ",
        "İBRADI",
        "KAŞ",
        "KEMER",
        "KEPEZ",
        "KONYAALTI",
        "KORKUTELİ",
        "KUMLUCA",
        "MANAVGAT",
        "MURATPAŞA",
        "SERİK",
    ],
    ARDAHAN: ["ÇILDIR", "DAMAL", "GÖLE", "HANAK", "MERKEZ", "POSOF"],
    ARTVİN: [
        "ARDANUÇ",
        "ARHAVİ",
        "BORÇKA",
        "HOPA",
        "MERKEZ",
        "MURGUL",
        "ŞAVŞAT",
        "YUSUFELİ",
    ],
    AYDIN: [
        "BOZDOĞAN",
        "BUHARKENT",
        "ÇİNE",
        "DİDİM",
        "EFELER",
        "GERMENCİK",
        "İNCİRLİOVA",
        "KARACASU",
        "KARPUZLU",
        "KOÇARLI",
        "KÖŞK",
        "KUŞADASI",
        "KUYUCAK",
        "NAZİLLİ",
        "SÖKE",
        "SULTANHİSAR",
        "YENİPAZAR",
    ],
    BALIKESİR: [
        "ALTIEYLÜL",
        "AYVALIK",
        "BALYA",
        "BANDIRMA",
        "BİGADİÇ",
        "BURHANİYE",
        "DURSUNBEY",
        "EDREMİT",
        "ERDEK",
        "GÖMEÇ",
        "GÖNEN",
        "HAVRAN",
        "İVRİNDİ",
        "KARESİ",
        "KEPSUT",
        "MANYAS",
        "MARMARA",
        "SAVAŞTEPE",
        "SINDIRGI",
        "SUSURLUK",
    ],
    BARTIN: ["AMASRA", "KURUCAŞİLE", "MERKEZ", "ULUS"],
    BATMAN: ["BEŞİRİ", "GERCÜŞ", "HASANKEYF", "KOZLUK", "MERKEZ", "SASON"],
    BAYBURT: ["AYDINTEPE", "DEMİRÖZÜ", "MERKEZ"],
    BİLECİK: [
        "BOZÜYÜK",
        "GÖLPAZARI",
        "İNHİSAR",
        "MERKEZ",
        "OSMANELİ",
        "PAZARYERİ",
        "SÖĞÜT",
        "YENİPAZAR",
    ],
    BİNGÖL: [
        "ADAKLI",
        "GENÇ",
        "KARLIOVA",
        "KİĞI",
        "MERKEZ",
        "SOLHAN",
        "YAYLADERE",
        "YEDİSU",
    ],
    BİTLİS: [
        "ADİLCEVAZ",
        "AHLAT",
        "GÜROYMAK",
        "HİZAN",
        "MERKEZ",
        "MUTKİ",
        "TATVAN",
    ],
    BOLU: [
        "DÖRTDİVAN",
        "GEREDE",
        "GÖYNÜK",
        "KIBRISCIK",
        "MENGEN",
        "MERKEZ",
        "MUDURNU",
        "SEBEN",
        "YENİÇAĞA",
    ],
    BURDUR: [
        "AĞLASUN",
        "ALTINYAYLA",
        "BUCAK",
        "ÇAVDIR",
        "ÇELTİKÇİ",
        "GÖLHİSAR",
        "KARAMANLI",
        "KEMER",
        "MERKEZ",
        "TEFENNİ",
        "YEŞİLOVA",
    ],
    BURSA: [
        "BÜYÜKORHAN",
        "GEMLİK",
        "GÜRSU",
        "HARMANCIK",
        "İNEGÖL",
        "İZNİK",
        "KARACABEY",
        "KELES",
        "KESTEL",
        "MUDANYA",
        "MUSTAFAKEMALPAŞA",
        "NİLÜFER",
        "ORHANELİ",
        "ORHANGAZİ",
        "OSMANGAZİ",
        "YENİŞEHİR",
        "YILDIRIM",
    ],
    ÇANAKKALE: [
        "AYVACIK",
        "BAYRAMİÇ",
        "BİGA",
        "BOZCAADA",
        "ÇAN",
        "ECEABAT",
        "EZİNE",
        "GELİBOLU",
        "GÖKÇEADA",
        "LAPSEKİ",
        "MERKEZ",
        "YENİCE",
    ],
    ÇANKIRI: [
        "ATKARACALAR",
        "BAYRAMÖREN",
        "ÇERKEŞ",
        "ELDİVAN",
        "ILGAZ",
        "KIZILIRMAK",
        "KORGUN",
        "KURŞUNLU",
        "MERKEZ",
        "ORTA",
        "ŞABANÖZÜ",
        "YAPRAKLI",
    ],
    ÇORUM: [
        "ALACA",
        "BAYAT",
        "BOĞAZKALE",
        "DODURGA",
        "İSKİLİP",
        "KARGI",
        "LAÇİN",
        "MECİTÖZÜ",
        "MERKEZ",
        "OĞUZLAR",
        "ORTAKÖY",
        "OSMANCIK",
        "SUNGURLU",
        "UĞURLUDAĞ",
    ],
    DENİZLİ: [
        "ACIPAYAM",
        "BABADAĞ",
        "BAKLAN",
        "BEKİLLİ",
        "BEYAĞAÇ",
        "BOZKURT",
        "BULDAN",
        "ÇAL",
        "ÇAMELİ",
        "ÇARDAK",
        "ÇİVRİL",
        "GÜNEY",
        "HONAZ",
        "KALE",
        "MERKEZEFENDİ",
        "PAMUKKALE",
        "SARAYKÖY",
        "SERİNHİSAR",
        "TAVAS",
    ],
    DİYARBAKIR: [
        "BAĞLAR",
        "BİSMİL",
        "ÇERMİK",
        "ÇINAR",
        "ÇÜNGÜŞ",
        "DİCLE",
        "EĞİL",
        "ERGANİ",
        "HANİ",
        "HAZRO",
        "KAYAPINAR",
        "KOCAKÖY",
        "KULP",
        "LİCE",
        "SİLVAN",
        "SUR",
        "YENİŞEHİR",
    ],
    DÜZCE: [
        "AKÇAKOCA",
        "CUMAYERİ",
        "ÇİLİMLİ",
        "GÖLYAKA",
        "GÜMÜŞOVA",
        "KAYNAŞLI",
        "MERKEZ",
        "YIĞILCA",
    ],
    EDİRNE: [
        "ENEZ",
        "HAVSA",
        "İPSALA",
        "KEŞAN",
        "LALAPAŞA",
        "MERİÇ",
        "MERKEZ",
        "SÜLOĞLU",
        "UZUNKÖPRÜ",
    ],
    ELAZIĞ: [
        "AĞIN",
        "ALACAKAYA",
        "ARICAK",
        "BASKİL",
        "KARAKOÇAN",
        "KEBAN",
        "KOVANCILAR",
        "MADEN",
        "MERKEZ",
        "PALU",
        "SİVRİCE",
    ],
    ERZİNCAN: [
        "ÇAYIRLI",
        "İLİÇ",
        "KEMAH",
        "KEMALİYE",
        "MERKEZ",
        "OTLUKBELİ",
        "REFAHİYE",
        "TERCAN",
        "ÜZÜMLÜ",
    ],
    ERZURUM: [
        "AŞKALE",
        "AZİZİYE",
        "ÇAT",
        "HINIS",
        "HORASAN",
        "İSPİR",
        "KARAÇOBAN",
        "KARAYAZI",
        "KÖPRÜKÖY",
        "NARMAN",
        "OLTU",
        "OLUR",
        "PALANDÖKEN",
        "PASİNLER",
        "PAZARYOLU",
        "ŞENKAYA",
        "TEKMAN",
        "TORTUM",
        "UZUNDERE",
        "YAKUTİYE",
    ],
    ESKİŞEHİR: [
        "ALPU",
        "BEYLİKOVA",
        "ÇİFTELER",
        "GÜNYÜZÜ",
        "HAN",
        "İNÖNÜ",
        "MAHMUDİYE",
        "MİHALGAZİ",
        "MİHALIÇÇIK",
        "ODUNPAZARI",
        "SARICAKAYA",
        "SEYİTGAZİ",
        "SİVRİHİSAR",
        "TEPEBAŞI",
    ],
    GAZİANTEP: [
        "ARABAN",
        "İSLAHİYE",
        "KARKAMIŞ",
        "NİZİP",
        "NURDAĞI",
        "OĞUZELİ",
        "ŞAHİNBEY",
        "ŞEHİTKAMİL",
        "YAVUZELİ",
    ],
    GİRESUN: [
        "ALUCRA",
        "BULANCAK",
        "ÇAMOLUK",
        "ÇANAKÇI",
        "DERELİ",
        "DOĞANKENT",
        "ESPİYE",
        "EYNESİL",
        "GÖRELE",
        "GÜCE",
        "KEŞAP",
        "MERKEZ",
        "PİRAZİZ",
        "ŞEBİNKARAHİSAR",
        "TİREBOLU",
        "YAĞLIDERE",
    ],
    GÜMÜŞHANE: ["KELKİT", "KÖSE", "KÜRTÜN", "MERKEZ", "ŞİRAN", "TORUL"],
    HAKKARİ: ["ÇUKURCA", "MERKEZ", "ŞEMDİNLİ", "YÜKSEKOVA"],
    HATAY: [
        "ALTINÖZÜ",
        "ANTAKYA",
        "ARSUZ",
        "BELEN",
        "DEFNE",
        "DÖRTYOL",
        "ERZİN",
        "HASSA",
        "İSKENDERUN",
        "KIRIKHAN",
        "KUMLU",
        "PAYAS",
        "REYHANLI",
        "SAMANDAĞ",
        "YAYLADAĞI",
    ],
    IĞDIR: ["ARALIK", "KARAKOYUNLU", "MERKEZ", "TUZLUCA"],
    ISPARTA: [
        "AKSU",
        "ATABEY",
        "EĞİRDİR",
        "GELENDOST",
        "GÖNEN",
        "KEÇİBORLU",
        "MERKEZ",
        "SENİRKENT",
        "SÜTÇÜLER",
        "ŞARKİKARAAĞAÇ",
        "ULUBORLU",
        "YALVAÇ",
        "YENİŞARBADEMLİ",
    ],
    İSTANBUL: [
        "ADALAR",
        "ARNAVUTKÖY",
        "ATAŞEHİR",
        "AVCILAR",
        "BAĞCILAR",
        "BAHÇELİEVLER",
        "BAKIRKÖY",
        "BAŞAKŞEHİR",
        "BAYRAMPAŞA",
        "BEŞİKTAŞ",
        "BEYKOZ",
        "BEYLİKDÜZÜ",
        "BEYOĞLU",
        "BÜYÜKÇEKMECE",
        "ÇATALCA",
        "ÇEKMEKÖY",
        "ESENLER",
        "ESENYURT",
        "EYÜP",
        "FATİH",
        "GAZİOSMANPAŞA",
        "GÜNGÖREN",
        "KADIKÖY",
        "KAĞITHANE",
        "KARTAL",
        "KÜÇÜKÇEKMECE",
        "MALTEPE",
        "PENDİK",
        "SANCAKTEPE",
        "SARIYER",
        "SİLİVRİ",
        "SULTANBEYLİ",
        "SULTANGAZİ",
        "ŞİLE",
        "ŞİŞLİ",
        "TUZLA",
        "ÜMRANİYE",
        "ÜSKÜDAR",
        "ZEYTİNBURNU",
    ],
    İZMİR: [
        "ALİAĞA",
        "BALÇOVA",
        "BAYINDIR",
        "BAYRAKLI",
        "BERGAMA",
        "BEYDAĞ",
        "BORNOVA",
        "BUCA",
        "ÇEŞME",
        "ÇİĞLİ",
        "DİKİLİ",
        "FOÇA",
        "GAZİEMİR",
        "GÜZELBAHÇE",
        "KARABAĞLAR",
        "KARABURUN",
        "KARŞIYAKA",
        "KEMALPAŞA",
        "KINIK",
        "KİRAZ",
        "KONAK",
        "MENDERES",
        "MENEMEN",
        "NARLIDERE",
        "ÖDEMİŞ",
        "SEFERİHİSAR",
        "SELÇUK",
        "TİRE",
        "TORBALI",
        "URLA",
    ],
    KAHRAMANMARAŞ: [
        "AFŞİN",
        "ANDIRIN",
        "ÇAĞLAYANCERİT",
        "DULKADİROĞLU",
        "EKİNÖZÜ",
        "ELBİSTAN",
        "GÖKSUN",
        "NURHAK",
        "ONİKİŞUBAT",
        "PAZARCIK",
        "TÜRKOĞLU",
    ],
    KARABÜK: [
        "EFLANİ",
        "ESKİPAZAR",
        "MERKEZ",
        "OVACIK",
        "SAFRANBOLU",
        "YENİCE",
    ],
    KARAMAN: [
        "AYRANCI",
        "BAŞYAYLA",
        "ERMENEK",
        "KAZIMKARABEKİR",
        "MERKEZ",
        "SARIVELİLER",
    ],
    KARS: [
        "AKYAKA",
        "ARPAÇAY",
        "DİGOR",
        "KAĞIZMAN",
        "MERKEZ",
        "SARIKAMIŞ",
        "SELİM",
        "SUSUZ",
    ],
    KASTAMONU: [
        "ABANA",
        "AĞLI",
        "ARAÇ",
        "AZDAVAY",
        "BOZKURT",
        "CİDE",
        "ÇATALZEYTİN",
        "DADAY",
        "DEVREKANİ",
        "DOĞANYURT",
        "HANÖNÜ",
        "İHSANGAZİ",
        "İNEBOLU",
        "KÜRE",
        "MERKEZ",
        "PINARBAŞI",
        "SEYDİLER",
        "ŞENPAZAR",
        "TAŞKÖPRÜ",
        "TOSYA",
    ],
    KAYSERİ: [
        "AKKIŞLA",
        "BÜNYAN",
        "DEVELİ",
        "FELAHİYE",
        "HACILAR",
        "İNCESU",
        "KOCASİNAN",
        "MELİKGAZİ",
        "ÖZVATAN",
        "PINARBAŞI",
        "SARIOĞLAN",
        "SARIZ",
        "TALAS",
        "TOMARZA",
        "YAHYALI",
        "YEŞİLHİSAR",
    ],
    KIRIKKALE: [
        "BAHŞİLİ",
        "BALIŞEYH",
        "ÇELEBİ",
        "DELİCE",
        "KARAKEÇİLİ",
        "KESKİN",
        "MERKEZ",
        "SULAKYURT",
        "YAHŞİHAN",
    ],
    KIRKLARELİ: [
        "BABAESKİ",
        "DEMİRKÖY",
        "KOFÇAZ",
        "LÜLEBURGAZ",
        "MERKEZ",
        "PEHLİVANKÖY",
        "PINARHİSAR",
        "VİZE",
    ],
    KIRŞEHİR: [
        "AKÇAKENT",
        "AKPINAR",
        "BOZTEPE",
        "ÇİÇEKDAĞI",
        "KAMAN",
        "MERKEZ",
        "MUCUR",
    ],
    KİLİS: ["ELBEYLİ", "MERKEZ", "MUSABEYLİ", "POLATELİ"],
    KOCAELİ: [
        "BAŞİSKELE",
        "ÇAYIROVA",
        "DARICA",
        "DERİNCE",
        "DİLOVASI",
        "GEBZE",
        "GÖLCÜK",
        "İZMİT",
        "KANDIRA",
        "KARAMÜRSEL",
        "KARTEPE",
        "KÖRFEZ",
    ],
    KONYA: [
        "AHIRLI",
        "AKÖREN",
        "AKŞEHİR",
        "ALTINEKİN",
        "BEYŞEHİR",
        "BOZKIR",
        "CİHANBEYLİ",
        "ÇELTİK",
        "ÇUMRA",
        "DERBENT",
        "DEREBUCAK",
        "DOĞANHİSAR",
        "EMİRGAZİ",
        "EREĞLİ",
        "GÜNEYSINIR",
        "HADİM",
        "HALKAPINAR",
        "HÜYÜK",
        "ILGIN",
        "KADINHANI",
        "KARAPINAR",
        "KARATAY",
        "KULU",
        "MERAM",
        "SARAYÖNÜ",
        "SELÇUKLU",
        "SEYDİŞEHİR",
        "TAŞKENT",
        "TUZLUKÇU",
        "YALIHÜYÜK",
        "YUNAK",
    ],
    KÜTAHYA: [
        "ALTINTAŞ",
        "ASLANAPA",
        "ÇAVDARHİSAR",
        "DOMANİÇ",
        "DUMLUPINAR",
        "EMET",
        "GEDİZ",
        "HİSARCIK",
        "MERKEZ",
        "PAZARLAR",
        "SİMAV",
        "ŞAPHANE",
        "TAVŞANLI",
    ],
    MALATYA: [
        "AKÇADAĞ",
        "ARAPGİR",
        "ARGUVAN",
        "BATTALGAZİ",
        "DARENDE",
        "DOĞANŞEHİR",
        "DOĞANYOL",
        "HEKİMHAN",
        "KALE",
        "KULUNCAK",
        "PÜTÜRGE",
        "YAZIHAN",
        "YEŞİLYURT",
    ],
    MANİSA: [
        "AHMETLİ",
        "AKHİSAR",
        "ALAŞEHİR",
        "DEMİRCİ",
        "GÖLMARMARA",
        "GÖRDES",
        "KIRKAĞAÇ",
        "KÖPRÜBAŞI",
        "KULA",
        "SALİHLİ",
        "SARIGÖL",
        "SARUHANLI",
        "SELENDİ",
        "SOMA",
        "ŞEHZADELER",
        "TURGUTLU",
        "YUNUSEMRE",
    ],
    MARDİN: [
        "ARTUKLU",
        "DARGEÇİT",
        "DERİK",
        "KIZILTEPE",
        "MAZIDAĞI",
        "MİDYAT",
        "NUSAYBİN",
        "ÖMERLİ",
        "SAVUR",
        "YEŞİLLİ",
    ],
    MERSİN: [
        "AKDENİZ",
        "ANAMUR",
        "AYDINCIK",
        "BOZYAZI",
        "ÇAMLIYAYLA",
        "ERDEMLİ",
        "GÜLNAR",
        "MEZİTLİ",
        "MUT",
        "SİLİFKE",
        "TARSUS",
        "TOROSLAR",
        "YENİŞEHİR",
    ],
    MUĞLA: [
        "BODRUM",
        "DALAMAN",
        "DATÇA",
        "FETHİYE",
        "KAVAKLIDERE",
        "KÖYCEĞİZ",
        "MARMARİS",
        "MENTEŞE",
        "MİLAS",
        "ORTACA",
        "SEYDİKEMER",
        "ULA",
        "YATAĞAN",
    ],
    MUŞ: ["BULANIK", "HASKÖY", "KORKUT", "MALAZGİRT", "MERKEZ", "VARTO"],
    NEVŞEHİR: [
        "ACIGÖL",
        "AVANOS",
        "DERİNKUYU",
        "GÜLŞEHİR",
        "HACIBEKTAŞ",
        "KOZAKLI",
        "MERKEZ",
        "ÜRGÜP",
    ],
    NİĞDE: ["ALTUNHİSAR", "BOR", "ÇAMARDI", "ÇİFTLİK", "MERKEZ", "ULUKIŞLA"],
    ORDU: [
        "AKKUŞ",
        "ALTINORDU",
        "AYBASTI",
        "ÇAMAŞ",
        "ÇATALPINAR",
        "ÇAYBAŞI",
        "FATSA",
        "GÖLKÖY",
        "GÜLYALI",
        "GÜRGENTEPE",
        "İKİZCE",
        "KABADÜZ",
        "KABATAŞ",
        "KORGAN",
        "KUMRU",
        "MESUDİYE",
        "PERŞEMBE",
        "ULUBEY",
        "ÜNYE",
    ],
    OSMANİYE: [
        "BAHÇE",
        "DÜZİÇİ",
        "HASANBEYLİ",
        "KADİRLİ",
        "MERKEZ",
        "SUMBAS",
        "TOPRAKKALE",
    ],
    RİZE: [
        "ARDEŞEN",
        "ÇAMLIHEMŞİN",
        "ÇAYELİ",
        "DEREPAZARI",
        "FINDIKLI",
        "GÜNEYSU",
        "HEMŞİN",
        "İKİZDERE",
        "İYİDERE",
        "KALKANDERE",
        "MERKEZ",
        "PAZAR",
    ],
    SAKARYA: [
        "ADAPAZARI",
        "AKYAZI",
        "ARİFİYE",
        "ERENLER",
        "FERİZLİ",
        "GEYVE",
        "HENDEK",
        "KARAPÜRÇEK",
        "KARASU",
        "KAYNARCA",
        "KOCAALİ",
        "PAMUKOVA",
        "SAPANCA",
        "SERDİVAN",
        "SÖĞÜTLÜ",
        "TARAKLI",
    ],
    SAMSUN: [
        "19 MAYIS",
        "ALAÇAM",
        "ASARCIK",
        "ATAKUM",
        "AYVACIK",
        "BAFRA",
        "CANİK",
        "ÇARŞAMBA",
        "HAVZA",
        "İLKADIM",
        "KAVAK",
        "LADİK",
        "SALIPAZARI",
        "TEKKEKÖY",
        "TERME",
        "VEZİRKÖPRÜ",
        "YAKAKENT",
    ],
    SİİRT: [
        "BAYKAN",
        "ERUH",
        "KURTALAN",
        "MERKEZ",
        "PERVARİ",
        "ŞİRVAN",
        "TİLLO",
    ],
    SİNOP: [
        "AYANCIK",
        "BOYABAT",
        "DİKMEN",
        "DURAĞAN",
        "ERFELEK",
        "GERZE",
        "MERKEZ",
        "SARAYDÜZÜ",
        "TÜRKELİ",
    ],
    SİVAS: [
        "AKINCILAR",
        "ALTINYAYLA",
        "DİVRİĞİ",
        "DOĞANŞAR",
        "GEMEREK",
        "GÖLOVA",
        "GÜRÜN",
        "HAFİK",
        "İMRANLI",
        "KANGAL",
        "KOYULHİSAR",
        "MERKEZ",
        "SUŞEHRİ",
        "ŞARKIŞLA",
        "ULAŞ",
        "YILDIZELİ",
        "ZARA",
    ],
    ŞANLIURFA: [
        "AKÇAKALE",
        "BİRECİK",
        "BOZOVA",
        "CEYLANPINAR",
        "EYYÜBİYE",
        "HALFETİ",
        "HALİLİYE",
        "HARRAN",
        "HİLVAN",
        "KARAKÖPRÜ",
        "SİVEREK",
        "SURUÇ",
        "VİRANŞEHİR",
    ],
    ŞIRNAK: [
        "BEYTÜŞŞEBAP",
        "CİZRE",
        "GÜÇLÜKONAK",
        "İDİL",
        "MERKEZ",
        "SİLOPİ",
        "ULUDERE",
    ],
    TEKİRDAĞ: [
        "ÇERKEZKÖY",
        "ÇORLU",
        "ERGENE",
        "HAYRABOLU",
        "KAPAKLI",
        "MALKARA",
        "MARMARAEREĞLİSİ",
        "MURATLI",
        "SARAY",
        "SÜLEYMANPAŞA",
        "ŞARKÖY",
    ],
    TOKAT: [
        "ALMUS",
        "ARTOVA",
        "BAŞÇİFTLİK",
        "ERBAA",
        "MERKEZ",
        "NİKSAR",
        "PAZAR",
        "REŞADİYE",
        "SULUSARAY",
        "TURHAL",
        "YEŞİLYURT",
        "ZİLE",
    ],
    TRABZON: [
        "AKÇAABAT",
        "ARAKLI",
        "ARSİN",
        "BEŞİKDÜZÜ",
        "ÇARŞIBAŞI",
        "ÇAYKARA",
        "DERNEKPAZARI",
        "DÜZKÖY",
        "HAYRAT",
        "KÖPRÜBAŞI",
        "MAÇKA",
        "OF",
        "ORTAHİSAR",
        "SÜRMENE",
        "ŞALPAZARI",
        "TONYA",
        "VAKFIKEBİR",
        "YOMRA",
    ],
    TUNCELİ: [
        "ÇEMİŞGEZEK",
        "HOZAT",
        "MAZGİRT",
        "MERKEZ",
        "NAZIMİYE",
        "OVACIK",
        "PERTEK",
        "PÜLÜMÜR",
    ],
    UŞAK: ["BANAZ", "EŞME", "KARAHALLI", "MERKEZ", "SİVASLI", "ULUBEY"],
    VAN: [
        "BAHÇESARAY",
        "BAŞKALE",
        "ÇALDIRAN",
        "ÇATAK",
        "EDREMİT",
        "ERCİŞ",
        "GEVAŞ",
        "GÜRPINAR",
        "İPEKYOLU",
        "MURADİYE",
        "ÖZALP",
        "SARAY",
        "TUŞBA",
    ],
    YALOVA: [
        "ALTINOVA",
        "ARMUTLU",
        "ÇINARCIK",
        "ÇİFTLİKKÖY",
        "MERKEZ",
        "TERMAL",
    ],
    YOZGAT: [
        "AKDAĞMADENİ",
        "AYDINCIK",
        "BOĞAZLIYAN",
        "ÇANDIR",
        "ÇAYIRALAN",
        "ÇEKEREK",
        "KADIŞEHRİ",
        "MERKEZ",
        "SARAYKENT",
        "SARIKAYA",
        "SORGUN",
        "ŞEFAATLİ",
        "YENİFAKILI",
        "YERKÖY",
    ],
    ZONGULDAK: [
        "ALAPLI",
        "ÇAYCUMA",
        "DEVREK",
        "EREĞLİ",
        "GÖKÇEBEY",
        "KİLİMLİ",
        "KOZLU",
        "MERKEZ",
    ],
};

export const lang = [
    "Arabic",
    "Bulgarian",
    "Chinese",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Finnish",
    "French",
    "German",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Indonesian",
    "Italian",
    "Japanese",
    "Korean",
    "Latvian",
    "Lithuanian",
    "Persian",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Spanish",
    "Thai",
    "Turkish",
];

export const tooltip_labels = new Map([["Intent Analysis", " Filter the results by intent. Detect complaining people and propitiate them or select praise and thank them!"],
["Overall Sentiment Analysis", "The average sentiment of all reviews about a specific topic"],
["Detailed Sentiment Analysis", "Click on any sentiment you want and see all people who have the same sentiment"],
["Time-based Participation", "See temporal changes by day, month or year. Also, click on specific time to see it"],
["Time-based Sentiment", "See the sentimental changes over time"],
["Reviews", "Curious about other search results? Hit search and get tailored results based on your own chosen words!"],
["Hot Terms 🔥", "Hot Terms make it easier to create insights. Just click on a word group and see the results"],
["Platforms", "See how much data is collected from which platform"],
["Top Words", "Select top words and filter your search ! This is a shortcut taking you to insights."],
["Trends View", "See the AI topics with top words, time based participation and sentiment"],
],);;

export const tooltip_labels_tur = new Map([["Intent Analysis", " Sonuçları amaca göre filtreleyin. Şikayet eden insanları tespit edin ve yatıştırın veya onlara övgü ve teşekkür edin!"],
["Overall Sentiment Analysis", "Belirli bir konuyla ilgili tüm incelemelerin ortalama duyarlılığı"],
["Detailed Sentiment Analysis", "İstediğin herhangi bir duyguya tıkla ve aynı duyguya sahip tüm insanları gör"],
["Time-based Participation", "Güne, aya veya yıla göre geçici değişiklikleri görün. Ayrıca görmek için belirli bir zamana tıklayın"],
["Time-based Sentiment", "See the sentimental changes over time"],
["Reviews", "Diğer arama sonuçlarını merak mı ediyorsunuz? Aramaya basın ve kendi seçtiğiniz kelimelere göre özel sonuçlar alın!"],
["Hot Terms 🔥", "Güncel terimler, içgörü oluşturmayı kolaylaştırır. Sadece bir kelime grubuna tıklayın ve sonuçları görün"],
["Platforms", "Hangi platformdan ne kadar veri toplandığını görün"],
["Top Words", "En çok kullanılan kelimeleri seçin ve aramanızı filtreleyin! Bu, sizi içgörülere götüren bir kısayoldur."],
["Trends View", "En çok kullanılan kelimeler, zamana dayalı katılım ve duyarlılık içeren AI Konularını görün"],
],);;

export const timezoneList = {
    "(UTC+03:00) Istanbul": "Europe/Istanbul",
    "(UTC-11:00) Pago Pago": "Pacific/Pago_Pago",
    "(UTC-10:00) Hawaii Time": "Pacific/Honolulu",
    "(UTC-08:00) Pacific Time": "America/Los_Angeles",
    "(UTC-08:00) Pacific Time - Tijuana": "America/Tijuana",
    "(UTC-07:00) Mountain Time": "America/Denver",
    "(UTC-07:00) Mountain Time - Arizona": "America/Phoenix",
    "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan": "America/Mazatlan",
    "(UTC-06:00) Central Time": "America/Chicago",
    "(UTC-06:00) Central Time - Mexico City": "America/Mexico_City",
    "(UTC-06:00) Central Time - Regina": "America/Regina",
    "(UTC-06:00) Guatemala": "America/Guatemala",
    "(UTC-05:00) Bogota": "America/Bogota",
    "(UTC-05:00) Eastern Time": "America/New_York",
    "(UTC-05:00) Lima": "America/Lima",
    "(UTC-04:30) Caracas": "America/Caracas",
    "(UTC-04:00) Atlantic Time - Halifax": "America/Halifax",
    "(UTC-04:00) Guyana": "America/Guyana",
    "(UTC-04:00) La Paz": "America/La_Paz",
    "(UTC-03:00) Buenos Aires": "America/Argentina/Buenos_Aires",
    "(UTC-03:00) Godthab": "America/Godthab",
    "(UTC-03:00) Montevideo": "America/Montevideo",
    "(UTC-03:30) Newfoundland Time - St. Johns": "America/St_Johns",
    "(UTC-03:00) Santiago": "America/Santiago",
    "(UTC-02:00) Sao Paulo": "America/Sao_Paulo",
    "(UTC-02:00) South Georgia": "Atlantic/South_Georgia",
    "(UTC-01:00) Azores": "Atlantic/Azores",
    "(UTC-01:00) Cape Verde": "Atlantic/Cape_Verde",
    "(UTC+00:00) Casablanca": "Africa/Casablanca",
    "(UTC+00:00) Dublin": "Europe/Dublin",
    "(UTC+00:00) Lisbon": "Europe/Lisbon",
    "(UTC+00:00) London": "Europe/London",
    "(UTC+00:00) Monrovia": "Africa/Monrovia",
    "(UTC+01:00) Algiers": "Africa/Algiers",
    "(UTC+01:00) Amsterdam": "Europe/Amsterdam",
    "(UTC+01:00) Berlin": "Europe/Berlin",
    "(UTC+01:00) Brussels": "Europe/Brussels",
    "(UTC+01:00) Budapest": "Europe/Budapest",
    "(UTC+01:00) Central European Time - Belgrade": "Europe/Belgrade",
    "(UTC+01:00) Central European Time - Prague": "Europe/Prague",
    "(UTC+01:00) Copenhagen": "Europe/Copenhagen",
    "(UTC+01:00) Madrid": "Europe/Madrid",
    "(UTC+01:00) Paris": "Europe/Paris",
    "(UTC+01:00) Rome": "Europe/Rome",
    "(UTC+01:00) Stockholm": "Europe/Stockholm",
    "(UTC+01:00) Vienna": "Europe/Vienna",
    "(UTC+01:00) Warsaw": "Europe/Warsaw",
    "(UTC+02:00) Athens": "Europe/Athens",
    "(UTC+02:00) Bucharest": "Europe/Bucharest",
    "(UTC+02:00) Cairo": "Africa/Cairo",
    "(UTC+02:00) Jerusalem": "Asia/Jerusalem",
    "(UTC+02:00) Johannesburg": "Africa/Johannesburg",
    "(UTC+02:00) Helsinki": "Europe/Helsinki",
    "(UTC+02:00) Kiev": "Europe/Kiev",
    "(UTC+02:00) Moscow-01 - Kaliningrad": "Europe/Kaliningrad",
    "(UTC+02:00) Riga": "Europe/Riga",
    "(UTC+02:00) Sofia": "Europe/Sofia",
    "(UTC+02:00) Tallinn": "Europe/Tallinn",
    "(UTC+02:00) Vilnius": "Europe/Vilnius",
    "(UTC+03:00) Baghdad": "Asia/Baghdad",
    "(UTC+03:00) Nairobi": "Africa/Nairobi",
    "(UTC+03:00) Minsk": "Europe/Minsk",
    "(UTC+03:00) Riyadh": "Asia/Riyadh",
    "(UTC+03:00) Moscow+00 - Moscow": "Europe/Moscow",
    "(UTC+03:30) Tehran": "Asia/Tehran",
    "(UTC+04:00) Baku": "Asia/Baku",
    "(UTC+04:00) Moscow+01 - Samara": "Europe/Samara",
    "(UTC+04:00) Tbilisi": "Asia/Tbilisi",
    "(UTC+04:00) Yerevan": "Asia/Yerevan",
    "(UTC+04:30) Kabul": "Asia/Kabul",
    "(UTC+05:00) Karachi": "Asia/Karachi",
    "(UTC+05:00) Moscow+02 - Yekaterinburg": "Asia/Yekaterinburg",
    "(UTC+05:00) Tashkent": "Asia/Tashkent",
    "(UTC+05:30) Colombo": "Asia/Colombo",
    "(UTC+06:00) Almaty": "Asia/Almaty",
    "(UTC+06:00) Dhaka": "Asia/Dhaka",
    "(UTC+06:30) Rangoon": "Asia/Rangoon",
    "(UTC+07:00) Bangkok": "Asia/Bangkok",
    "(UTC+07:00) Jakarta": "Asia/Jakarta",
    "(UTC+07:00) Moscow+04 - Krasnoyarsk": "Asia/Krasnoyarsk",
    "(UTC+08:00) China Time - Beijing": "Asia/Shanghai",
    "(UTC+08:00) Hong Kong": "Asia/Hong_Kong",
    "(UTC+08:00) Kuala Lumpur": "Asia/Kuala_Lumpur",
    "(UTC+08:00) Moscow+05 - Irkutsk": "Asia/Irkutsk",
    "(UTC+08:00) Singapore": "Asia/Singapore",
    "(UTC+08:00) Taipei": "Asia/Taipei",
    "(UTC+08:00) Ulaanbaatar": "Asia/Ulaanbaatar",
    "(UTC+08:00) Western Time - Perth": "Australia/Perth",
    "(UTC+09:00) Moscow+06 - Yakutsk": "Asia/Yakutsk",
    "(UTC+09:00) Seoul": "Asia/Seoul",
    "(UTC+09:00) Tokyo": "Asia/Tokyo",
    "(UTC+09:30) Central Time - Darwin": "Australia/Darwin",
    "(UTC+10:00) Eastern Time - Brisbane": "Australia/Brisbane",
    "(UTC+10:00) Guam": "Pacific/Guam",
    "(UTC+10:00) Moscow+07 - Magadan": "Asia/Magadan",
    "(UTC+10:00) Moscow+07 - Yuzhno-Sakhalinsk": "Asia/Vladivostok",
    "(UTC+10:00) Port Moresby": "Pacific/Port_Moresby",
    "(UTC+10:30) Central Time - Adelaide": "Australia/Adelaide",
    "(UTC+11:00) Eastern Time - Hobart": "Australia/Hobart",
    "(UTC+11:00) Eastern Time - Melbourne, Sydney": "Australia/Sydney",
    "(UTC+11:00) Guadalcanal": "Pacific/Guadalcanal",
    "(UTC+11:00) Noumea": "Pacific/Noumea",
    "(UTC+12:00) Majuro": "Pacific/Majuro",
    "(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy": "Asia/Kamchatka",
    "(UTC+13:00) Auckland": "Pacific/Auckland",
    "(UTC+13:00) Fakaofo": "Pacific/Fakaofo",
    "(UTC+13:00) Fiji": "Pacific/Fiji",
    "(UTC+13:00) Tongatapu": "Pacific/Tongatapu",
    "(UTC+14:00) Apia": "Pacific/Apia"
}
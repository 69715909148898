import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { ColorPicker, notification } from "antd";
import { Modal } from "components/General/ModalFormComponents";

import Icon from "helperComponents/Icon";
import { apiRequest } from "util/services";
import Spinner from "helperComponents/Spinner";

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const Groups = ({
  setGroup,
  activeGroup,
  searchQuery,
  setSearchQuery,
  groupColor,
  setGroupColor,
  isEdit,
  setIsEdit,
}) => {
  const [groups, setGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupCreateLoading, setGroupCreateLoading] = useState(false);
  const [groupDeleteLoading, setGroupDeleteLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(
    activeGroup ? activeGroup : null
  );
  const [deleteGroupId, setDeleteGroup] = useState(null);

  useEffect(() => {
    fetchAllGroups();
  }, []);
  const bgColors = [
    "#4400AA",
    "#FF5280",
    "#9E1684",
    "#D2B5F2",
    "#4582C4",
    "#5C2A70",
    "#D41C1C",
    "#56DEC8",
    "#87050D",
    "#836DDE",
    "#FF056D",
    "#BD02FF",
    "#6E6980",
    "#671D9E",
    "#DE7070",
    "#FFBFFF",
    "#0027FF",
    "#CCCCCC",
    "#46ABBF",
    "#FF0C32",
    "#D6336B",
    "#0C3F54",
    "#C9A479",
    "#9BC3DE",
    "#140A80",
    "#E7EDEF",
  ];
  useEffect(() => {
    if (searchQuery?.length) {
      let filteredList = groupList.filter((item) =>
        item.group_name.includes(searchQuery)
      );
      setGroups(filteredList);
    } else {
      setGroups(groupList);
    }
  }, [searchQuery]);

  const fetchAllGroups = async () => {
    setGroupsLoading(true);
    const result = await apiRequest("dashboards/groups", "POST", {}, true);
    if (!result.error) {
      setGroups(result.success);
      setGroupList(result.success);
    } else {
      notification.error({
        message: result.error,
      });
    }
    setGroupsLoading(false);
  };

  useEffect(() => {
    let colors = groups?.map((item) => item.group_color);
    setGroupColor(
      isEdit
        ? groups.filter((group) => group.group_id === isEdit)[0]?.group_color
        : activeGroup?.group_color
        ? activeGroup?.group_color
        : selectedGroup?.group_color
        ? selectedGroup.group_color
        : bgColors.filter((color) => !colors.includes(color))[0]
    );
  }, [groups, isEdit]);
  const createGroup = async () => {
    setGroupCreateLoading(true);
    const result = await apiRequest(
      "dashboards/groups/add",
      "POST",
      {
        group_name: searchQuery,
        group_color: groupColor,
      },
      true
    );
    if (!result.error) {
      setGroup({
        group_id: result.success,
        group_name: searchQuery,
        group_color: groupColor,
      });
      setSelectedGroup({
        group_id: result.success,
        group_name: searchQuery,
        group_color: groupColor,
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
    setGroupCreateLoading(false);
  };

  const deleteGroup = async () => {
    setGroupDeleteLoading(true);
    const result = await apiRequest(
      "dashboards/groups/delete",
      "POST",
      {
        group_id: deleteGroupId,
      },
      true
    );
    if (!result.error) {
      setDeleteGroup(null);
      fetchAllGroups();
    } else {
      notification.error({
        message: result.error,
      });
    }
    setGroupDeleteLoading(false);
  };

  const updateGroup = async () => {
    setGroupCreateLoading(true);
    const result = await apiRequest(
      "dashboards/groups/rename",
      "POST",
      {
        group_id: isEdit,
        group_name: searchQuery,
        group_color: groupColor,
      },
      true
    );
    if (!result.error) {
      setIsEdit(null);
      setGroup({
        group_id: isEdit,
        group_name: searchQuery,
      });
      setSelectedGroup({
        group_id: isEdit,
        group_name: searchQuery,
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
    setGroupCreateLoading(false);
  };

  return (
    <div className={styles.groupsManager}>
      <div className={styles.groupInput}>
        {selectedGroup && !isEdit ? (
          <div className={styles.group}>
            {selectedGroup.group_name}{" "}
            <span
              onClick={() => {
                setIsEdit(selectedGroup.group_id);
                setSearchQuery(selectedGroup.group_name);
              }}
              style={{ cursor: "pointer" }}
            >
              <Icon name={"edit"} />
            </span>
            <div
              onClick={() => {
                // remove group
                setGroup(null);
                setSelectedGroup(null);
              }}
              style={{ cursor: "pointer" }}
            >
              <Icon name={"close"} />
            </div>
          </div>
        ) : (
          <input
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyUp={(e) => {
              setSearchQuery(e.target.value);
            }}
            placeholder="Search or Create new"
            type="text"
          />
        )}
      </div>

      {isEdit ? (
        groupCreateLoading ? (
          <Spinner />
        ) : (
          <div className={styles.createButton}>
            {" "}
            <span className={styles.pickColor}>
              {" "}
              <p>Change group color</p>{" "}
              <ColorPicker
                value={groupColor || bgColors[0]}
                className={styles.colorPickerCls}
                defaultFormat="hex"
                onChange={(color) => {
                  setGroupColor(`#${color.toHex()?.toUpperCase()}`);
                  let curr = {};
                  if (!selectedGroup && searchQuery !== "") {
                    curr = {
                      group_name: searchQuery,
                      group_color: `#${color.toHex()?.toUpperCase()}`,
                    };
                  }
                  setSelectedGroup((prev) => {
                    return {
                      ...prev,
                      ...curr,
                    };
                  });
                  setGroup((prev) => {
                    return {
                      ...prev,
                      ...curr,
                    };
                  });
                }}
              />{" "}
            </span>
          </div>
        )
      ) : selectedGroup !== null ? (
        ""
      ) : !groupsLoading && groups?.length == 0 && searchQuery?.length == 0 ? (
        <div className={styles.description}>
          {" "}
          Create groups to add it to your dashboards{" "}
        </div>
      ) : (
        <div className={styles.groupsLists}>
          {groups?.length ? (
            <div className={styles.description}>Recent Group names</div>
          ) : (
            ""
          )}
          {groupsLoading ? (
            <div className={styles.loading}>
              {" "}
              <Spinner />{" "}
            </div>
          ) : groups?.length === 0 ? (
            <div>
              {" "}
              {groupCreateLoading ? (
                <Spinner />
              ) : (
                <div className={styles.createButton}>
                  {" "}
                  <span className={styles.pickColor}>
                    {" "}
                    <p>Pick a color to represent the group</p>{" "}
                    <ColorPicker
                      value={groupColor || bgColors[0]}
                      defaultFormat="hex"
                      className={styles.colorPickerCls}
                      onChange={(color) => {
                        setGroupColor(`#${color.toHex()?.toUpperCase()}`);
                        let curr = {};
                        if (!selectedGroup && searchQuery !== "") {
                          curr = {
                            group_name: searchQuery,
                            group_color: `#${color.toHex()?.toUpperCase()}`,
                          };
                        }
                        setSelectedGroup((prev) => {
                          return {
                            ...prev,
                            ...curr,
                          };
                        });
                        setGroup((prev) => {
                          return {
                            ...prev,
                            ...curr,
                          };
                        });
                      }}
                    />{" "}
                  </span>
                </div>
              )}{" "}
            </div>
          ) : (
            groups &&
            groups.map((info) => {
              return (
                <div
                  onClick={() => {
                    setGroup(info);
                    setSelectedGroup(info);
                  }}
                  className={styles.groupItem}
                  key={info.id}
                >
                  {" "}
                  <div className={styles.label}> {info.group_name} </div>{" "}
                  <div className={styles.actions}>
                    <div
                      onClick={() => {
                        setIsEdit(info.group_id);
                        setSearchQuery(info.group_name);
                      }}
                    >
                      {" "}
                      <Icon fill={"white"} size={20} name={"edit"} />
                    </div>{" "}
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteGroup(info.group_id);
                      }}
                    >
                      <Icon fill={"white"} size={20} name={"trash"} />
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
      <Modal
        showModal={deleteGroupId ? true : false}
        onClose={() => setDeleteGroup(null)}
        onSave={deleteGroup}
        showSaveButton={true}
        hideCloseIcon={true}
        textonButton={"Delete"}
        showCancelButton={true}
        disabled={groupDeleteLoading ? true : false}
        loadingSaveClick={groupDeleteLoading ? true : false}
        titleAsset=""
        title={
          <div>
            {" "}
            <Icon name={"trash"} />
            Delete Group
          </div>
        }
        widthofmodal="550px"
      >
        Are you sure you want to delete this group ?
      </Modal>
    </div>
  );
};

export default Groups;

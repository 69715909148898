import React from "react";
import Representation from "./Representation";

const UploadCSV = ({
  handleFiles,
  progress,
  fileName,
  setColumnNames,
  spinning,
  setProgress,
}) => {
  return (
    <Representation
      fileName={fileName}
      progress={progress}
      setProgress={setProgress}
      spinning={spinning}
      setColumnNames={setColumnNames}
      handleFileChange={handleFiles}
    />
  );
};

export default UploadCSV;

import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Carousel,
  notification,
  Checkbox,
  Col,
  Radio,
  Input,
  Switch,
  Button,
  Select,
} from "antd";
import Card from "./Card";
import config from "../../Configurations.js";
import firebase from "../../FirebaseConfig.js";
import "../../stylesheets/SignForms.scss";
import {
  authWithGmail,
  apiRequest,
  logError,
  signout,
} from "../../util/services";
import styles from "./signup.module.scss";
import {
  filterByTypeInterval,
  getPlanById,
  getPlanByUTM,
  getPlans,
} from "./plans";
import { HIDE_BOARD_TOUR, HIDE_CREATION_TOUR } from "../../util/tourGuide";
import { USER } from "../../util/constants";
import { getParams } from "../../util/common";
import { useHistory } from "react-router-dom";
import { hotjar } from "react-hotjar";
import mixpanel from "mixpanel-browser";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;

const Signup = ({ user, authenticated }) => {
  const [selectedPlan, setSelectedPlan] = useState();
  const [plansCategory, setPlansCategory] = useState("default");
  const [timeType, setTimeType] = useState("monthly");
  const [currency, setCurrency] = useState("usd");
  const [reviews, setReviews] = useState("100000");
  const [expert, setExpert] = useState("expert_no");
  const [plans, setPlans] = useState(getPlans());

  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [freeTrial, setFreeTrial] = useState(true);
  const [captchaError, setCaptchaError] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const history = useHistory();
  const Paddle = window.Paddle;
  const [form] = Form.useForm();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  let carousel = React.createRef();
  let startingStep = 0;
  /* Determine the starting component */
  const UTMparams = getParams(document.location.href);
  const utmPlan = getPlanByUTM(UTMparams.recur, UTMparams.type, UTMparams.plan);

  if (user.role || user.company || user.dataSource || user.usage) {
    // user onboarding to give details of user
    startingStep = utmPlan ? 4 : 3;
  } else if (!user.uid) startingStep = 0;
  // Goes to Sign up Page ( user never logged in or signed up)
  else if (!user.emailVerified) startingStep = 1; // Goes to verification page when email needs to be verified

  const invitationId = getParams(document.location.href)?.invitationId;
  const handleNextStepAfterEmailVerification = async () => {
    const payload = { email: user.email };
    const result = await apiRequest("users/add_invited", "POST", payload, true);
    if (result && result?.success) {
      history.push("/console/myDashboards");
    } else {
      carousel.next();
      window.scrollTo(0, 0);
    }
  };

  const email = getParams(document.location.href)?.email || "";

  useEffect(() => {
    if (config.HOTJARID) {
      hotjar.initialize(config.HOTJARID, config.HOTJARVERSION);
    }
  }, []);

  useEffect(() => {
    setSelectedPlan(utmPlan?.id);
  }, []);

  const handlePayment = (e, paddleId, freePlan) => {
    e.preventDefault();
    if (!Paddle) {
      logError("Paddle", {}, "no paddle object found");
      notification.info({
        message: "An error happened, Please refresh the page",
        icon: <InfoCircleOutlined />,
      });
      return;
    }

    if (freePlan || freeTrial) {
      addPlan(null);
    } else {
      Paddle.Checkout.open({
        product: paddleId,
        email: user.email,
        passthrough: JSON.stringify({
          user_id: user.uid,
          package_id: getPlanById(selectedPlan).pricing[timeType].id,
        }),
        successCallback: async (data, err) => {
          Paddle.Order.details(data.checkout.id, function(orderDetails) {
            if (orderDetails.order) {
              const subscriptionId = orderDetails.order.subscription_id;
              addPlan(subscriptionId);
            } else {
              logError(
                "An error happend with the payment and paddle is reviewing this",
                {}
              );
              notification.success({
                message:
                  "Thanks for your subscription, we are reviewing your payment and will activate your account in few hours. If 24 hours passed and it is not activated please contact us at support@pivony.com",
              });
            }
          });
        },
      });
    }
  };

  const verifyEmail = async () => {
    let user = firebase.auth().currentUser;
    let customURL = document.location.href;
    await user
      .sendEmailVerification({ url: customURL })
      .then(async (res) => {
        notification.info({
          message: "Email sent",
          description: "A verification mail has been sent to your account.",
          icon: <InfoCircleOutlined />,
        });
        setResendTimer(60);
      })
      .catch((error) => {
        notification.error({
          message:
            "Verification email not sent, Please wait a few minutes then try again",
        });
        setResendTimer(60);
        apiRequest(
          "users/client_log",
          "POST",
          {
            error: user.email + " account creation failed with error: " + error,
          },
          true
        );
      });
  };

  const signUpUser = async (values) => {
    setCaptchaError("");
    window.localStorage.removeItem(HIDE_BOARD_TOUR);
    window.localStorage.removeItem(HIDE_CREATION_TOUR);

    if (
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000"
    ) {
      setCaptchaError("You must verify with google recaptcha");
      return;
    }

    const authCheckResult = await apiRequest(
      "users/pre_auth_check",
      "POST",
      {
        email: values.email,
        operation_type: "signup",
        invitation_id: invitationId,
      },
      true
    );
    if (
      !authCheckResult ||
      authCheckResult?.cancel === true ||
      authCheckResult?.error
    ) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: authCheckResult?.error || "Unauthorized action!",
      });
      signout(false);
      return;
    }

    setLoading(true);
    let user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      type: USER,
      emailVerified: false,
    };

    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async (response) => {
        const res = response.user.toJSON();
        (res || {}).uid && mixpanel.identify(res.uid);
        mixpanel.track("REGISTER");
        registerUID(res.uid, response.user, user, true);
      })
      .catch(function(error) {
        // var errorCode = error.code;
        apiRequest(
          "users/client_log",
          "POST",
          {
            error:
              values.email + " account creation failed with error: " + error,
          },
          true
        );

        var errorMessage = error.message;
        notification.error({
          message: "Error",
          description: errorMessage,
        });
        setLoading(false);
      });
  };

  const registerUID = async (uid, authUser, objUser, sendEmail) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        user_id: uid,
        email: objUser.email,
        invitation_id: invitationId,
        first_name: objUser.firstName,
        last_name: objUser.lastName,
      }),
    };

    await fetch(config.BASE_URL + "users/create", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (!result || result?.error || result?.cancel === true) {
          setLoading(false);
          authUser.delete();
          notification.error({
            message: "Error",
            description: "Failed to sign up, Please contact support@pivony.com",
          });
        } else {
          if (sendEmail) {
            await verifyEmail();
          }
          await firebase
            .database()
            .ref(`/users/${uid}`)
            .set(objUser);
          document.location.href = document.location.href;
        }
        setLoading(false);
      })
      .catch((error) => {
        authUser.delete();
        notification.error({
          message: "Error",
          description: "Please contact support@pivony.com",
        });
        setLoading(false);
      });
  };

  const verifyRecaptcha = (token) => {
    // TODO serverside check recaptcha
    setCheckRecaptcha(true);

    // const data = {
    //   response: token,
    //   secret: config.RECAPTCHA_LOCALHOST_SECRET_KEY
    // }
    // fetch("https://www.google.com/recaptcha/api/siteverify", {
    //   method: "POST", // or 'PUT'
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("Success:", data);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  // Gmail sign up removed
  // const signUpWithGmail = async () => {
  //   const result = await authWithGmail();
  //   if (result) {
  //     const authCheckResult = await apiRequest("users/pre_auth_check", "POST", { firebase_email: result.email }, true);
  //     if ((authCheckResult || {}).error) {
  //       notification.error({
  //         message: "Error",
  //         description: authCheckResult.error,
  //       });
  //       signout(false);
  //       return;
  //     }

  //     firebase
  //       .database()
  //       .ref(`/users/${result.uid}`)
  //       .once("value", async (snapshot) => {
  //         if (!snapshot.val()) {
  //           const objUser = {
  //             firstName: result.displayName,
  //             phone: result.phoneNumber,
  //             email: result.email,
  //             type: USER,
  //             emailVerified: true,
  //           };
  //           (result || {}).uid && mixpanel.identify(result.uid);
  //           mixpanel.track('REGISTER');
  //           registerUID(result.uid, result, objUser);
  //         }
  //       });
  //   }
  // };

  const onFinish = async (values) => {
    const objUser = { ...user, ...values };
    for (let key in objUser) {
      if (!objUser[key] && key !== "emailVerified" && key !== "plan")
        delete objUser[key];
    }
    // call api to alert about a user waiting for onboarding
    const payload = {
      user_id: user.uid,
      user_other: values,
    };
    const result = await apiRequest(
      "subscriptions/onboardingRequest",
      "POST",
      payload,
      true
    );
    if (result && !result.error) {
      if (selectedPlan) carousel.goTo(4);
      else carousel.next();
      window.scrollTo(0, 0);
      await firebase
        .database()
        .ref(`/users/${user.uid}`)
        .set(objUser);
    } else {
      notification.error({
        message: result.error || JSON.stringify(result),
      });
    }
  };

  const addPlan = async (subscriptionId) => {
    // carousel.next();
    const payload = {
      user_id: user.uid,
      package_id: getPlanById(selectedPlan).pricing[timeType].id,
      subscription_id: subscriptionId,
      IsTrial: freeTrial,
    };
    const result = await apiRequest(
      "subscriptions/create",
      "POST",
      payload,
      true
    );
    if (result && !result.error) {
      await firebase
        .database()
        .ref(`/users/${user.uid}/plan`)
        .set(getPlanById(selectedPlan).pricing[timeType].id);
      history.push("/");
    } else {
      logError("subscriptions/create endpoint", result);
      notification.error({
        message: result.error || JSON.stringify(result),
      });
    }
  };

  const passPlanSection = (planId, freeTrial) => {
    carousel.next();
    window.scrollTo(0, 0);
    setSelectedPlan(planId);
    setFreeTrial(freeTrial);
  };

  const changePlansCategory = (e) => {
    setPlansCategory(e.target.value);
    setPlans(filterByTypeInterval(timeType, e.target.value));
  };

  const changeTime = (checked) => {
    if (checked) {
      setTimeType("yearly");
      // setPlans(filterByTypeInterval("yearly", plansCategory));
    } else {
      setTimeType("monthly");
      // setPlans(filterByTypeInterval("monthly", plansCategory));
    }
  };
  console.log(authenticated);

  // const MyInterval = setInterval(() => {
  //   setResendTimer(prev => prev - 1)
  // }, 1000)

  // useEffect(() => {
  //   if (resendTimer === 0) return;
  //   else {
  //     setTimeout(() => {
  //       setResendTimer(prev => prev - 1)
  //     }, 1000);
  //   }
  // }, [resendTimer]);

  return (
    <div
      className={`${styles.container} ${
        authenticated === "unlogged" || authenticated === "unknown"
          ? styles.showLandingBG
          : undefined
      }`}
    >
      <div className={styles.signUpLandingBG}></div>
      <div className={styles.signUpContainer}>
        <Carousel
          swipe={false}
          accessibility={false}
          ref={(node) => (carousel = node)}
          dotPosition="top"
          initialSlide={startingStep}
        >
          <div className={styles.carouselContent}>
            <div className={styles.title}>
              <img src="/assets/TopicScope_logo_only.png" alt="" />
              <h4 className={styles.subTitle1}>Let's Get started</h4>
              <p className={styles.subTitle2}>Create your Pivony Account</p>
              <p className={styles.loginLink}>
                Already have an account?{" "}
                <span onClick={() => history.push("/login")}>Log in</span>
              </p>
            </div>

            <div className={styles.form}>
              <Form form={form} name="mainDetails" onFinish={signUpUser}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <input type="text" className={styles.inputForm} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <input type="text" className={styles.inputForm} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "Enter a valid email address",
                    },
                    { required: true, message: "Email is required" },
                  ]}
                >
                  <input type="text" className={styles.inputForm} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  className={styles.passwordBox}
                  rules={[
                    { required: true, message: "Password is required" },
                    { min: 6, message: "Weak password!" },
                  ]}
                >
                  <Input.Password className={styles.inputForm} />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  className={styles.passwordBox}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  {/* <input type="password" className={styles.inputForm} /> */}
                  <Input.Password className={styles.inputForm} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                  <div className={styles.recaptcha}>
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      onChange={verifyRecaptcha}
                      sitekey={config.RECAPTCHA_SITE_PUBLIC_KEY}
                    />
                    {captchaError && (
                      <p
                        style={{
                          color: "#ff4d4f",
                          margin: "0",
                          textAlign: "center",
                        }}
                      >
                        {captchaError}
                      </p>
                    )}
                  </div>
                  <Button
                    loading={loading}
                    className={styles.btn}
                    type="primary"
                    htmlType="submit"
                    style={{ height: "auto" }}
                  >
                    Sign up
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {/* <h2 className={styles.or}>
            <span>or</span>
          </h2>
          <div>
            <button onClick={signUpWithGmail} className={styles.gmailSignup}>
              <img src="/assets/google.png" />
              <span>SIGN UP WITH GOOGLE</span>
            </button>
          </div> */}

            <p className={styles.terms}>
              By clicking the button above, you agree to our <br />
              <a href="https://pivony.com/terms/" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="https://pivony.com/privacy/" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className={styles.carouselContent}>
            <div className={styles.title}>
              <h1>Verify your email</h1>
              <div className={styles.verified}>
                <img
                  src={
                    !user.emailVerified
                      ? "/assets/verify_email.svg"
                      : "/assets/verify_email_done.svg"
                  }
                  alt="verified"
                />
              </div>
              {!user.emailVerified && (
                <div className={styles.welcome}>
                  <p>We've sent an email to {user.email}</p>
                  <p>
                    Just click the link in the email to confirm your address
                  </p>
                  <p>If you don't see it, you may need to check your spam</p>
                  <div className={styles.resendEmailBlock}>
                    {/* {resendTimer > 0 && <p>Didn't receive the email? Try after {resendTimer} seconds</p>} */}
                    <button onClick={verifyEmail}>Resend email</button>
                  </div>
                  <p>Your information is safe and secure in Pivony.</p>
                </div>
              )}
              {user.emailVerified && (
                <div
                  className={`${styles.welcome} ${styles.emailVerifiedBlock}`}
                >
                  <p>Your email {user.email} is successfully verified!</p>
                  <button
                    className={styles.btn}
                    type="primary"
                    onClick={handleNextStepAfterEmailVerification}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
            <div className={styles.form}></div>
          </div>
          <div className={styles.carouselContent}>
            <div className={styles.title}>
              <h1>A few Questions to Customize Your Experience</h1>
            </div>
            <div>
              <Form
                name="additionalDetails"
                className={styles.additionalDetails}
                onFinish={onFinish}
              >
                <Form.Item style={{ marginBottom: "10px" }}>
                  <span style={{ fontSize: "16px" }}>
                    <p style={{ marginBottom: "0px" }}>
                      Which data source are you interested in?{" "}
                    </p>
                    <span>(select one or multiple options)</span>
                  </span>
                </Form.Item>
                <Form.Item name="dataSource">
                  <Checkbox.Group>
                    <Col>
                      <Checkbox
                        value="customerData"
                        style={{ lineHeight: "32px" }}
                      >
                        Your customer data
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox
                        value="socialMedia"
                        style={{ lineHeight: "32px" }}
                      >
                        Social media
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox
                        value="onlineNews"
                        style={{ lineHeight: "32px" }}
                      >
                        Online news
                      </Checkbox>
                    </Col>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item
                  name="company"
                  label="Your company name OR website"
                  className={styles.changeLabelStyle}
                >
                  <Input className={styles.changeBorderRadius} />
                </Form.Item>
                <Form.Item
                  className={styles.changeLabelStyle2}
                  name="usage"
                  label="Please briefly describe your expectation using Pivony"
                  rules={[
                    { required: true, message: "Please fill up this field" },
                    { min: 20, message: "Very short answer" },
                  ]}
                >
                  <TextArea
                    className={styles.changeBorderRadius}
                    rows={"3"}
                    showCount
                    maxLength={150}
                  />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Which of the following best describes your role?"
                  className={styles.radioWrapper}
                >
                  <Radio.Group className="signupRadios">
                    <Radio value="Customer Experience">
                      Customer Experience
                    </Radio>
                    <Radio value="Digital Marketing / Social Media">
                      Digital Marketing / Social Media
                    </Radio>
                    <Radio value="Corporate Communications">
                      Corporate Communications
                    </Radio>
                    <Radio value="Other">Other</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <div className={styles.btnContainer}>
                    <button
                      className={styles.btn}
                      type="primary"
                      htmlType="submit"
                    >
                      Next
                    </button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
          {!user.plan && (
            <div className={styles.carouselContent}>
              <div className={styles.title}>
                <h1>Our Team Reviews Your Request</h1>
                <div className={styles.welcome}>
                  <p>Thank you for showing interest in our solution.</p>
                  <p>Our Onboarding process is 100% Human to human.</p>
                  <p>You will be contacted by our team soon.</p>
                </div>
              </div>
              <div className={styles.blogPost}>
                <p>Have you seen our recent blog?</p>
                <button
                  onClick={() =>
                    window.open("https://www.pivony.com/blog", "_self")
                  }
                >
                  <img src="/assets/navigate.png" alt="" />
                  Explore now
                </button>
                <button
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={styles.logoutBtn}
                  onClick={signout}
                >
                  <img
                    src={
                      isHovered
                        ? "/assets/log-out.svg"
                        : "/assets/log-out-black.svg"
                    }
                    alt=""
                  />
                  <span>Logout</span>
                </button>
              </div>
              <div className={styles.onboardingBackground}>
                <img src="/assets/onboarding.svg" alt="" />
              </div>
              <div className={styles.form}></div>
            </div>
          )}
          {user.plan && (
            <div className={styles.carouselContent_fullWidth}>
              <div className={styles.title}>
                <h1>The right plan for your company's growth</h1>
              </div>
              <div className={styles.planFilters}>
                <div className={styles.switch}>
                  <p>Monthly</p>
                  <Switch onChange={changeTime} />
                  <p>Annually</p>
                </div>
              </div>

              <div className={styles.DashboardsWrapper}>
                <div className={styles.Dashboards}>
                  <p>Currency</p>
                  <Select
                    labelInValue
                    defaultValue={{ value: "usd" }}
                    style={{ width: 100 }}
                    onChange={({ value }) => setCurrency(value)}
                  >
                    <Option value="usd">USD $</Option>
                    <Option value="eur">EUR €</Option>
                    <Option value="gbp">GBP £</Option>
                    <Option value="tl">TL ₺</Option>
                  </Select>
                </div>
              </div>

              {user.currency && (
                <div className={styles.plans}>
                  {plans.map((plan) => (
                    <Card
                      plan={plan}
                      passPlanSection={passPlanSection}
                      reviews={reviews}
                      expert={expert}
                      timeType={timeType}
                      currency={currency}
                      user={user}
                      freeTrial={false}
                    />
                  ))}
                </div>
              )}
              <p>*Prices do not include VAT</p>
            </div>
          )}
          {user.plan && (
            <div className={styles.carouselContent_fullWidth}>
              <div className={styles.title}>
                <h1>The right plan for your company's growth</h1>
              </div>
              <div className={styles.plans_selected}>
                {user.currency && (
                  <Card
                    plan={{ ...getPlanById(selectedPlan), hidden: true }}
                    passPlanSection={passPlanSection}
                    reviews={reviews}
                    expert={expert}
                    timeType={timeType}
                    currency={currency}
                    user={user}
                    freeTrial={freeTrial}
                  />
                )}
                <div className={styles.selectionDetails}>
                  <h3>
                    You have selected the {getPlanById(selectedPlan)?.title}{" "}
                    plan{" "}
                    {!freeTrial &&
                      !getPlanById(selectedPlan)?.free &&
                      `${timeType} subscription`}
                    {freeTrial && `free trial`}
                  </h3>
                  <h5>
                    Not sure of this plan? pick another{" "}
                    <a
                      onClick={() => {
                        carousel.prev();
                        window.scrollTo(0, 0);
                      }}
                    >
                      here
                    </a>
                  </h5>
                  <div className={styles.payment}>
                    {!freeTrial && !getPlanById(selectedPlan)?.free ? (
                      <>
                        <img
                          className={styles.paddleLogo}
                          src="/assets/paddle.svg"
                        />
                        <h3>Payment With Paddle</h3>
                        <p>
                          Click on the next button to sign in and pay with your
                          Paddle account.
                        </p>
                        <p>
                          By clicking this, you will allow Pivony to charge on
                          your credit card during your subscription.
                        </p>
                      </>
                    ) : (
                      <>
                        <h3>{!freeTrial ? "Free Plan" : "Free Trial"}</h3>
                        <p>
                          To start a {!freeTrial ? "free plan" : "free trial"},
                          you don't need to enter your credit card details.
                        </p>
                        <p>
                          Click to start your {!freeTrial ? "free" : "trial"}{" "}
                          membership.
                        </p>
                      </>
                    )}

                    <p>
                      Click on the next button to sign in
                      {!getPlanById(selectedPlan).freeTrial &&
                        " and pay with your Paddle account."}
                    </p>
                    {!getPlanById(selectedPlan).freeTrial && (
                      <p>
                        By clicking this, you will allow Pivony to charge on
                        your credit card during your subscription.
                      </p>
                    )}
                    <button
                      className={styles.btn}
                      style={{
                        float: "inherit",
                        margin: "20px 0 0 0",
                        width: "100%",
                      }}
                      type="primary"
                      onClick={(e) =>
                        handlePayment(
                          e,
                          getPlanById(selectedPlan).pricing[timeType].paddleId,
                          getPlanById(selectedPlan).title === "Free"
                        )
                      }
                    >
                      {freeTrial
                        ? "Activate Free Trial"
                        : "Begin My Subscription"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {user.plan && (
            <div className={styles.carouselContent}>
              <div className={styles.title}>
                <h1>You are all set</h1>
                <div className={styles.verified}>
                  <img src="/assets/verified.png" alt="verified" />
                </div>
                <div className={styles.welcome}>
                  <p>Thank you for joining us!</p>
                  <p>
                    You can now start your subscription, we will guide you
                    through
                  </p>
                  <button
                    className={styles.btn}
                    style={{ float: "inherit", margin: "20px 0 0 0" }}
                    type="primary"
                    onClick={addPlan}
                  >
                    Start
                  </button>
                </div>
              </div>
              <div className={styles.form}></div>
            </div>
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Signup;

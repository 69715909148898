import React, { useEffect, useRef, forwardRef } from "react";
import styles from "./style.module.scss";
import {
  Chart,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  CategoryScale,
  LinearScale,
  Title,
  RadialLinearScale,
  DatasetController,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import Zoom from "chartjs-plugin-zoom";
Chart.register(
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Zoom
);

const CustomChart = forwardRef(
  ({ type, data, options, plugins, width, height, dependency }, ref) => {
    const chartInstanceRef = useRef(null);
    const canvasRef = useRef(null);
    const chartRef = ref || chartInstanceRef;

    useEffect(() => {
      // Destroy existing chart if present
      if (chartRef?.current) {
        chartRef.current.destroy();
      }
      const chartConfig = {
        type: type,
        data: data,
        options: options,
        plugins: plugins,
      };
      // Create a new chart instance
      const ctx = canvasRef.current.getContext("2d");
      chartRef.current = new Chart(ctx, chartConfig);
      // Cleanup chart instance on unmount or data change
      return () => {
        chartRef.current.destroy();
      };
    }, [dependency ? dependency : data]);
    return <canvas ref={canvasRef} width={width} height={height} />;
  }
);

export default CustomChart;

import React, { useState } from "react";
import { notification } from "antd";
import Representation from './Representation';
import { apiRequest } from 'util/services';
import i18n from 'i18next';

const NewOrganization = ({ user, closeView }) => {
  const [input, setInput] = useState();

  const save = () => {
    const payload = {
      org_creator_id: user.uid,
      org_name: input
    }
    apiRequest(
      "users/organization/add",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        closeView()
        notification.success({
          message: i18n.t("settings.176")
        })
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }
  return <Representation
    onInputChange={setInput}
    save={save}
  />
}

export default NewOrganization;
import React from "react";
import config from "Configurations.js";
import Representation from './Representation';

const Modal = ({ user }) => {

  const activateSlack = () => {
    const key = config.SLACK_KEY;
    const redirectUrl = window.location.href;
    const link = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=${key}&state=${user.uid}&redirect_uri=${redirectUrl}?slack=true`
    window.location.replace(link);
  }

  return <Representation
    activateSlack={activateSlack}
  />
}

export default Modal;
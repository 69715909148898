import React from "react";
import { useDropzone } from "react-dropzone";
import Representation from "./Representation";
import { getFileHeaderFromXLSX } from "./fileReader";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { getRandomProgress } from "util/common";

const DropZoneWrapper = ({
  handleFileChange,
  progress,
  setProgress,
  spinning,
  setColumnNames,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const onFileChange = async (acceptedFiles) => {
    setProgress(getRandomProgress(1, 5));
    const fileResponse = await getFileHeaderFromXLSX(acceptedFiles[0]);
    setProgress(getRandomProgress(6, 14));
    if (fileResponse?.success) {
      setColumnNames(fileResponse?.headers);
      setProgress(getRandomProgress(15, 21));
      handleFileChange(acceptedFiles);
    } else if (fileResponse.encrypted) {
      setProgress(0);
      notification.error({
        message: t("notifications.150"),
      });
    } else {
      setProgress(0);
      notification.error({
        message: fileResponse?.message,
      });
    }
    return;
  };
  const params = {
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop: onFileChange,
    maxFiles: 1,
    multiple: false,
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone(params);
  return (
    <Representation
      spinning={spinning}
      progress={progress}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      theme={theme}
    />
  );
};

export default DropZoneWrapper;

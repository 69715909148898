import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./style.module.scss";

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const getAllLabels = (data, labelData, level) => {
  if (Object.keys(labelData).length) {
    Object.keys(labelData).map((key) => {
      if (
        Object.keys(labelData[key]).length &&
        Object.keys(Object.values(labelData[key])[0]).length
      ) {
        getAllLabels(data, labelData[key], level + 1);
        if (data.labels[level]) {
          data.labels[level].push(...Object.keys(labelData[key]));
        } else {
          data.labels[level] = Object.keys(labelData[key]);
        }
      } else {
        if (Object.keys(labelData[key]).length) {
          getAllLabels(data, labelData[key], level + 1);
        }
        if (key == "cj_score") {
          data.data.push(labelData[key]);
        }
      }
    });
  }
};
const getAllLabelsForFeedback = (data, labelData, level) => {
  if (Object.keys(labelData).length) {
    Object.keys(labelData).map((key) => {
      if (
        Object.keys(labelData[key]).length &&
        Object.keys(Object.values(labelData[key])[0]).length
      ) {
        getAllLabelsForFeedback(data, labelData[key], level + 1);
        if (data.labels[level]) {
          data.labels[level].push(...Object.keys(labelData[key]));
        } else {
          data.labels[level] = Object.keys(labelData[key]);
        }
      } else {
        getAllLabelsForFeedback(data, labelData[key], level + 1);
        // if (Object.keys(labelData[key]).length) {
        //     Object.keys(labelData[key]).map((newKey) => {
        //         if (newKey !== 'cj_score') {
        //             if (data.labels[level]) {
        //                 data.labels[level].push(newKey)
        //             } else {
        //                 data.labels[level] = [newKey]

        //             }

        //         }
        //     })
        // }
        if (
          key !== "cj_score" &&
          (key == "last_year" || key == "last_quarter" || key == "current")
        ) {
          data.data[key].push(labelData[key]);
        }
      }
    });
  }
};

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [-10, -29, -120, -120, -100],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [100, 200, 5, 912, 12],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(75,181,67, 0.5)",
    },
  ],
};

const customPlugin = {
  beforeInit: function(chart) {
    let { scales } = chart;
    chart.data.labels.forEach(function(e, i, a) {
      if (/\n/.test(e)) {
        a[i] = e.split(/\n/);
      }
    });
  },
};

const HorizontalChart = ({ ChartData }) => {
  const options = {
    height: "100%",
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      xAxes: [],
      yAxes: [],
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  let ChartAxisData = {
    labels: {},
    data: [],
  };
  getAllLabels(ChartAxisData, { ChartData }, 1);
  let labels = [];

  let backgroundColor = [];
  for (let i = 0; i < ChartAxisData.data.length; i++) {
    if (ChartAxisData.data[i] > 0) {
      backgroundColor.push("rgba(75,181,67, 1)");
    } else {
      backgroundColor.push("rgba(255,51,51,1)");
    }
  }

  let n = Object.keys(ChartAxisData.labels).length - 1;
  labels = Object.values(ChartAxisData.labels)[n];
  let data = {
    datasets: [
      {
        label: "Sentiment score",
        data: ChartAxisData.data,
        backgroundColor: backgroundColor,
        borderWidth: 2,
        barPercentage: 0.6,
      },
    ],
  };

  Object.keys(ChartAxisData.labels)
    .reverse()
    .map((key, index) => {
      options.scales.yAxes.push({
        type: "category",
        afterFit: function(scale) {
          scale.width = 130;
        },
        labels: ChartAxisData.labels[key],
        align: "center",
        gridLines: {
          display: true,
          offsetGridLines: true,
        },
      });
    });

  return (
    // <HorizontalBar options={options} data={data} plugins={[customPlugin]} />
    <></>
  );
};

const BarChart = ({ ChartData }) => {
  const options = {
    height: "100%",
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      xAxes: [],
      yAxes: [],
    },

    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  let ChartAxisData = {
    labels: {},
    data: [],
  };
  getAllLabels(ChartAxisData, { ChartData }, 1);
  let labels = [];

  let backgroundColor = [];
  for (let i = 0; i < ChartAxisData.data.length; i++) {
    if (ChartAxisData.data[i] > 0) {
      backgroundColor.push("rgba(75,181,67, 1)");
    } else {
      backgroundColor.push("rgba(255,51,51,1)");
    }
  }

  let n = Object.keys(ChartAxisData.labels).length - 1;
  labels = Object.values(ChartAxisData.labels)[n];
  let data = {
    datasets: [
      {
        label: "Sentiment score",
        data: ChartAxisData.data,
        backgroundColor: backgroundColor,
        borderWidth: 2,
        barPercentage: 0.6,
      },
    ],
  };

  Object.keys(ChartAxisData.labels)
    .reverse()
    .map((key, index) => {
      options.scales.xAxes.push({
        type: "category",
        afterFit: function(scale) {
          scale.width = 130;
        },
        labels: ChartAxisData.labels[key],
        align: "center",
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
            return value.split(" ");
          },
        },
        gridLines: {
          display: true,
          offsetGridLines: true,
        },
      });
    });

  return (
    <div className={styles.barChartContainer}>
      {" "}
      <div className={styles.box}>
        {/* <Bar options={options} plugins={[customPlugin]} data={data} /> */}
      </div>{" "}
    </div>
  );
};

const BarChartFeedback = ({ ChartData }) => {
  const options = {
    height: "100%",
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      xAxes: [],
      yAxes: [],
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "right",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
  };

  let ChartAxisData = {
    labels: {},
    data: {
      current: [],
      last_quarter: [],
      last_year: [],
    },
  };
  getAllLabelsForFeedback(ChartAxisData, { ChartData }, 1);
  let labels = [];

  let backgroundColor = [];
  for (let i = 0; i < ChartAxisData.data.length; i++) {
    if (ChartAxisData.data[i] > 0) {
      backgroundColor.push("rgba(75,181,67, 1)");
    } else {
      backgroundColor.push("rgba(255,51,51,1)");
    }
  }

  let n = Object.keys(ChartAxisData.labels).length - 1;
  labels = Object.values(ChartAxisData.labels)[n];
  let data = {
    datasets: [
      {
        label: "current",
        data: ChartAxisData.data.current,
        backgroundColor: "rgb(42,77,105)",
        borderWidth: 2,
        barPercentage: 0.6,
      },
      {
        label: "last quarter",
        data: ChartAxisData.data.last_quarter,
        backgroundColor: "rgb(75,134,180)",
        borderWidth: 2,
        barPercentage: 0.6,
      },
      {
        label: "last year",
        data: ChartAxisData.data.last_year,
        backgroundColor: "rgb(173,203,227)",
        borderWidth: 2,
        barPercentage: 0.6,
      },
    ],
  };

  Object.keys(ChartAxisData.labels)
    .reverse()
    .map((key, index) => {
      options.scales.xAxes.push({
        type: "category",
        afterFit: function(scale) {
          scale.width = 130;
        },
        labels: ChartAxisData.labels[key],
        align: "center",
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value, index, ticks) {
            return value.split(" ");
          },
        },
        gridLines: {
          display: true,
          offsetGridLines: true,
        },
      });
    });

  return (
    <div className={styles.barChartContainer}>
      {" "}
      <div className={styles.box}>
        {/* <Bar plugins={[customPlugin]} options={options} data={data} /> */}
      </div>{" "}
    </div>
  );
};

export { HorizontalChart, BarChart, BarChartFeedback };

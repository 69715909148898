import React, { useState, useEffect } from "react";
import { Select, Checkbox } from "antd";
import styles from "./style.module.scss";
import { DownOutlined } from "@ant-design/icons";
const { Option } = Select;

const MultiSelect = ({
  isLoading,
  showSearch,
  enableSelectAll,
  placeholderOfSearchBar,
  selectionLimit,
  defaultSelectedOptions,
  placeholder,
  options,
  reset,
  setReset,
  setSelectedOptions,
  allowClear,
  tagCount,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false); // Track if dropdown is clicked

  useEffect(() => {
    if (defaultSelectedOptions?.length > 0) {
      const defaultIds = defaultSelectedOptions?.map((opt) => opt.id);
      const defaultSelection = options?.filter((option) =>
        defaultIds.includes(option?.id.toString())
      );
      setSelectedItems(defaultSelection);
    }
  }, [defaultSelectedOptions]);

  const handleChange = (selected) => {
    if (!isLoading) {
      let updatedSelection = [...selected];

      if (selected.includes("select-all")) {
        updatedSelection =
          selected.length === options.length
            ? []
            : options.slice(0, selectionLimit);
      } else if (selected.length > selectionLimit) {
        const overflow = selected.length - selectionLimit;
        updatedSelection = selected.slice(overflow, selected.length);
      }

      setSelectedItems(updatedSelection);
      setSelectAllChecked(updatedSelection.length === options.length);
      setSelectedOptions(updatedSelection);
    }
  };

  useEffect(() => {
    if (reset && defaultSelectedOptions?.length > 0) {
      const defaultSelection = options?.filter((option) =>
        defaultSelectedOptions?.includes(option?.id)
      );
      setSelectedItems(defaultSelection);
      setSelectAllChecked(false);
      setReset(false);
    } else if (reset) {
      setSelectedItems([]);
      setSelectAllChecked(false);
      setSelectedOptions([]);
      setReset(false);
    }
  }, [reset]);

  const handleSelectAll = () => {
    const newSelected = selectAllChecked
      ? []
      : options.slice(0, selectionLimit);
    handleChange(newSelected);
  };

  return (
    <div className={styles.Dropdown}>
      <div className={styles.MultiSelectDropdown}>
        <Select
          mode="multiple"
          suffixIcon={<DownOutlined />}
          showSearch={showSearch ? true : false}
          allowClear={allowClear}
          disabled={isLoading}
          placeholder={
            defaultSelectedOptions.length === 0 && (
              <>
                {showSearch && dropdownClicked && placeholderOfSearchBar ? (
                  placeholderOfSearchBar
                ) : placeholder ? (
                  placeholder
                ) : (
                  <span
                    className={styles.defaultPlaceholder}
                    onClick={() => setDropdownClicked(true)} // Set dropdown clicked on placeholder click
                  >
                    Select <DownOutlined className={styles.suffixIcon} />
                  </span>
                )}
              </>
            )
          }
          value={selectedItems}
          optionFilterProp="title"
          maxTagCount={tagCount ? "responsive" : 1}
          listHeight={192}
          className={styles.selection}
          onFocus={() => setDropdownClicked(true)}
          onBlur={() => setDropdownClicked(false)}
        >
          {enableSelectAll && (
            <Option
              key="select-all"
              value="select-all"
              className={styles.option}
            >
              <Checkbox
                checked={
                  selectAllChecked ||
                  defaultSelectedOptions
                    ?.map((opt) => opt.id)
                    .every((item) =>
                      options.map((opt) => opt.id).includes(item)
                    )
                }
                indeterminate={
                  selectedItems.length > 0 &&
                  selectedItems.length < options.length
                }
                onChange={handleSelectAll}
              >
                Select All
              </Checkbox>
            </Option>
          )}
          {options.map((option, index) => (
            <Option
              className={
                selectedItems.includes(option.value)
                  ? styles.checkedOption
                  : styles.option
              }
              key={option.value}
              value={option.value}
              title={option.label}
            >
              <Checkbox
                checked={selectedItems.includes(option)}
                onChange={() => {
                  const newSelectedItems = selectedItems.includes(option)
                    ? selectedItems.filter((item) => item !== option)
                    : [...selectedItems, option];

                  if (newSelectedItems.length > selectionLimit) {
                    const overflow = newSelectedItems.length - selectionLimit;
                    const trimmedSelection = newSelectedItems.slice(
                      overflow,
                      newSelectedItems.length
                    );
                    handleChange(trimmedSelection);
                  } else {
                    handleChange(newSelectedItems);
                  }
                }}
              >
                {option.label}
              </Checkbox>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default MultiSelect;

import React, { useEffect, useState } from "react";
import Popup from "./Popup";
import { Button, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { apiRequest } from "util/services";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { convertDateIntoYYYYMMDD } from "util/common";
const PopupSequence = ({ setSelectedConfiguration, setIsLoading }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [resetPopup, setResetPopup] = useState(false);
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const [
    selectCategoriesButtonClicked,
    setSelectCategoriesButtonClicked,
  ] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  const requestDataForDashboards = {
    groupIds: selectedGroups?.map((group) => group.groupId),
  };
  const requestDataForCategories = {
    dashboard_ids: selectedDashboards?.map(
      (dashboard) => dashboard.dashboardId
    ),
  };
  const requestDataForSaveSources = {
    groupIds: selectedGroups?.map((group) => group.groupId),
    dashboardIds: selectedDashboards?.map((dashboard) => dashboard.dashboardId),
    categoryIds: selectedCategories?.map((category) => category.categoryId),
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setContinueButtonClicked(false);
    setSelectCategoriesButtonClicked(false);
  };
  useEffect(() => {
    if (isPopupOpen) {
      (async () => {
        setTableDataLoading(true);
        const apiResponse = await apiRequest(
          "industry_analysis/groups",
          "GET",
          null,
          true
        );
        setIsLoading(true);
        if (apiResponse?.data) {
          setGroups(apiResponse.data);
          setIsLoading(false);
        } else {
          notification.error({
            message: `${apiResponse?.error}`,
          });
          setIsLoading(false);
        }
        setTableDataLoading(false);
      })();
    } else if (continueButtonClicked) {
      (async () => {
        setTableDataLoading(true);
        const apiResponse = await apiRequest(
          "industry_analysis/dashboards",
          "POST",
          requestDataForDashboards,
          true
        );
        setIsLoading(true);
        if (apiResponse?.data) {
          setDashboards(apiResponse?.data);
          setIsLoading(false);
        } else {
          notification.error({
            message: `${apiResponse?.error}`,
          });
          setIsLoading(false);
        }
        setTableDataLoading(false);
      })();
    } else if (selectCategoriesButtonClicked) {
      (async () => {
        setTableDataLoading(true);
        const apiResponse = await apiRequest(
          "industry_analysis/categories",
          "POST",
          requestDataForCategories,
          true
        );
        setIsLoading(true);
        if (apiResponse?.data) {
          setCategories(apiResponse?.data);
          setIsLoading(false);
        } else {
          notification.error({
            message: `${apiResponse?.error}`,
          });
          setIsLoading(false);
        }
        setTableDataLoading(false);
      })();
    }
  }, [isPopupOpen, continueButtonClicked, selectCategoriesButtonClicked]);
  const handleSaveSources = async () => {
    updateQParams(
      "selected_categories",
      JSON.stringify(requestDataForSaveSources?.categoryIds)
    );
    const untilDate = new Date();
    const sinceDate = new Date(untilDate.getTime() - 6 * 24 * 60 * 60 * 1000);
    const untilStr = convertDateIntoYYYYMMDD(untilDate);
    const sinceStr = convertDateIntoYYYYMMDD(sinceDate);
    updateQParams("since", sinceStr);
    updateQParams("until", untilStr);
    updateQParams("daysRange", "last7d");
    setSelectedConfiguration({
      ...requestDataForSaveSources,
      filters: {
        date: [sinceStr, untilStr],
        sentiment: [],
        stream: [],
      },
    });
    const apiResponse = await apiRequest(
      "industry_analysis/saveSources",
      "POST",
      requestDataForSaveSources,
      true
    );
    setIsLoading(true);
    if (apiResponse?.success) {
      return;
    } else {
      notification.error({
        message: `${apiResponse?.error}`,
      });
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.popupSeq}>
      <span
        className={styles.StartButton}
        onClick={() => {
          setIsPopupOpen(true);
          setResetPopup(true);
        }}
      >
        <PlusOutlined />
        <span>{t("competitor_analysis.11")}</span>
      </span>
      {isPopupOpen && (
        <Popup
          open={isPopupOpen}
          onClose={handleClosePopup}
          data={groups}
          dataType={"Groups"}
          setContinueButtonClicked={setContinueButtonClicked}
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          resetPopup={resetPopup}
          loading={tableDataLoading}
        />
      )}
      {continueButtonClicked && (
        <Popup
          open={continueButtonClicked}
          onClose={handleClosePopup}
          data={dashboards}
          dataType={"Dashboards"}
          setSelectCategoriesButtonClicked={setSelectCategoriesButtonClicked}
          selectedDashboards={selectedDashboards}
          setSelectedDashboards={setSelectedDashboards}
          resetPopup={resetPopup}
          loading={tableDataLoading}
        />
      )}
      {selectCategoriesButtonClicked && (
        <Popup
          open={selectCategoriesButtonClicked}
          onClose={handleClosePopup}
          data={categories}
          onSave={handleSaveSources}
          dataType={"Categories"}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          resetPopup={resetPopup}
          loading={tableDataLoading}
        />
      )}
    </div>
  );
};

export default PopupSequence;

import React from "react";
import { DatePicker } from "antd";
import styles from "./style.module.scss";
import dayjs from 'dayjs'
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import moment from "moment";
dayjs.extend(weekday)
dayjs.extend(localeData)
const { RangePicker } = DatePicker;



const Representation = ({onChange,defaultStartDate,defaultEndDate,searchPeriod,disabled}) => (
  <div className={styles.row}>
    <RangePicker
      defaultValue={[dayjs(defaultStartDate,"YYYY-MM-DD"),dayjs(defaultEndDate,"YYYY-MM-DD")]}
      value = {[searchPeriod[0] ? dayjs(searchPeriod[0],"YYYY-MM-DD") : "",searchPeriod[1] ?  dayjs(searchPeriod[1],"YYYY-MM-DD") : ""]}
      verticalHeight={5}
      onChange= {onChange}
      disabled={disabled}
    />
  </div>
);

export default Representation;
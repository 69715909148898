import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { apiRequest } from "util/services";
import { useSelector, useDispatch } from "react-redux";
import { Button, notification, Spin } from "antd";
import { APP_MESSAGES, UNLOGGED, APP_MESSAGES_TUR } from "util/constants";
import * as actions from "redux/Dashboard/actions.js";
import { useHistory } from "react-router-dom";
import GeolocationMap from "./Map";
import BoardCard from "components/Dashboard/BoardCard";
import BoardError from "components/Dashboard/BoardError";
import NestedTabs from "components/NestedTabs";
import { useTranslation } from "react-i18next";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const Geolocation = ({
  authenticated,
  user,
  currentDashboard,
  shared,
  currentDashboardHash,
  match,
}) => {
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const history = useHistory();
  if (!currentDashboard && !DashboardReducer.dashboard) {
    history.push("/console/myDashboards");
  }

  let initialState = {
    currentDashboard: shared
      ? currentDashboard
      : match.params.id || DashboardReducer.dashboard?.ID,
  };
  const [localState, setState] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [facebookReplies, setFacebookReplies] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    if (authenticated === UNLOGGED && !shared) {
      document.location.href = document.location.origin;
      return;
    }
    if (!localState.currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/console/myDashboards");
      return;
    }
    checkDashboardStatus(localState.currentDashboard);
  }, []);

  const checkDashboardStatus = async (DashboardId) => {
    const result = await apiRequest(
      "dashboards/v2/get",
      "POST",
      {
        dashboard_id: parseInt(DashboardId, 10),
      },
      true
    );
    if (result.error) {
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    } else if (result.status?.ID === -1 || result.status?.ID === 5) {
      notification.info({
        message: t("notifications.44"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/myDashboards`);
    } else if (
      result.status.ID !== 4 &&
      result.status.ID !== 6 &&
      !result?.dashboard?.IsDynamic
    ) {
      notification.info({
        message: t("notifications.45"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/journey/${initialState.currentDashboard}`);
    } else {
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          ...result,
          loading: true,
        },
      });
      getMapData(result.dashboard.ID);
    }
  };

  const getMapData = async (dashboard_id) => {
    setLoading(true);
    const result = await apiRequest(
      "dashboards/get/location",
      "POST",
      { dashboard_id, topic_type: "ai" },
      true
    );
    if (!result.error) {
      setData(result);
      DashboardReducer.dashboard?.ID && getFacebookReplies(result.data);
    } else {
      notification.error({ message: result.error });
    }
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    setLoading(false);
  };

  const getFacebookReplies = async (resReviews = []) => {
    setReviewsLoading(true);
    const url = "dashboards/get_facebook_replies";
    const payload = {
      comment_ids: (resReviews.length > 0 ? resReviews : data?.data).map(
        ({ reviews }) => {
          return reviews[0].DocId;
        }
      ),
      firebase_id: DashboardReducer?.dashboard?.UserId,
    };
    const result = await apiRequest(url, "POST", payload, true);
    if (result.success) {
      setFacebookReplies(result.success);
    } else {
      notification.error({
        message: result.error,
      });
    }
    setReviewsLoading(false);
  };

  if (DashboardReducer.failureMsg)
    return <BoardError failureMsg={DashboardReducer.failureMsg} />;

  return (
    <div className={styles.mainWindow}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.title}>
            <div className={styles.text}>
              <h5>
                {i18n.language === "en"
                  ? APP_MESSAGES.MAP.HEADER
                  : APP_MESSAGES_TUR.MAP.HEADER}
              </h5>
              {!shared && (
                <h6>
                  {i18n.language === "en"
                    ? APP_MESSAGES.MAP.SUBHEADER
                    : APP_MESSAGES_TUR.MAP.SUBHEADER}
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      <NestedTabs />
      <Spin spinning={loading}>
        {data ? (
          <BoardCard title={t("geolocation.1")}>
            <GeolocationMap
              data2={data}
              currentDashboard={localState.currentDashboard}
              getFacebookReplies={getFacebookReplies}
              facebookReplies={facebookReplies}
              reviewsLoading={reviewsLoading}
            />
          </BoardCard>
        ) : (
          <>
            <h4 className={styles.error}>{t("geolocation.2")}</h4>
            <div
              className={`${styles.navBtn}`}
              onClick={() =>
                history.push(
                  `${shared ? "/share/" : "/console/"}DashboardData/${
                    currentDashboardHash
                      ? currentDashboardHash
                      : `${initialState.currentDashboard}`
                  }`
                )
              }
            >
              <Button>{t("selected_dashboard_page.67")}</Button>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default Geolocation;
